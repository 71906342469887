import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import { statusColorSwitch } from "./asset-list";
export function AssetMobileCard(props) {
  const asset = props.entity;
  const history = useHistory();
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };
  return (
    <Card variant="outlined" className="w-100">
      <CardContent className="pb-0 pt-1">
        <Typography variant="h6">
          <Link
            href="#"
            underline="none"
            onClick={(e) => handleNavigateTo(e, `assets/${asset.id}`)}
          >
            {asset.assetNumber}
          </Link>
        </Typography>
        <Typography variant="h6">{asset.name}</Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          Type: {asset.assetType || "-"}
        </Typography>
        {asset.status && (
          <Typography variant="body2">
            <i
              className={"fe fe-circle "}
              style={{ color: statusColorSwitch(asset.status) }}
            />
            &nbsp;Status: {asset.status}
          </Typography>
        )}
      </CardContent>
      <CardActions></CardActions>
    </Card>
  );
}
