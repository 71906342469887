import * as React from "react";
import { connect } from "react-redux";
import { Card, Form, Grid, Page } from "tabler-react";
import { loadAccount, updateAccount } from "../actions/account";

import Button from "@mui/material/Button";
import alertify from "alertifyjs";
import ResetPassword from "../components/login/reset-password";
import { getLookup } from "../services/lookup";

class AccountContainer extends React.Component {
  state = {
    id: 0,
    organizationName: "",
    email: "",
    firstName: "",
    lastName: "",
    language: "",
    city: "",
    postalCode: "",
    country: "",
    defaultLatitude: 0,
    defaultLongitude: 0,
    defaultTheme: "Dark",
    defaultRightSidebar: "Newsfeed",
    defaultZoom: 0,
    togglePasswordReset: false,
    coordinateType: "",
    passwordErrorList: [],
    timezones: [],
  };

  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  handleSubmit() {
    this.props.updateAccount(this.state).then(() => {
      alertify.success("Account Updated");
      this.props.loadAccount();
    });
  }

  handleBack() {
    this.setState({
      togglePasswordReset: false,
    });
  }

  componentDidMount() {
    getLookup("timezone").then((res) => {
      this.setState({
        ...this.state,
        timezones: res.data,
      });
    });
    this.props.loadAccount().then(() => {
      this.setState({
        organizationName: this.props.account.organizationName,
        firstName: this.props.account.firstName,
        email: this.props.account.email,
        defaultRightSidebar: this.props.account.defaultRightSidebar,
        lastName: this.props.account.lastName,
        language: this.props.account.language,
        city: this.props.account.city,
        postalCode: this.props.account.postalCode,
        country: this.props.account.country,
        defaultLatitude: this.props.account.defaultLatitude,
        defaultLongitude: this.props.account.defaultLongitude,
        defaultTheme: this.props.account.defaultTheme,
        defaultZoom: this.props.account.defaultZoom,
        id: this.props.account.id,
        coordinateType: this.props.account.coordinateType,
        timezoneId: this.props.account.timezoneId,
      });
    });
  }

  render() {
    return (
      <Page>
        <Page.Main>
          <Page.Content title="My Account">
            {this.props.account.isLoading === false &&
              this.state.togglePasswordReset === false && (
                <Form className="card">
                  <Card.Body>
                    <Card.Title>
                      Edit Profile
                      <Button
                        variant="contained"
                        onClick={() =>
                          this.setState({ togglePasswordReset: true })
                        }
                        color="neutral"
                        className="ml-2 float-right"
                      >
                        Reset Password
                      </Button>
                    </Card.Title>

                    <Grid.Row>
                      <Grid.Col md={6} width={12}>
                        <Form.Group>
                          <Form.Label>Organization</Form.Label>
                          <Form.Input
                            type="text"
                            disabled
                            placeholder="Company"
                            value={this.state.organizationName}
                            name="organizationName"
                            onChange={this.handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>

                      <Grid.Col width={12} md={6}>
                        <Form.Group>
                          <Form.Label>Email address</Form.Label>
                          <Form.Input
                            type="email"
                            placeholder="Email"
                            disabled
                            value={this.state.email}
                            name="email"
                            onChange={this.handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col width={12} md={4}>
                        <Form.Group>
                          <Form.Label htmlFor="firstName">
                            First Name
                          </Form.Label>
                          <Form.Input
                            type="text"
                            placeholder="First Name"
                            value={this.state.firstName}
                            name="firstName"
                            onChange={this.handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col width={12} md={4}>
                        <Form.Group>
                          <Form.Label>Last Name</Form.Label>
                          <Form.Input
                            type="text"
                            placeholder="Last Name"
                            value={this.state.lastName}
                            name="lastName"
                            onChange={this.handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>

                      <Grid.Col width={12} md={4}>
                        <Form.Group>
                          <Form.Label>City</Form.Label>
                          <Form.Input
                            type="text"
                            placeholder="City"
                            value={this.state.city}
                            name="city"
                            onChange={this.handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col width={12} md={4}>
                        <Form.Group>
                          <Form.Label>Postal Code</Form.Label>
                          <Form.Input
                            type="number"
                            placeholder="ZIP Code"
                            value={this.state.postalCode}
                            name="postalCode"
                            onChange={this.handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>

                      <Grid.Col width={12} md={4}>
                        <Form.Group>
                          <Form.Label>Timezone</Form.Label>
                          <Form.Select
                            value={this.state.timezoneId}
                            name="timezoneId"
                            onChange={this.handleInputChange}
                          >
                            {this.state.timezones.map((zone) => (
                              <option value={zone.id}>
                                {zone.displayName}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </Grid.Col>

                      <>
                        <Grid.Col width={12} md={12}>
                          <Form.Group>
                            <Card.Title>Dashboard Settings</Card.Title>
                          </Form.Group>
                        </Grid.Col>
                        <Grid.Col sm={6} md={4}>
                          <Form.Group>
                            <Form.Label>Coordinate Type</Form.Label>
                            <Form.Select
                              value={this.state.coordinateType}
                              name="coordinateType"
                              onChange={this.handleInputChange}
                            >
                              <option value="LatLong">
                                Latitude / Longitude
                              </option>
                              <option value="Utm">UTM</option>
                            </Form.Select>
                          </Form.Group>
                        </Grid.Col>
                      </>
                    </Grid.Row>
                  </Card.Body>
                  <Card.Footer className="text-right">
                    <Button variant="contained" onClick={this.handleSubmit}>
                      Update Profile
                    </Button>
                  </Card.Footer>
                </Form>
              )}
            {this.props.account.isLoading === false &&
              this.state.togglePasswordReset === true && (
                <ResetPassword
                  handleBack={this.handleBack}
                  requireCurrentPassword={true}
                  email={this.props.account.email}
                  apiUrl="/account/password"
                ></ResetPassword>
              )}
          </Page.Content>
        </Page.Main>
      </Page>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account,
});

const mapDispatchToProps = (dispatch) => ({
  loadAccount: () => dispatch(loadAccount()),
  updateAccount: (account) => dispatch(updateAccount(account)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer);
