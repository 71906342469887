import { apiUrl, client } from "../modules/store";
export function getEquipment(params) {
  return client.get(apiUrl + "/equipment", {
    params: params,
  });
}

export function updateEquipment(id, dto) {
  return client.put(apiUrl + "/equipment/" + id, dto);
}

export function createEquipment(dto, organizationId) {
  return client.post(`${apiUrl}/equipment`, dto, {
    params: {
      organizationId: organizationId,
    },
  });
}
export function deleteEquipment(id) {
  return client.delete(apiUrl + "/equipment/" + id);
}
