import { apiUrl, client } from "../modules/store";
const url = `${apiUrl}/monitoringVarianceLimits`;
export function getMonitoringVarianceLimits(params) {
  return client.get(url, {
    params: params,
  });
}

export function updateMonitoringVarianceLimit(id, dto) {
  return client.put(`${url}/${id}`, dto);
}
export function createMonitoringVarianceLimit(dto) {
  return client.post(url, dto);
}
export function deleteMonitoringVarianceLimit(id) {
  return client.delete(`${url}/${id}`);
}
