import React from "react";
import { OrganizationModal } from "./OrganizationModal";
import { getOrganizations } from "../../services/admin";
import { LynxDataGrid } from "../data-grid/LynxDataGrid";
import Checkbox from "@mui/material/Checkbox";

export function OrganizationList(props) {
  const organizationColumns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      type: "string",
    },

    {
      field: "userCount",
      headerName: "User Count (License)",
      width: 150,
      type: "number",
    },
    {
      field: "activeUserCount",
      headerName: "Active Users",
      width: 150,
      type: "number",
    },
    {
      field: "eventsLicenseCount",
      headerName: "Events License Count",
      width: 150,
      type: "number",
    },
    {
      field: "activeEventUsers",
      headerName: "Active Event Users",
      width: 150,
      type: "number",
    },
    {
      field: "monitoringLicenseCount",
      headerName: "Monitoring License Count",
      width: 150,
      type: "number",
    },
    {
      field: "activeMonitoringUsers",
      headerName: "Active Monitoring Users",
      width: 150,
      type: "number",
    },
    {
      field: "incidentsLicenseCount",
      headerName: "Incidents License Count",
      width: 150,
      type: "number",
    },
    {
      field: "activeIncidentUsers",
      headerName: "Active Incident Users",
      width: 150,
      type: "number",
    },
    {
      field: "inspectionsLicenseCount",
      headerName: "Inspections License Count",
      width: 150,
      type: "number",
    },
    {
      field: "activeInspectionUsers",
      headerName: "Active Inspection Users",
      width: 150,
      type: "number",
    },
    {
      field: "energyLogsLicenseCount",
      headerName: "Energy Logs License Count",
      width: 150,
      type: "number",
    },
    {
      field: "activeEnergyLogUsers",
      headerName: "Active Energy Log Users",
      width: 150,
      type: "number",
    },
    {
      field: "timeSeriesLicenseCount",
      headerName: "Timeseries License Count",
      width: 150,
      type: "number",
    },
    {
      field: "activeTimeSeriesUsers",
      headerName: "Active Timeseries Users",
      width: 150,
      type: "number",
    },
    {
      field: "hasPhotoAnalysis",
      headerName: "Has Photo Analysis",
      width: 175,
      type: "boolean",
      renderCell: (params) => (
        <div>
          <Checkbox
            checked={params.value}
            name="hasPhotoAnalysis"
            disabled={true}
          />
        </div>
      ),
    },
    {
      field: "hasPermitFeature",
      headerName: "Has Permit Feature",
      width: 175,
      type: "boolean",
      renderCell: (params) => (
        <div>
          <Checkbox
            checked={params.value}
            name="hasPermitFeature"
            disabled={true}
          />
        </div>
      ),
    },
  ];

  return (
    <LynxDataGrid
      columns={organizationColumns}
      getDataFunction={getOrganizations}
      localStorageName="organizations"
      entityModal={OrganizationModal}
      entityName={"Organization"}
      {...props}
      hasEditButton
      hasNoLookups
    />
  );
}
