import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import { Card, Dimmer, Form, Grid } from "tabler-react";
import { MultiSelect } from "./../../form-controls/multi-select";
import "./incident-form.css";

import { styled } from "@mui/material/styles";
import { ParticipantModal } from "./participant-modal";
export function IncidentParticipants(props) {
  const [participantToEdit, setParticipantToEdit] = useState({});
  const [showParticipantModal, setShowParticipantModal] = useState(false);
  const [showWitnessModal, setShowWitnessModal] = useState(false);

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  }));

  const handleEditParty = (party) => {
    let participant = { ...party };
    setParticipantToEdit(participant);
    setShowParticipantModal(true);
  };
  const handleEditAffectedParty = (party) => {
    let existingParties = [...props.affectedParties];
    let i = existingParties.findIndex(
      (x) => x.id == party.id || x.newId == party.newId
    );
    existingParties.splice(i, 1);
    existingParties.push(party);
    props.setAffectedParties(existingParties);
    setParticipantToEdit({});
    setShowParticipantModal(false);
  };
  const handleAddAffectedParty = (e) => {
    let existingParties = [...props.affectedParties];
    props.setAffectedParties([
      { newId: _.uniqueId("new"), ...e },
      ...existingParties,
    ]);
    setShowParticipantModal(false);
  };

  const handleAddWitness = (e) => {
    let existingWitnesses = [...props.witnesses];
    props.setWitnesses([
      { newId: _.uniqueId("new"), isWitness: true, ...e },
      ...existingWitnesses,
    ]);
    setShowWitnessModal(false);
  };

  const handleDeleteParty = (party) => {
    let existingParties = [...props.affectedParties];
    let i = existingParties.findIndex(
      (x) => x.id == party.id || x.newId == party.newId
    );
    existingParties.splice(i, 1);
    props.setAffectedParties(existingParties);
  };

  const handleDeleteWitness = (witness) => {
    let existingWitnessess = [...props.witnesses];
    let i = 0;

    if (witness.newId != undefined) {
      i = existingWitnessess.findIndex((x) => x.newId == witness.newId);
    } else {
      i = existingWitnessess.findIndex((x) => x.id == witness.id);
    }

    existingWitnessess.splice(i, 1);
    props.setWitnesses(existingWitnessess);
  };

  const handleEditWitness = (party) => {
    let participant = { ...party };
    setParticipantToEdit(participant);
    setShowWitnessModal(true);
  };

  const handleEditAffectedWitness = (party) => {
    let existingParties = [...props.witnesses];
    let i = existingParties.findIndex(
      (x) => x.id == party.id || x.newId == party.newId
    );
    existingParties.splice(i, 1);
    existingParties.push(party);
    props.setWitnesses(existingParties);
    setParticipantToEdit({});
    setShowWitnessModal(false);
  };

  const getCardFooters = () => {
    return (
      <>
        {props.handleSave && (
          <Card.Footer>
            <Button
              onClick={props.handleSave}
              className="float-right"
              variant="contained"
            >
              Save
            </Button>
          </Card.Footer>
        )}
      </>
    );
  };
  return (
    <>
      <Container className="form-container" maxWidth="xl">
        <Dimmer active={props.isLoading} loader>
          <Form className="card mb-0">
            <Card.Header>
              <Card.Title>Affected Parties</Card.Title>{" "}
            </Card.Header>
            <Card.Body className="pt-2">
              <Grid.Row>
                <Grid.Col md={12} width={12} className="mb-2">
                  <div>
                    Choose from users or add a affected party
                    <span className="form-required">*</span>
                  </div>
                  {props.formState.affectedPartiesError && (
                    <span
                      className="invalid-feedback"
                      style={{ display: "inline" }}
                    >
                      {props.formState.affectedPartiesError}
                    </span>
                  )}
                </Grid.Col>
                <Grid.Col md={6} width={12}>
                  <Form.Group label="Select Users">
                    <MultiSelect
                      disabled={props.isDisabled()}
                      name="userParticipants"
                      id="selectedUserParticipants"
                      onChange={props.handleInputChange}
                      value={props.formState.userParticipants}
                      dropdownValues={props.users}
                      key="id"
                      label="fullName"
                    />
                    <Button
                      onClick={() => setShowParticipantModal(true)}
                      startIcon={<AddIcon />}
                      sx={{ maxHeight: "50px", alignSelf: "center" }}
                      disabled={props.isDisabled()}
                    >
                      Or Add New Affected Party
                    </Button>
                  </Form.Group>
                </Grid.Col>

                <Grid.Col md={12} width={12} className="d-flex">
                  <div className="compliance-grid-container">
                    {_.orderBy(props.affectedParties, ["name"]).map((party) => (
                      <div className="dashboard-item">
                        <Item variant="outlined">
                          <Typography variant="h6" component="div">
                            {party.name}
                          </Typography>
                          <Typography
                            sx={{ mb: 1.5 }}
                            color="text.secondary"
                            className="mb-0"
                          >
                            {
                              props.incidentParticipantTypes.find(
                                (x) => x.id == party.participantTypeId
                              ).code
                            }
                          </Typography>

                          <Typography variant="body2">
                            {party.details}
                          </Typography>

                          <div className="d-flex h-100 align-items-end justify-content-end">
                            <Button
                              className=" mt-2 "
                              disabled={props.isDisabled()}
                              onClick={() => handleEditParty(party)}
                            >
                              Edit
                            </Button>
                            <Button
                              color="error"
                              disabled={props.isDisabled()}
                              onClick={() => handleDeleteParty(party)}
                              className=" mt-2 "
                            >
                              Delete
                            </Button>
                          </div>
                        </Item>
                      </div>
                    ))}
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
            {getCardFooters()}
          </Form>
        </Dimmer>
      </Container>
      <Container className="form-container" maxWidth="xl">
        <Dimmer active={props.isLoading} loader>
          <Form
            className={`card ${
              !props.showAttachments && props.hasNoFooter ? "mb-2" : "mb-0"
            }`}
          >
            <Card.Header>
              <Card.Title>Witnesses</Card.Title>{" "}
            </Card.Header>
            <Card.Body className="pt-2">
              <Grid.Row>
                <Grid.Col md={12} width={12} className="mb-2">
                  <div>Choose from users or add a witness</div>
                </Grid.Col>
                <Grid.Col md={6} width={12}>
                  <Form.Group label="Select Users">
                    <MultiSelect
                      disabled={props.isDisabled()}
                      name="userWitnesses"
                      id="selectedWitnesses"
                      onChange={props.handleInputChange}
                      value={props.formState.userWitnesses}
                      dropdownValues={props.users}
                      key="id"
                      label="fullName"
                    />
                    <Button
                      disabled={props.isDisabled()}
                      onClick={() => setShowWitnessModal(true)}
                      startIcon={<AddIcon />}
                      sx={{ maxHeight: "50px", alignSelf: "center" }}
                    >
                      Or Add New Witness
                    </Button>
                  </Form.Group>
                </Grid.Col>

                <Grid.Col md={12} width={12} className="d-flex ">
                  <div className="compliance-grid-container">
                    {_.orderBy(props.witnesses, ["name"]).map((party) => (
                      <div className="dashboard-item">
                        <Item variant="outlined">
                          <Typography variant="h6" component="div">
                            {party.name}
                          </Typography>
                          <Typography
                            sx={{ mb: 1.5 }}
                            color="text.secondary"
                            className="mb-0"
                          >
                            {
                              props.incidentParticipantTypes.find(
                                (x) => x.id == party.participantTypeId
                              ).code
                            }
                          </Typography>

                          <Typography
                            variant="body2"
                            component="div"
                            sx={{ whiteSpace: "pre-line" }}
                          >
                            {party.details}
                          </Typography>

                          <div className="d-flex h-100 align-items-end justify-content-end">
                            <Button
                              disabled={props.isDisabled()}
                              className=" mt-2 "
                              onClick={() => handleEditWitness(party)}
                            >
                              Edit
                            </Button>
                            <Button
                              disabled={props.isDisabled()}
                              color="error"
                              onClick={() => handleDeleteWitness(party)}
                              className=" mt-2 "
                            >
                              Delete
                            </Button>
                          </div>
                        </Item>
                      </div>
                    ))}
                  </div>
                </Grid.Col>
              </Grid.Row>
            </Card.Body>
            {getCardFooters()}
          </Form>
        </Dimmer>
      </Container>
      {showParticipantModal && (
        <ParticipantModal
          open={showParticipantModal}
          handleClose={() => setShowParticipantModal(false)}
          handleAdd={handleAddAffectedParty}
          participantTypes={props.incidentParticipantTypes}
          participant={participantToEdit}
          handleUpdate={handleEditAffectedParty}
        ></ParticipantModal>
      )}
      {showWitnessModal && (
        <ParticipantModal
          open={showWitnessModal}
          handleClose={() => setShowWitnessModal(false)}
          handleAdd={handleAddWitness}
          participantTypes={props.incidentParticipantTypes}
          participant={participantToEdit}
          handleUpdate={handleEditAffectedWitness}
          isWitness
        ></ParticipantModal>
      )}
    </>
  );
}
