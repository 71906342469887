import * as actions from "../actions/organization";
import { localStorageService } from "../services/local-storage-service";

const initialState = {
  isLoading: true,
  featureFlags: [],
};
export const organizationStoreName = "organizationStore_v2";
export default (state = initialState, action) => {
  switch (action.type) {
    case actions.ORG_LOAD:
      return {
        ...state,
        isLoading: true,
        hasErrored: false,
      };
    case actions.ORG_LOAD_SUCCESS:
      localStorageService.setLocalStorage(
        organizationStoreName,
        action.payload.data
      );
      return {
        ...state,
        ...action.payload.data,
        isLoading: false,
        hasErrored: false,
      };
    case actions.ORG_LOAD_FAIL:
      var lsOrg = localStorageService.getLocalStorage(organizationStoreName);
      if (lsOrg) {
        return {
          ...state,
          ...action.payload.data,
          isLoading: false,
          hasErrored: false,
        };
      }
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
        errorMessage: action.error.message,
      };

    default:
      break;
  }
  return state;
};
