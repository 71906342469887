import { getPermissions }from "../../../services/users";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import _ from "lodash";
import { LookupTypes } from "../../../types/enums";

export function UsersPermissions(props) {
    const [usersPermissionsData, setUsersPermissionsData] = useState([]);

    let defaultPermissionsColumns = [
        {
          field: "fullName",
          headerName: "Name",
          width: 150,
          type: "string",
        },       
        {
          field: "email",
          headerName: "Email",
          width: 150,
          type: "string",
        },
        {
          field: "canAccess",
          headerName: "Can access",
          width: 125,
          type: "boolean",
          renderCell: (params) => (            
            <div>
              <Checkbox
                checked={params.value}
                name="canAccess"
                onChange={(e) => handleUserPermissionChanged(e, params.row)}
                disabled={params.row.disabled}
              />
            </div>
          ),
        },
    ];

    useEffect(() => {
        getPermissions(props.lookupType, props.lookupId).then((res) => {
            setUsersPermissionsData(res.data);
            props.setUsersPermissionsData(res.data);  
          });
    }, [props.refresh]);

      const getColumns = () => {
        let columns = [...defaultPermissionsColumns];

        if (_.toLower(props.lookupType) === _.toLower(LookupTypes.IncidentType)){
          columns.push( {
              field: "canPerformInitialReview",
              headerName: "Can perform initial review",
              width: 200,
              type: "boolean",
              renderCell: (params) => (
                <div>
                  {params.row.canAccess && (
                    <Checkbox
                      name="canPerformInitialReview"
                      checked={params.value}
                      onChange={(e) => handleUserPermissionChanged(e, params.row)}
                    />
                  )}
                </div>
              ),
            });
        }
        if (_.toLower(props.lookupType) === _.toLower(LookupTypes.IncidentType) || _.toLower(props.lookupType) === _.toLower(LookupTypes.EventCategory)){
            columns.push({
            field: "canApprove",
            headerName: "Can approve actions",
            width: 150,
            type: "boolean",
            renderCell: (params) => (
              <div>
                {params.row.canAccess && (
                  <Checkbox
                    name="canApprove"
                    checked={params.value}
                    onChange={(e) => handleUserPermissionChanged(e, params.row)}
                  />
                )}
              </div>
            ),
          });
          columns.push({
            field: "canVerify",
            headerName: "Can verify actions",
            width: 150,
            type: "boolean",
            renderCell: (params) => (
              <div>
                {params.row.canAccess && (
                  <Checkbox
                    name="canVerify"
                    checked={params.value}
                    onChange={(e) => handleUserPermissionChanged(e, params.row)}
                  />
                )}
              </div>
            ),
          });
      
          columns.push({
            field: "canPerformFinalReview",
            headerName: "Can perform final review",
            width: 175,
            type: "boolean",
            renderCell: (params) => (
              <div>
                {params.row.canAccess && (
                  <Checkbox
                    name="canPerformFinalReview"
                    checked={params.value}
                    onChange={(e) => handleUserPermissionChanged(e, params.row)}
                  />
                )}
              </div>
            ),
          });
        }

        return columns;
      }

      const handleUserPermissionChanged = (e, user) => {
        const { name, checked } = e.target;
    
        let existingPermissions = [...usersPermissionsData];
        var permissionIndex = existingPermissions.findIndex(
          (x) => x.id == user.id
        );
        _.set(existingPermissions[permissionIndex], name, checked);
        _.set(existingPermissions[permissionIndex], "hasChanges", true);
        setUsersPermissionsData(existingPermissions);  
        props.setUsersPermissionsData(existingPermissions);      
      };

    return <Box sx={{ height: 400, width: "100%" }}>
    <DataGridPro
      rows={usersPermissionsData}
      columns={getColumns()}
      disableSelectionOnClick
    ></DataGridPro>
  </Box>
}

