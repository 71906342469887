import React, { useState, useEffect } from "react";
import { Grid, Card } from "tabler-react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { AddLookupHeader } from "./../add-lookup-header";
import { getLookups } from "./../../../../services/lookup";

import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import { EditLookupModal } from "./../edit-lookup-modal";
import Button from "@mui/material/Button";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { InspectionFormTypes, UserRoles } from "../../../../types/enums";
import { getInspectionForms } from "../../../../services/inspection-form-service";
import { roleMatch } from "../../../../actions/auth";
export function IncidentSettings() {
  const editFieldProps = {
    field: "Actions",
    headerName: "",
    width: 50,
    type: "actions",
    resizable: false,
    disableColumnMenu: true,
    disableReorder: true,
    disableExport: true,
    hideSortIcons: true,
  };
  const typeColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditLookup(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "code",
      headerName: "Type",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const severityColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditLookup(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "code",
      headerName: "Severity",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const participantColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditLookup(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "code",
      headerName: "Type",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const subTypeColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditLookup(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "parentLookupCode",
      headerName: "Type",
      width: 200,
      type: "string",
    },
    {
      field: "code",
      headerName: "Subtype",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const [incidentTypes, setIncidentTypes] = useState([]);
  const [incidentSubTypes, setIncidentSupTypes] = useState(true);
  const [incidentSeverities, setIncidentServerities] = useState([]);
  const [showLookupModal, setShowLookupModal] = useState(false);
  const [selectedLookup, setSelectedLookup] = useState({});
  const [incidentParticipantTypes, setIncidentParticipantTypes] = useState([]);
  const [incidentForms, setIncidentForms] = useState([]);
  const history = useHistory();
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadIncidentTypes();
    loadIncidentSubTypes();
    loadIncidentSeverities();
    loadIncidentParticipantTypes();
    loadCustomFieldForms();
  };

  const loadCustomFieldForms = () => {
    getInspectionForms({ onlyIncidentForms: true }).then((res) => {
      setIncidentForms(res.data);
    });
  };
  const loadIncidentTypes = () => {
    getLookups("IncidentType", true).then((res) => {
      setIncidentTypes(res.data);
    });
  };

  const loadIncidentSubTypes = () => {
    getLookups("IncidentSubType", true).then((res) => {
      setIncidentSupTypes(res.data);
    });
  };

  const loadIncidentSeverities = () => {
    getLookups("IncidentSeverity", true).then((res) => {
      setIncidentServerities(res.data);
    });
  };

  const loadIncidentParticipantTypes = () => {
    getLookups("IncidentParticipantType", true).then((res) => {
      setIncidentParticipantTypes(res.data);
    });
  };

  const handleEditLookup = (lookup) => {
    setShowLookupModal(true);
    setSelectedLookup(lookup);
  };

  const handleLookupModalClose = () => {
    setShowLookupModal(false);
    setSelectedLookup({});
  };

  const handleLookupSave = () => {
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() == "incidenttype"
    ) {
      loadIncidentTypes();
      loadIncidentSubTypes();
    }
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() == "incidentsubtype"
    ) {
      loadIncidentSubTypes();
    }
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() == "incidentseverity"
    ) {
      loadIncidentSeverities();
    }
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() == "incidentparticipanttypes"
    ) {
      loadIncidentParticipantTypes();
    }
    handleLookupModalClose();
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };
  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Incident Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">Incident Settings</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Incident Types
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              An Incident type is a broad-based category for data entry on the
              Add Incident form (e.g., health and safety, community,
              environmental).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Type"
              descriptionLabel="Description"
              lookupType="IncidentType"
              lookupName="Incident Type"
              handleAddLookup={() => loadIncidentTypes()}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={incidentTypes}
                columns={typeColumns}
                disableMultipleSelection={true}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Incident Subtypes
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              An Incident Subtype is more specific than an Incident Type and is
              attached to specific incident types. (e.g., a water tank leak
              subtype under the environmental type, or a community grievance
              subtype under the community type).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Subtype"
              descriptionLabel="Description"
              lookupType="IncidentSubType"
              lookupName="Incident Subtype"
              hasParentLookup
              parentLookupLabel="Type"
              parentLookups={incidentTypes}
              handleAddLookup={() => loadIncidentSubTypes()}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={incidentSubTypes}
                columns={subTypeColumns}
                disableMultipleSelection={true}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Incident Severities
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Incident Severities are used to track and rank the severity of an
              incident (e.g., high, medium, low or P1-P5). You can also adapt
              your companies risk severity tracking matrix to Incident
              Severities.
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Severity"
              descriptionLabel="Description"
              lookupType="IncidentSeverity"
              lookupName="Incident Severity"
              handleAddLookup={() => loadIncidentSeverities()}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={incidentSeverities}
                columns={severityColumns}
                disableMultipleSelection={true}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Incident Participant Types
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Incident Participant types are the types of participants that are
              either the witness or affected party of the incident. (e.g.,
              Contractor, Employee, Third Party, etc).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Type"
              descriptionLabel="Description"
              lookupType="IncidentParticipantType"
              lookupName="Incident Participant Type"
              handleAddLookup={() => loadIncidentParticipantTypes()}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={incidentParticipantTypes}
                columns={participantColumns}
                disableMultipleSelection={true}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      {roleMatch([UserRoles.InternalUser]) && (
        <Grid.Row>
          <Grid.Col md={2} width={12}>
            <div className="">
              <Typography variant="h5" component="div">
                Incident Forms
              </Typography>
            </div>
          </Grid.Col>

          <Grid.Col md={10} width={12}>
            <Card>
              <Card.Header className="justify-content-end">
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNavigateTo(null, "/settings/incidents/form-builder");
                  }}
                >
                  Add
                </Button>
              </Card.Header>

              <div style={{ height: 630, width: "100%" }}>
                <DataGridPro
                  columns={[
                    {
                      field: "name",
                      headerName: "Name",
                      width: 300,
                      type: "string",
                      renderCell: (params) => {
                        return (
                          <Button
                            onClick={(e) => {
                              handleNavigateTo(
                                e,
                                "/settings/incidents/form-builder/" +
                                  params.row.id
                              );
                            }}
                          >
                            {params.value}
                          </Button>
                        );
                      },
                    },
                    {
                      field: "formType",
                      headerName: "Type",
                      width: 300,
                      type: "string",
                      renderCell: (params) => {
                        return (
                          <span>
                            {params.value ==
                            InspectionFormTypes.IncidentInvestigation
                              ? "Investigation"
                              : "Witness"}
                          </span>
                        );
                      },
                    },
                    {
                      field: "description",
                      headerName: "Description",
                      type: "string",
                      width: 500,
                    },
                  ]}
                  rows={incidentForms}
                  disableColumnReorder
                  disableColumnFilter
                  disableColumnMenu
                  disableSelectionOnClick
                  disableChildrenSorting
                />
              </div>
            </Card>
          </Grid.Col>
        </Grid.Row>
      )}

      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showLookupModal}
        onClose={handleLookupModalClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <EditLookupModal
          lookup={selectedLookup}
          codeLabel="Name"
          descriptionLabel="Description"
          handleModalClose={handleLookupModalClose}
          handleLookupSave={handleLookupSave}
          lookupName={
            selectedLookup.lookupType
              ? selectedLookup.lookupType.toLowerCase() == "unit"
                ? "Unit"
                : selectedLookup.lookupType.toLowerCase() ==
                  "monitoringeventtype"
                ? "Monitoring Event Type"
                : ""
              : ""
          }
        />
      </Dialog>
    </Grid>
  );
}
