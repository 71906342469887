import AirIcon from "@mui/icons-material/Air";
import CabinIcon from "@mui/icons-material/Cabin";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import GrassIcon from "@mui/icons-material/Grass";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import WaterIcon from "@mui/icons-material/Water";
import WaterDropIcon from "@mui/icons-material/WaterDrop";
import Chip from "@mui/material/Chip";
import _ from "lodash";
import { Dimmer } from "tabler-react";
import React, { useEffect, useRef, useState } from "react";
import Map, { Layer, Popup, Source } from "react-map-gl";
import { getMonitoringLocationsForMicrositeMap } from "services/organization-portal-service";
import { MonitoringLocationPortalTypes } from "../../../types/enums";
import { MAPBOX_TOKEN } from "../../dashboards/map/dashboard-map";
import { createLocationsGeojson } from "../../dashboards/map/monitoring-locations-layer";
import "../microsites.css";
import { eaglesNest, marten, site } from "./../eaglesnest";
import { MonitoringLocationMicrositeCard } from "./monitoring-location-microsite-card";
import { wylooColors } from "./wyloo-theme";
import { CircularProgress } from "@mui/material";

const campLatLong = [52.741297360485056, -86.29603403396924];
export function WylooMap(props) {
  const [viewState, setViewState] = useState(
    props.portalTypes &&
      props.portalTypes.includes(MonitoringLocationPortalTypes.Air)
      ? {
          longitude: -86.29377005867119,
          latitude: 52.739946989524924,
          zoom: 14,
        }
      : props.portalTypes &&
        props.portalTypes.includes(MonitoringLocationPortalTypes.Wildlife)
      ? {
          longitude: -86.29492886932297,
          latitude: 52.750978829039525,
          zoom: 11.65,
        }
      : props.portalTypes &&
        props.portalTypes.includes(MonitoringLocationPortalTypes.GroundWater)
      ? {
          longitude: -86.27636132748371,
          latitude: 52.75523787774043,
          zoom: 10.9,
        }
      : {
          longitude: -86.27450405652304,
          latitude: 52.7624773189449,
          zoom: 11.5,
        }
  );

  const [allMonitoringLocations, setAllMonitoringLocations] = useState([]);

  const [monitoringLocations, setMonitoringLocations] = useState([]);
  useEffect(() => {
    getMonitoringLocationsForMicrositeMap(window.location.href).then((res) => {
      setAllMonitoringLocations(res.data);
    });
  }, [props.portalTypes]);
  const [enabledLayers, setEnabledLayers] = useState([]);
  const mapRef = useRef(null);
  const [selectedLocation, setSelectedLocaiton] = useState(null);
  const [popupLocation, setPopupLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (props.selectedLocation) {
      mapRef.current?.flyTo({
        center: [
          props.selectedLocation.longitude,
          props.selectedLocation.latitude,
        ],
        zoom: 14,
        duration: 2000,
      });
    }
  }, [props.selectedLocation]);
  useEffect(() => {
    if (!props.portalTypes) {
      setEnabledLayers([
        "air",
        "wildlife",
        "water",
        "asset",
        "vegetation",
        "camp",
        "marten",
      ]);
    }
    if (
      props.portalTypes &&
      props.portalTypes.includes(MonitoringLocationPortalTypes.GroundWater) &&
      props.portalTypes.includes(MonitoringLocationPortalTypes.SurfaceWater)
    ) {
      setEnabledLayers([...props.portalTypes, "camp", "asset", "marten"]);
    }
    if (
      props.portalTypes &&
      props.portalTypes.includes(MonitoringLocationPortalTypes.Wildlife)
    ) {
      setEnabledLayers(["wildlife", "camp", "asset", "marten"]);
    }

    if (
      props.portalTypes &&
      props.portalTypes.includes(MonitoringLocationPortalTypes.Air)
    ) {
      setEnabledLayers(["camp", "asset", "marten", "air"]);
    }
  }, []);

  useEffect(() => {
    var monLocs = [];
    if (enabledLayers.includes("air")) {
      monLocs = [
        ...monLocs,
        ...allMonitoringLocations.filter(
          (x) => x.portalType == MonitoringLocationPortalTypes.Air
        ),
      ];
    }
    if (enabledLayers.includes("wildlife")) {
      monLocs = [
        ...monLocs,
        ...allMonitoringLocations.filter(
          (x) => x.portalType == MonitoringLocationPortalTypes.Wildlife
        ),
      ];
    }
    if (enabledLayers.includes("vegetation")) {
      monLocs = [
        ...monLocs,
        ...allMonitoringLocations.filter(
          (x) => x.portalType == MonitoringLocationPortalTypes.Vegetation
        ),
      ];
    }
    if (enabledLayers.includes("water")) {
      monLocs = [
        ...monLocs,
        ...allMonitoringLocations.filter(
          (x) =>
            x.portalType == MonitoringLocationPortalTypes.SurfaceWater ||
            x.portalType == MonitoringLocationPortalTypes.GroundWater
        ),
      ];
    }
    if (enabledLayers.includes(MonitoringLocationPortalTypes.SurfaceWater)) {
      monLocs = [
        ...monLocs,
        ...allMonitoringLocations.filter(
          (x) => x.portalType == MonitoringLocationPortalTypes.SurfaceWater
        ),
      ];
    }
    if (enabledLayers.includes(MonitoringLocationPortalTypes.GroundWater)) {
      monLocs = [
        ...monLocs,
        ...allMonitoringLocations.filter(
          (x) => x.portalType == MonitoringLocationPortalTypes.GroundWater
        ),
      ];
    }
    setMonitoringLocations(monLocs);
  }, [allMonitoringLocations, enabledLayers]);

  const assetLayerStyle = {
    id: "line",
    type: "line",
    paint: {
      "line-color": wylooColors.orange,
      "line-width": 2,
    },
    layout: {
      visibility: enabledLayers.includes("asset") ? "visible" : "none",
    },
  };

  const campLayerStyle = {
    id: "camp",
    type: "line",
    paint: {
      "line-color": wylooColors.grey,
      "line-width": 4,
    },
    layout: {
      visibility: enabledLayers.includes("camp") ? "visible" : "none",
    },
  };

  const martenLayerStyle = {
    id: "fill",
    type: "fill",
    paint: {
      "fill-color": wylooColors.black,
      "fill-opacity": 0.8,
    },
    layout: {
      visibility: enabledLayers.includes("asset") ? "visible" : "none",
    },
  };
  const handleMapLoad = () => {
    mapRef.current.loadImage(
      "/icons/cabin_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.png",
      (error, image) => {
        if (error) throw error;
        mapRef.current.addImage("cabin-marker", image, { sdf: true });
      }
    );

    mapRef.current.loadImage(
      "/icons/water_drop_24dp_5F6368.png",
      (error, image) => {
        if (error) throw error;
        mapRef.current.addImage("water-marker", image, { sdf: true });
      }
    );
    if (!props.portalTypes) {
      mapRef.current.loadImage("/icons/air_24dp_5F6368.png", (error, image) => {
        if (error) throw error;
        mapRef.current.addImage("air-marker", image, { sdf: true });
      });
      mapRef.current.loadImage(
        "/icons/grass_24dp_5F6368_FILL0_wght400_GRAD0_opsz24.png",
        (error, image) => {
          if (error) throw error;
          mapRef.current.addImage("veg-marker", image, { sdf: true });
        }
      );

      mapRef.current.loadImage(
        "/icons/photo_camera_24dp_5F6368.png",
        (error, image) => {
          if (error) throw error;
          mapRef.current.addImage("photo-marker", image, { sdf: true });
        }
      );
    }
    if (
      props.portalTypes &&
      props.portalTypes.includes(MonitoringLocationPortalTypes.Wildlife)
    ) {
      mapRef.current.loadImage(
        "/icons/photo_camera_24dp_5F6368.png",
        (error, image) => {
          if (error) throw error;
          mapRef.current.addImage("photo-marker", image, { sdf: true });
        }
      );
    }
    if (
      props.portalTypes &&
      props.portalTypes.includes(MonitoringLocationPortalTypes.Air)
    ) {
      mapRef.current.loadImage("/icons/air_24dp_5F6368.png", (error, image) => {
        if (error) throw error;
        mapRef.current.addImage("air-marker", image, { sdf: true });
      });
    }
    if (
      props.portalTypes &&
      props.portalTypes.includes(MonitoringLocationPortalTypes.GroundWater)
    ) {
      mapRef.current.loadImage("/icons/well.png", (error, image) => {
        if (error) throw error;
        mapRef.current.addImage("ground-marker", image, { sdf: true });
      });
    }
    if (
      props.portalTypes &&
      props.portalTypes.includes(MonitoringLocationPortalTypes.SurfaceWater)
    ) {
      mapRef.current.loadImage("/icons/water.png", (error, image) => {
        if (error) throw error;
        mapRef.current.addImage("surface-marker", image, { sdf: true });
      });
    }

    mapRef.current.on("mouseenter", "monitoring_locations", () => {
      mapRef.current.getCanvas().style.cursor = "pointer";
    });
    mapRef.current.on("mouseleave", "monitoring_locations", () => {
      mapRef.current.getCanvas().style.cursor = "";
    });

    setIsLoading(false);
  };

  const handleMapClick = (e) => {
    e.originalEvent.stopPropagation();

    if (!_.isEmpty(e.features)) {
      if (props.handleFeatureClick) {
        props.handleFeatureClick(e.features[0]);
      } else {
        setSelectedLocaiton(e.features[0].properties);
        setPopupLocation(e.lngLat);
      }
    }
  };
  const onViewportChange = (e) => {
    const viewport = e.viewState;

    setViewState(viewport);
  };

  const handleToggleLayers = (layer) => {
    if (enabledLayers.includes(layer)) {
      setEnabledLayers(enabledLayers.filter((l) => l != layer));
    } else {
      setEnabledLayers([...enabledLayers, layer]);
    }
  };

  return (
    <>
      <Dimmer active={isLoading} loader>
        <div
          style={{
            height: !props.portalTypes ? 800 : 500,
            width: "100%",
            position: "relative",
            borderRadius: 25,
          }}
          className="wyloo-colors"
        >
          {isLoading && <CircularProgress />}

          <div className="wyloo-map-legend">
            <Chip
              variant="outlined"
              className={`mr-2 ml-2 ${
                !enabledLayers.includes("asset") ? "disabled-chip" : ""
              }`}
              icon={
                <CropSquareIcon
                  sx={{ color: wylooColors.orange + " !important" }}
                  color={wylooColors.orange}
                />
              }
              label="Wyloo Lease"
              onClick={(e) => {
                handleToggleLayers("asset");
              }}
            />
            <Chip
              variant="outlined"
              className={`mr-2 ml-2 ${
                !enabledLayers.includes("camp") ? "disabled-chip" : ""
              }`}
              icon={
                <CropSquareIcon
                  sx={{ color: wylooColors.grey + " !important" }}
                  color={wylooColors.grey}
                />
              }
              label="Eagle's Nest"
              onClick={(e) => {
                handleToggleLayers("camp");
              }}
            />{" "}
            {props.portalTypes &&
              props.portalTypes.includes(
                MonitoringLocationPortalTypes.GroundWater
              ) && (
                <Chip
                  variant="outlined"
                  className={`mr-2 ml-2 ${
                    !enabledLayers.includes(
                      MonitoringLocationPortalTypes.GroundWater
                    )
                      ? "disabled-chip"
                      : ""
                  }`}
                  icon={
                    <WaterDropIcon
                      sx={{ color: wylooColors.skyBlue + " !important" }}
                    />
                  }
                  label="Ground Water"
                  onClick={() => {
                    handleToggleLayers(
                      MonitoringLocationPortalTypes.GroundWater
                    );
                  }}
                />
              )}{" "}
            {props.portalTypes &&
              props.portalTypes.includes(
                MonitoringLocationPortalTypes.SurfaceWater
              ) && (
                <Chip
                  variant="outlined"
                  className={`mr-2 ml-2 ${
                    !enabledLayers.includes(
                      MonitoringLocationPortalTypes.SurfaceWater
                    )
                      ? "disabled-chip"
                      : ""
                  }`}
                  icon={
                    <WaterIcon
                      sx={{ color: wylooColors.skyBlue + " !important" }}
                    />
                  }
                  label="Surface Water"
                  onClick={() => {
                    handleToggleLayers(
                      MonitoringLocationPortalTypes.SurfaceWater
                    );
                  }}
                />
              )}
            {props.portalTypes &&
              props.portalTypes.includes(
                MonitoringLocationPortalTypes.Wildlife
              ) && (
                <Chip
                  variant="outlined"
                  className={`mr-2 ${
                    !enabledLayers.includes("wildlife") ? "disabled-chip" : ""
                  }`}
                  icon={
                    <PhotoCameraIcon
                      sx={{ color: wylooColors.tan + " !important" }}
                    />
                  }
                  label="Wildlife Monitoring"
                  onClick={() => {
                    handleToggleLayers("wildlife");
                  }}
                />
              )}
            {props.portalTypes &&
              props.portalTypes.includes(MonitoringLocationPortalTypes.Air) && (
                <Chip
                  variant="outlined"
                  className={`mr-2 ${
                    !enabledLayers.includes("air") ? "disabled-chip" : ""
                  }`}
                  icon={
                    <AirIcon sx={{ color: wylooColors.blue + " !important" }} />
                  }
                  label="Air Monitoring"
                  onClick={() => {
                    handleToggleLayers("air");
                  }}
                />
              )}
            {!props.portalTypes && (
              <>
                <Chip
                  variant="outlined"
                  className={`mr-2 ${
                    !enabledLayers.includes("wildlife") ? "disabled-chip" : ""
                  }`}
                  icon={
                    <PhotoCameraIcon
                      sx={{ color: wylooColors.tan + " !important" }}
                    />
                  }
                  label="Wildlife Monitoring"
                  onClick={() => {
                    handleToggleLayers("wildlife");
                  }}
                />
                <Chip
                  variant="outlined"
                  className={`mr-2 ${
                    !enabledLayers.includes("air") ? "disabled-chip" : ""
                  }`}
                  icon={
                    <AirIcon sx={{ color: wylooColors.blue + " !important" }} />
                  }
                  label="Air Monitoring"
                  onClick={() => {
                    handleToggleLayers("air");
                  }}
                />
                <Chip
                  variant="outlined"
                  className={`mr-2 ${
                    !enabledLayers.includes("water") ? "disabled-chip" : ""
                  }`}
                  icon={
                    <WaterDropIcon
                      sx={{ color: wylooColors.skyBlue + " !important" }}
                    />
                  }
                  label="Water Monitoring"
                  onClick={() => {
                    handleToggleLayers("water");
                  }}
                />
                <Chip
                  variant="outlined"
                  className={`mr-2 ${
                    !enabledLayers.includes("vegetation") ? "disabled-chip" : ""
                  }`}
                  icon={
                    <GrassIcon
                      sx={{ color: wylooColors.green + " !important" }}
                    />
                  }
                  label="Vegetation Plot"
                  onClick={() => {
                    handleToggleLayers("vegetation");
                  }}
                />
              </>
            )}
          </div>
          <Map
            mapboxAccessToken={MAPBOX_TOKEN}
            ref={mapRef}
            {...viewState}
            onMove={onViewportChange}
            interactiveLayerIds={["monitoring_locations"]}
            onClick={(e) => handleMapClick(e)}
            onLoad={handleMapLoad}
            style={{ width: "100%", height: "100%", borderRadius: 25 }}
            mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
          >
            <Source id="my-data" type="geojson" data={eaglesNest}>
              <Layer {...assetLayerStyle} />
            </Source>

            <Source id="marten" type="geojson" data={marten}>
              <Layer {...martenLayerStyle} />
            </Source>
            <Source type="geojson" id="esker-camp" data={site}>
              <Layer {...campLayerStyle}></Layer>
            </Source>

            <Source
              type="geojson"
              id="monitoring-locations"
              data={createLocationsGeojson(monitoringLocations)}
            >
              <Layer
                type="symbol"
                id="monitoring_locations"
                layout={{
                  "icon-allow-overlap": false,
                  "icon-image":
                    props.portalTypes &&
                    props.portalTypes.includes(
                      MonitoringLocationPortalTypes.GroundWater
                    )
                      ? [
                          "match",
                          ["get", "portalType"],
                          MonitoringLocationPortalTypes.SurfaceWater,
                          "surface-marker",
                          "water-marker",
                        ]
                      : [
                          "match",
                          ["get", "portalType"],
                          MonitoringLocationPortalTypes.Air,
                          "air-marker",
                          MonitoringLocationPortalTypes.Wildlife,
                          "photo-marker",
                          MonitoringLocationPortalTypes.Vegetation,
                          "veg-marker",
                          "water-marker",
                        ],
                  "icon-size": 0.5,
                  "symbol-sort-key": [
                    "match",
                    ["get", "portalType"],
                    MonitoringLocationPortalTypes.Air,
                    1,
                    MonitoringLocationPortalTypes.Wildlife,
                    3,
                    MonitoringLocationPortalTypes.Vegetation,
                    2,
                    4,
                  ],
                }}
                paint={{
                  "icon-color": [
                    "match",
                    ["get", "portalType"],
                    MonitoringLocationPortalTypes.Air,
                    wylooColors.blue,
                    MonitoringLocationPortalTypes.Wildlife,
                    wylooColors.tan,
                    MonitoringLocationPortalTypes.Vegetation,
                    wylooColors.green,
                    wylooColors.skyBlue,
                  ],
                }}
              ></Layer>
            </Source>

            {popupLocation && (
              <Popup
                maxWidth="500px"
                longitude={_.toNumber(popupLocation.lng)}
                latitude={_.toNumber(popupLocation.lat)}
                closeOnClick={false}
                onClose={() => setPopupLocation(null)}
              >
                <MonitoringLocationMicrositeCard entity={selectedLocation} />
              </Popup>
            )}
          </Map>
        </div>
      </Dimmer>
    </>
  );
}
