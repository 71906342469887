import _, { set } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Dimmer } from "tabler-react";
import { chartService } from "../../../services/chart-service";
import { SearchableSelect } from "../../form-controls/searchable-select";
import {
  getDistinctParamtersPerMonitoringLocation,
  getDistinctParamtersPerPortalType,
  getLocationsPerPortalType,
  getMonitoringLocationForMicrositeMap,
} from "../../../services/organization-portal-service";
import { Chip, Typography, Tooltip as TT, Slider } from "@mui/material";
import { wylooColors } from "./wyloo-theme";
export function WylooLineChart(props) {
  const minimumDistance = 1;
  const [isLoading, setIsLoading] = useState(false);
  const [opacity, setOpacity] = useState({});
  const [dateRange, setDateRange] = useState(null);
  const [originalDateRange, setOriginalDateRange] = useState(null);
  const [originalChartData, setOriginalChartData] = useState([]);
  const [events, setEvents] = useState([]);
  const [distinctParameters, setDistinctParameters] = useState([]);
  const [selectedParameters, setSelectedParameters] = useState([]);
  const [value, setValue] = React.useState([0, 0]);
  const [monitoringLocations, setMonitoringLocations] = useState([]);
  const [maxValue, setMaxValue] = useState(0);
  const [originalMarks, setOriginalMarks] = useState([]);
  const [marks, setMarks] = useState([]);
  const [selectedMonitoringLocations, setSelectedMonitoringLocations] =
    useState(null);

  useEffect(() => {
    if (!_.isEmpty(props.portalTypes)) {
      setSelectedParameters(null);
      getDistinctParamtersPerPortalType(
        props.portalTypes,
        window.location.href
      ).then((response) => {
        setDistinctParameters(response.data);
      });
      getLocationsPerPortalType(props.portalTypes, window.location.href).then(
        (response) => {
          setMonitoringLocations(response.data);
        }
      );
    }
  }, [props.portalTypes]);
  useEffect(() => {
    if (props.monitoringLocation) {
      setSelectedMonitoringLocations(props.monitoringLocation);
    }
  }, [props.monitoringLocation]);

  useEffect(() => {
    if (props.isForDetails && !_.isEmpty(props.monitoringLocation)) {
      getDistinctParamtersPerMonitoringLocation(
        props.monitoringLocation.id
      ).then((res) => {
        setDistinctParameters(res.data);
      });
    }
  }, [props.isForDetails, props.monitoringLocation]);
  useEffect(() => {
    if (props.isForDetails && !_.isEmpty(props.events)) {
      setEvents(props.events);
    }
  }, [props.isForDetails, props.events]);
  useEffect(() => {
    if (
      selectedMonitoringLocations != null &&
      selectedMonitoringLocations &&
      !props.isForDetails
    ) {
      getMonitoringLocationForMicrositeMap(
        selectedMonitoringLocations.id,
        window.location.href
      ).then((res) => {
        setEvents(res.data.monitoringEvents);
      });
    }
  }, [selectedMonitoringLocations]);
  useEffect(() => {
    if (!_.isEmpty(originalChartData)) {
      let opacityToSet = {};
      Object.entries(originalChartData[0])
        .filter((x) => x[0] !== "name")
        .forEach((object) => {
          opacityToSet = { ...opacityToSet, [object[0]]: 1 };
        });
      setOpacity(opacityToSet);
    }
  }, [originalChartData]);
  useEffect(() => {
    if (
      !_.isEmpty(events) &&
      selectedParameters != null &&
      !_.isEmpty(selectedParameters)
    ) {
      setupChartData(
        props.isForDetails ? selectedParameters : [selectedParameters]
      );
    }
  }, [events, selectedParameters]);
  useEffect(() => {
    if (
      selectedMonitoringLocations == null ||
      selectedParameters == null ||
      _.isEmpty(selectedParameters)
    ) {
      setOriginalChartData([]);
    }
  }, [selectedMonitoringLocations, selectedParameters]);

  function monthDiff(d1, d2) {
    d1 = new Date(d1);
    d2 = new Date(d2);
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  }
  const getValueLabel = (value) => {
    if (originalDateRange == null) return "";
    let minDate = originalDateRange[0];
    var labelDate = moment.unix(minDate).add(value, "months").format("YYYY-MM");
    return labelDate;
  };
  const handleChange = (event, newValue, activeThumb) => {
    let firstValue = newValue[0];
    let secondValue = newValue[1];
    if (activeThumb === 0) {
      firstValue = Math.min(newValue[0], value[1] - minimumDistance);
    } else {
      secondValue = Math.max(newValue[1], value[0] + minimumDistance);
    }
    let newMinDateUnix = moment
      .unix(originalDateRange[0])
      .add(firstValue, "months")
      .unix();
    let newMaxDateUnix = moment
      .unix(originalDateRange[0])
      .add(secondValue, "months")
      .unix();

    let marksToSet = [...originalMarks];
    if (firstValue != 0) {
      let newMinMark = {
        value: firstValue,
        label: moment
          .unix(originalDateRange[0])
          .add(firstValue, "months")
          .format("YYYY-MM"),
      };
      marksToSet = [...marksToSet, newMinMark];
    }
    if (secondValue != maxValue) {
      let newMaxMark = {
        value: secondValue,
        label: moment
          .unix(originalDateRange[0])
          .add(secondValue, "months")
          .format("YYYY-MM"),
      };
      marksToSet = [...marksToSet, newMaxMark];
    }
    setMarks(marksToSet);
    //setChartData(newChartData);
    setDateRange([newMinDateUnix, newMaxDateUnix]);
    setValue([firstValue, secondValue]);
  };
  const setupChartData = (paramList = null) => {
    setIsLoading(true);
    var completedEvents = _.orderBy(events, "endDateTimeUtc").filter(
      (x) => x.endDateTimeUtc != null && x.monitoringResults.length > 0
    );
    let dataArray = [];
    var parameterList = [];
    var dateRangeToSet = [];
    for (let i = 0; i < completedEvents.length; i++) {
      if (i == 0) {
        dateRangeToSet[0] = moment(completedEvents[i].endDateTimeUtc).unix();
      }
      if (i == completedEvents.length - 1) {
        dateRangeToSet[1] = moment(completedEvents[i].endDateTimeUtc).unix();
      }
      let dataObject = {
        name: moment(completedEvents[i].endDateTimeUtc).unix(),
      };
      var monitoringResults = _.orderBy(completedEvents[i].monitoringResults, [
        "parameterName",
      ]);
      if (paramList) {
        monitoringResults = monitoringResults.filter((x) =>
          paramList.map((x) => x.id).includes(x.parameterId)
        );
      }
      for (let x = 0; x < monitoringResults.length; x++) {
        let paramName = monitoringResults[x].parameterName;
        dataObject = {
          ...dataObject,
          [paramName]: monitoringResults[x].value,
        };

        var existingParameter = parameterList.find((x) => x == paramName);
        if (!existingParameter) {
          parameterList.push(monitoringResults[x].parameterName);
        }
      }
      dataArray.push(dataObject);
    }
    setOriginalDateRange(dateRangeToSet);
    setDateRange(null);
    if (props.isForDetails) {
      parameterList.forEach((param) => {
        dataArray.forEach((item) => {
          if (!item[param]) {
            item[param] = 0;
          }
        });
      });
    } else {
      dataArray.forEach((item) => {
        if (!item[selectedParameters.name]) {
          item[selectedParameters.name] = 0;
        }
      });
    }
    if (dateRangeToSet != null) {
      var sliderDateRange = [
        moment.unix(dateRangeToSet[0]).format("YYYY-MM-DD"),
        moment.unix(dateRangeToSet[1]).format("YYYY-MM-DD"),
      ];
      var monthsBetween = monthDiff(sliderDateRange[0], sliderDateRange[1]);
      setMaxValue(monthsBetween);
      let marksToSet = [
        { value: 0, label: moment.utc(sliderDateRange[0]).format("YYYY-MM") },
        {
          value: monthsBetween,
          label: moment.utc(sliderDateRange[1]).format("YYYY-MM"),
        },
      ];
      setMarks(marksToSet);
      setOriginalMarks(marksToSet);
      setValue([0, monthsBetween]);
    }
    setOriginalChartData(dataArray);

    setIsLoading(false);
  };

  const handleMouseEnter = (o, index, event) => {
    const { dataKey } = o;
    let oldOpacity = { ...opacity };
    let newOpacity = {};

    Object.entries(oldOpacity).forEach((object) => {
      let key = object[0];
      if (object[0] == dataKey) {
        newOpacity = { ...newOpacity, [o.dataKey]: 1 };
      } else {
        newOpacity = { ...newOpacity, [key]: 1 };
      }
    });
    setOpacity(newOpacity);
  };
  const handleMouseLeave = (o) => {
    let oldOpacity = { ...opacity };
    let newOpacity = {};

    Object.entries(oldOpacity).forEach((object) => {
      let key = object[0];

      newOpacity = { ...newOpacity, [key]: 1 };
    });
    setOpacity(newOpacity);
  };
  const dateFormatter = (date) => {
    return moment.unix(date).format("MM/DD/YYYY");
  };

  const getUnit = (props) => {
    var matchingEvent = events.find(
      (a) =>
        moment(a.endDateTimeUtc).format("MM/DD/YYYY HH:mm") ==
        moment.unix(props.payload.name).format("MM/DD/YYYY HH:mm")
    );
    var matchingResult = matchingEvent.monitoringResults.find(
      (a) => a.parameterName == props.dataKey
    );
    return matchingResult ? matchingResult.unitName : "";
  };
  return (
    <>
      <div className="wyloo-text-section">
        {!props.monitoringLocation && (
          <SearchableSelect
            className="mb-2"
            name={`selectedMonitoringLocations`}
            options={monitoringLocations}
            id="id"
            labelFunction={(value) => {
              return `${value.name}${
                value.monitoringLocationType
                  ? ` (${value.monitoringLocationType})`
                  : ""
              }${value.description ? ` - ${value.description}` : ""}`;
            }}
            value={selectedMonitoringLocations}
            placeholder={"Search or choose locations from list"}
            onChange={(e) => {
              props.handleLocationSelected(_.isEmpty(e) ? null : e);
              setSelectedMonitoringLocations(_.isEmpty(e) ? null : e);
            }}
          />
        )}
        <SearchableSelect
          name={`selectedParameters`}
          options={distinctParameters}
          multiple={props.isForDetails}
          id="id"
          labelFunction={(value) => {
            return `${value.name}${
              value.description ? ` - ${value.description}` : ""
            }`;
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => (
              <TT title={option.description}>
                <Chip
                  sx={{
                    backgroundColor: wylooColors.white,
                    borderColor: wylooColors.black,
                  }}
                  {...getTagProps({ index })}
                  label={option.name}
                />
              </TT>
            ));
          }}
          value={selectedParameters}
          placeholder={"Search or choose parameters from list"}
          onChange={(e) => {
            setSelectedParameters(
              _.isEmpty(e) ? (props.isForDetails ? [] : null) : e
            );
          }}
        />
        <Dimmer active={isLoading} loader>
          <div style={{ width: "100%", height: 400 }} className="mt-2">
            {(!selectedMonitoringLocations ||
              !selectedParameters ||
              _.isEmpty(selectedParameters)) && (
              <Typography variant="h5" className="pt-4 pl-4">
                {!props.isForDetails
                  ? `Select a parameter and monitoring location to view data`
                  : "Select one or more parameters to view data"}
              </Typography>
            )}
            {!_.isEmpty(originalChartData) && (
              <ResponsiveContainer>
                <LineChart
                  data={originalChartData}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 0,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    scale={"time"}
                    type="number"
                    domain={dateRange ?? originalDateRange}
                    tickFormatter={dateFormatter}
                    allowDataOverflow
                  />
                  <YAxis />
                  <Tooltip
                    formatter={(value, name, props) => {
                      return `${value} ${getUnit(props)}`;
                    }}
                    labelFormatter={dateFormatter}
                    contentStyle={{
                      zIndex: 1000,
                    }}
                    wrapperStyle={{
                      zIndex: 1000,
                      height: 500,
                      pointerEvents: "auto",
                    }}
                  />
                  <Legend
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    wrapperStyle={{
                      padding: "0 2rem",
                      maxHeight: "30%",
                      overflow: "auto",
                    }}
                  />
                  {!_.isEmpty(originalChartData) &&
                    Object.entries(originalChartData[0])
                      .filter((x) => x[0] !== "name")
                      .map((object, index) => (
                        <Line
                          type="monotone"
                          key={index}
                          dataKey={object[0]}
                          stroke={chartService.getChartColorByIndexWyloo(index)}
                          strokeOpacity={opacity[object[0]]}
                        />
                      ))}
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
          {!_.isEmpty(originalChartData) && (
            <div className="pl-5 pr-5">
              <Slider
                size="small"
                disableSwap
                className="mb-2"
                aria-label="Date Filter"
                step={1}
                color="secondary"
                value={value}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => <div>{getValueLabel(value)}</div>}
                marks={marks}
                min={0}
                max={maxValue}
                onChange={handleChange}
              />
            </div>
          )}
        </Dimmer>{" "}
      </div>
    </>
  );
}
