import _ from "lodash";
import moment from "moment";
import { apiUrl, client } from "../modules/store";
const isEqualTo = ["is", "equals", "="];
const isNotEqualTo = ["!=", "not"];
const startsWith = ["startsWith"];
const endsWith = ["endsWith"];
const contains = ["contains"];
const doesNotContain = ["doesNotContain"];
const isEmpty = ["isEmpty"];
const isNotEmpty = ["isNotEmpty"];
const isGreaterThan = [">", "after"];
const isGreatThanOrEqualTo = [">=", "onOrAfter"];
const isLessThan = ["<", "before"];
const isLessThanOrEqualTo = ["<=", "onOrBefore"];
const isAnyOf = ["isAnyOf"];
const isRange = ["isRange"];
const isBetween = ["isBetween"];

export function performGroupByTranslations(field, value) {
  if (value == "Empty") {
    return "null";
  }
  if (
    field == "eventDateTime" ||
    field == "endDateTimeUtc" ||
    field == "startDateTimeUtc" ||
    field == "lastReviewedDateTimeUtc"
  ) {
    return moment(value).utc().format();
  }

  if (field == "isNonCompliant") {
    return value == "Noncompliant" ? "true" : "false";
  }
  if (value == "True") {
    return "true";
  }
  if (value == "False") {
    return "false";
  }

  return value;
}

export function performValueTranslations(item) {
  if (!item.value) return null;
  if (item.value == "Empty") {
    return "null";
  }
  if (
    (item.columnField == "eventDateTime" ||
      item.columnField == "endDateTimeUtc" ||
      item.columnField == "startDateTimeUtc" ||
      item.columnField == "lastReviewedDateTimeUtc" ||
      item.columnField == "incidentDateTime" ||
      (item.columnField.includes("customFieldData.") &&
        item.columnField.includes(".dateTimeValue"))) &&
    item.operatorValue != "isRange" &&
    item.operatorValue != "isBetween"
  ) {
    return moment(item.value).utc().format();
  }

  if (item.operatorValue == "isBetween" && item.value) {
    if (item.value[0] != "today") {
      item.value[0] = moment(item.value[0]).utc().format();
    }
    if (item.value[1] != "today") {
      item.value[1] = moment(item.value[1]).utc().format();
    }
  }
  if (item.columnField == "isNonCompliant") {
    return item.value == "Noncompliant" ? "true" : "false";
  }
  if (item.value == "True") {
    return "true";
  }
  if (item.value == "False") {
    return "false";
  }

  return item.value;
}
export function convertFilterDefToQueryString(filterDef) {
  var queryString = "";
  var filterOperator = filterDef.linkOperator === "or" ? "//" : "++";
  filterDef.items.forEach((item) => {
    item.value = performValueTranslations(item);

    if (_.includes(isEqualTo, item.operatorValue) && item.value) {
      queryString += `${item.columnField}==${item.value}${filterOperator}`;
    }
    if (_.includes(isNotEqualTo, item.operatorValue) && item.value) {
      queryString += `${item.columnField}!=${item.value}${filterOperator}`;
    }
    if (_.includes(startsWith, item.operatorValue) && item.value) {
      queryString += `${item.columnField}=^${item.value}${filterOperator}`;
    }
    if (_.includes(endsWith, item.operatorValue) && item.value) {
      queryString += `${item.columnField}=$${item.value}${filterOperator}`;
    }
    if (_.includes(contains, item.operatorValue) && item.value) {
      queryString += `${item.columnField}=@${item.value}${filterOperator}`;
    }
    if (_.includes(doesNotContain, item.operatorValue) && item.value) {
      queryString += `${item.columnField}!@${item.value}${filterOperator}`;
    }
    if (_.includes(isEmpty, item.operatorValue)) {
      queryString += `${item.columnField}!!${filterOperator}`;
    }
    if (_.includes(isNotEmpty, item.operatorValue)) {
      queryString += `${item.columnField}@@${filterOperator}`;
    }
    if (_.includes(isGreaterThan, item.operatorValue) && item.value) {
      queryString += `${item.columnField}>>${item.value}${filterOperator}`;
    }
    if (_.includes(isGreatThanOrEqualTo, item.operatorValue) && item.value) {
      queryString += `${item.columnField}>=${item.value}${filterOperator}`;
    }
    if (_.includes(isLessThan, item.operatorValue) && item.value) {
      queryString += `${item.columnField}<<${item.value}${filterOperator}`;
    }
    if (_.includes(isLessThanOrEqualTo, item.operatorValue) && item.value) {
      queryString += `${item.columnField}<=${item.value}${filterOperator}`;
    }
    if (_.includes(isAnyOf, item.operatorValue) && !_.isEmpty(item.value)) {
      var valueString = item.value.join("|");
      queryString += `${item.columnField}<>${valueString}${filterOperator}`;
    }
    if (_.includes(isRange, item.operatorValue) && !_.isEmpty(item.value)) {
      var valueStringRange = item.value.join("|");
      queryString += `${item.columnField}^^${valueStringRange}${filterOperator}`;
    }
    if (_.includes(isBetween, item.operatorValue) && !_.isEmpty(item.value)) {
      var valueStringBetween = item.value.join("|");
      queryString += `${item.columnField}**${valueStringBetween}${filterOperator}`;
    }
  });
  return queryString;
}

export function saveFilter(dto) {
  return client.post(apiUrl + "/filters", {
    ...dto,
  });
}

export function getFilters(entityType) {
  return client.get(apiUrl + "/filters?entityType=" + entityType);
}

export function deleteFilter(filterId) {
  return client.delete(apiUrl + "/filters/" + filterId);
}
