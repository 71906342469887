import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import { TimelineOppositeContent } from "@mui/lab";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Link from "@mui/material/Link";
import moment from "moment";
import React, { useEffect, useState } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { dateUtil } from "../../../services/date-util";
export function EventDetailsLinkedEvents(props) {
  const [linkedEvents, setLinkedEvents] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const eventMenuOpen = Boolean(anchorEl);
  const history = useHistory();
  useEffect(() => {
    var allEvents = [props.event];
    if (!_.isEmpty(props.event.linkedEvents)) {
      allEvents = [...props.event.linkedEvents, ...allEvents];
    }

    setLinkedEvents(
      allEvents.sort(function (a, b) {
        return (
          new Date(
            b.eventDateTime ||
              dateUtil.convertDateOnlyToLocal(b.eventDueDateTime)
          ) -
          new Date(
            a.eventDateTime ||
              dateUtil.convertDateOnlyToLocal(a.eventDueDateTime)
          )
        );
      })
    );
  }, [props.event.linkedEvents]);
  const getDotColor = (event) => {
    switch (_.toLower(event.status)) {
      case "pending review":
      case "pending actions":
        return "secondary";
      case "incomplete (overdue)":
        return "error";
      case "incomplete":
        return "success";
      default:
        return "primary";
    }
  };
  const handleEventAddClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEventMenuClose = () => {
    setAnchorEl(null);
  };
  const handleEventNumberClick = (e, event) => {
    e.preventDefault();
    history.push(`/events/${event.id}`);
  };

  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      <CardHeader
        className="lynx-card-header"
        title="Linked Events"
        action={
          <>
            <Button
              id="basic-button"
              aria-controls={eventMenuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={eventMenuOpen ? "true" : undefined}
              onClick={handleEventAddClick}
            >
              Add Linked Event
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={eventMenuOpen}
              onClose={handleEventMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  props.handleAddLinkedEvent(false);
                  handleEventMenuClose();
                }}
              >
                Add Event
              </MenuItem>
              <MenuItem
                onClick={() => {
                  props.handleAddLinkedEvent(true);
                  handleEventMenuClose();
                }}
              >
                Schedule Event
              </MenuItem>
            </Menu>
          </>
        }
      />

      <CardContent>
        <Timeline alight="left" className="mb-0 p-0">
          {!props.isLoading &&
            linkedEvents.map((event) => (
              <TimelineItem key={event.id}>
                <TimelineOppositeContent style={{ flex: 0.1 }}>
                  <div style={{ width: "100px" }}>
                    {event.eventDateTime != null
                      ? moment(event.eventDateTime).utc().format("YYYY-MM-DD")
                      : moment
                          .utc(
                            dateUtil.convertDateOnlyToLocal(
                              event.eventDueDateTime
                            )
                          )
                          .format("YYYY-MM-DD")}
                  </div>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot
                    variant="outlined"
                    {...(props.event.id == event.id
                      ? null
                      : { color: getDotColor(event) })}
                  >
                    <EventOutlinedIcon
                      fontSize="small"
                      sx={{ color: "#495057" }}
                    />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <div className="d-flex">
                    <div>
                      Event&nbsp;
                      {event.id == props.event.id ? (
                        `#${event.eventNumber}`
                      ) : (
                        <Link
                          underline="hover"
                          href="#"
                          onClick={(e) => handleEventNumberClick(e, event)}
                        >
                          #{event.eventNumber}
                        </Link>
                      )}
                      &nbsp;
                      {`(${event.eventCategory}${
                        event.eventSubCategory
                          ? ` - ${event.eventSubCategory}`
                          : ``
                      })`}
                      &nbsp;
                      {event.eventDateTime ? "completed." : "is due."}
                    </div>
                  </div>
                </TimelineContent>
              </TimelineItem>
            ))}
        </Timeline>
      </CardContent>
    </Card>
  );
}
