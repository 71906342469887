import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import alertify from "alertifyjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form, Grid } from "tabler-react";
import lynxColors from "../../../modules/lynxColors";
import { validationService } from "../../../services";
import {
  createMshaAsset,
  deleteMshaAsset,
  updateMshaAsset,
} from "../../../services/msha-service";
import { LynxDialog } from "../../lynx-dialog";
export function MshaEditAsset(props) {
  const [asset, setAsset] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    nameError: "",
    assetNumber: "",
    assetNumberError: "",
  });
  const isExistingAsset = props.asset != null;
  const handleInputChange = (e) => {
    let newState = { ...formState };
    let name = e.target.name;
    let value = e.target.value;

    _.set(newState, name, value);
    setFormState(newState);
  };

  useEffect(() => {
    if (props.asset) {
      setFormState({
        name: props.asset.name,
        assetNumber: props.asset.assetNumber,
      });
      setAsset(props.asset);
    }
  }, [props.asset]);

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Mine Site Name"
    );
    validationService.validateRequiredField(
      newState,
      "assetNumber",
      "assetNumberError",
      "Mine Site ID"
    );

    isFormValid = !validationService.hasError(
      newState,
      "nameError",
      "assetNumberError"
    );

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleSave = () => {
    if (!validateDataForSave()) {
      return;
    }
    if (isExistingAsset) {
      updateMshaAsset(asset.id, {
        name: formState.name,
        assetNumber: formState.assetNumber,
      })
        .then((res) => {
          alertify.success("Mine Site Updated.");
          props.handleClose(true);
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    } else {
      createMshaAsset({
        name: formState.name,
        assetNumber: formState.assetNumber,
        organizationId: props.organizationId,
      })
        .then((res) => {
          alertify.success("Mine Site Added.");
          props.handleClose(true);
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    }
  };
  return (
    <Grid>
      <Grid.Row>
        <Grid.Col md={6} width={12}>
          <Form.Group label="Mine Site Name" isRequired>
            <Form.Input
              type="text"
              onChange={handleInputChange}
              name={`name`}
              className="mine-site-name-input"
              value={formState.name}
              error={formState.nameError}
            ></Form.Input>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={6} width={12}>
          <Form.Group label="Mine Site ID" isRequired>
            <Form.Input
              type="text"
              onChange={handleInputChange}
              name={`assetNumber`}
              value={formState.assetNumber}
              error={formState.assetNumberError}
            ></Form.Input>
          </Form.Group>
        </Grid.Col>

        <Grid.Col md={12} width={12}>
          <Divider className="mb-2"></Divider>
          {isExistingAsset && (
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                setShowDeleteDialog(true);
              }}
            >
              Delete Mine Site
            </Button>
          )}

          <Button
            className="float-right"
            variant="contained"
            onClick={handleSave}
          >
            {isExistingAsset ? "Save" : "Add"}
          </Button>
          <Button
            onClick={() => {
              props.handleClose(false);
            }}
            className="float-right mr-2"
            variant="contained"
          >
            Cancel
          </Button>
        </Grid.Col>
      </Grid.Row>
      {showDeleteDialog && (
        <LynxDialog
          open={showDeleteDialog}
          title={`Delete Mine Site?`}
          description={
            "All associated program forms and inspection forms will be deleted. This action cannot be undone."
          }
          handleClose={() => {
            setShowDeleteDialog(false);
          }}
          handleDelete={() => {
            deleteMshaAsset(asset.id).then((res) => {
              alertify.success("Mine Site Deleted.");
              props.handleClose(true);
            });
          }}
        />
      )}
    </Grid>
  );
}
const getStatusChip = (status) => {
  if (!status) return;
  if (_.toLower(status) == "inactive") {
    <Chip label="Inactive" color="error" />;
    return;
  }
  if (_.toLower(status) == "pending registration") {
    return (
      <Chip
        label={status}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }

  return <Chip label="Active" color="success" />;
};
