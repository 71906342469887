import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import React from "react";
import _ from "lodash";
import { SearchableSelect } from "../../form-controls/searchable-select";
export function Step5(props) {
  const handleDropdownChange = (e, i, fieldName) => {
    let translations = [...props.translationsObj];
    let transIndex = translations.findIndex((x) => x.fieldName == fieldName);
    if (transIndex >= 0) {
      translations[transIndex].translationValues[i].toValue = e ? e : "";
    }
    props.setTranslationsObj(translations);
  };
  return (
    <div>
      <Typography className="mb-2">
        {_.isEmpty(props.translationsObj)
          ? "No translations are required for this import configuration. You can continue to the next step."
          : "The following fields require translations to be defined for the imported values. Expand each section below and assign a value, or leave blank to ignore."}
      </Typography>
      {props.translationsObj.map((trans, i) => (
        <Accordion key={i}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>{trans.fieldLabel}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width={"50%"}>Import Value</TableCell>
                    <TableCell>Lynx Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trans.fromValues.map((value, i) => (
                    <TableRow
                      key={i}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>{value}</TableCell>
                      <TableCell>
                        <SearchableSelect
                          name={`lynxField_${i}`}
                          key={`lynxField_${i}`}
                          options={[...trans.toValues]}
                          value={
                            trans.translationValues.find(
                              (x) => x.fromValue == value
                            ).toValue
                          }
                          placeholder={"Search or choose from list"}
                          labelFunction={(value) => {
                            return value;
                          }}
                          onChange={(e) => {
                            handleDropdownChange(e, i, trans.fieldName);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      ))}
      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={props.handleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            Continue
          </Button>
          <Button onClick={props.handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
        </div>
      </Box>
    </div>
  );
}
