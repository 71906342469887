import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";

import { LynxDataGrid } from "../data-grid/LynxDataGrid";
import { submissionColumns } from "./submission-columns";
import { getSubmissions } from "../../services/portal-submission-service";

export function SubmissionsList(props) {
  const [columns, setColumns] = useState([]);
  const handleSubmissionNumberClick = (row) => {
    props.history.push(`/portal-submissions/${row.id}`);
  };

  useEffect(() => {
    let newColumns = [...submissionColumns];
    let colIndex = newColumns.findIndex((x) => x.field == "submissionNumber");
    newColumns[colIndex].renderCell = (params) => {
      return (
        <Button
          variant="text"
          onClick={() => handleSubmissionNumberClick(params.row)}
        >
          {params.value}
        </Button>
      );
    };
    setColumns(newColumns);
  }, [submissionColumns]);

  return (
    <>
      <LynxDataGrid
        columns={columns}
        getDataFunction={getSubmissions}
        localStorageName="portal-submission"
        entityName="PortalSubmission"
        hasNoLookups
        title={"Portal Submissions"}
        {...props}
      />
    </>
  );
}
