import lynxColors from "../modules/lynxColors";
import { wylooColors } from "components/microsites/wyloo/wyloo-theme";
const colors = [
  lynxColors.harvestOrange,
  "#8884d8",
  "#82ca9d",
  lynxColors.sapphireBlue,
  "#56BC8A",
];

const wylooColor = [
  wylooColors.orange,
  wylooColors.ocean,
  wylooColors.black,
  wylooColors.eucalyptus,
];
class ChartService {
  getChartColorByIndex(index) {
    if (index < colors.length) return colors[index];
    return colors[index % colors.length];
  }
  getChartColorByIndexWyloo(index) {
    if (index < wylooColor.length) return wylooColor[index];
    return wylooColor[index % wylooColor.length];
  }
}

export const chartService = new ChartService();
