import { apiUrl, client } from "../modules/store";

export function getPermits(params) {
  return client.get(apiUrl + "/permits", {
    params: params,
  });
}

export function getPermitById(id) {
  return client.get(apiUrl + "/permits/" + id);
}

export function createPermit(formData) {
    return client.post(
        `${apiUrl}/permits`,
        formData
    );
}

export function patchPermit(id, jPatch) {
    var url = `${apiUrl}/permits/${id}`;
    return client.patch(url, jPatch);
}


export function deletePermit(id) {
  return client.delete(apiUrl + "/permits/" + id);
}

export function getPermitExportAsXLSX(params, columnDefinitionDto) {
  var url = `${apiUrl}/permits/export`;

  return client
    .post(url, columnDefinitionDto, {
      responseType: "blob",
      params: params,
    })
    .then((res) => res);
}


