import GroupIcon from "@mui/icons-material/Group";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Dimmer } from "tabler-react";
import React, { useEffect, useState } from "react";
import { getContact, getContactGroup } from "../../../services/contacts";
import { EventContactTypes } from "../../../types/enums";
export function ContactQuickCard(props) {
  const [contact, setContact] = useState({});
  const [contactGroup, setContactGroup] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  useEffect(() => {
    if (props.type == EventContactTypes.Contact) {
      getContact(props.id)
        .then((res) => {
          setContact({ name: res.data.fullName, ...res.data });
          setIsLoading(false);
        }, [])
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 404) {
              setIsLoading(false);
              setShowError(true);
            }
          }
        });
    }
    if (props.type == EventContactTypes.ContactGroup) {
      getContactGroup(props.id)
        .then((res) => {
          setContactGroup(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status == 404) {
              setIsLoading(false);
              setShowError(true);
            }
          }
        });
    }
  }, [props.id, props.type]);
  if (showError) {
    return (
      <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
        <CardContent>
          <div style={{ maxWidth: 400, minWidth: 200 }}>
            <Dimmer active={isLoading} loader>
              <div>Contact has been deleted.</div>
            </Dimmer>
          </div>
        </CardContent>
      </Card>
    );
  }
  if (props.type == EventContactTypes.Contact) {
    return (
      <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
        <CardContent>
          <div style={{ maxWidth: 400, minWidth: 200 }}>
            <Dimmer active={isLoading} loader>
              <div>
                {contact.contactGroupName && (
                  <Typography className=" d-flex">
                    <b>{contact.name}</b>
                    <GroupIcon
                      style={{ color: "rgba(0, 0, 0, 0.26)" }}
                      size="small"
                      className="mr-1 ml-2 "
                    />
                    {contact.contactGroupName}
                  </Typography>
                )}
              </div>
              {contact.title && (
                <Typography variant="body2" className="mr-1">
                  {contact.title}
                </Typography>
              )}

              {contact.phone && (
                <Typography variant="body2" className="mr-1">
                  <a href={`sms:${contact.phone}`}>{contact.phone}</a>
                </Typography>
              )}
              {contact.email && (
                <Typography variant="body2" className="mr-1">
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </Typography>
              )}
              {contact.notes && (
                <Typography variant="body2" className="mr-1">
                  {contact.notes}
                </Typography>
              )}
            </Dimmer>
          </div>
        </CardContent>
      </Card>
    );
  } else {
    return (
      <Card variant="outlined" sx={{ width: "100%" }} className="lynx-card">
        <CardContent>
          <div style={{ maxWidth: 400, minWidth: 200 }}>
            <Dimmer active={isLoading} loader>
              <div>
                <Typography className=" d-flex">
                  <GroupIcon
                    style={{ color: "rgba(0, 0, 0, 0.87)" }}
                    size="small"
                    className="mr-1  "
                  />
                  <b>{contactGroup.name}</b>
                </Typography>
              </div>
              {contactGroup.contactGroupType && (
                <Typography variant="body2" className="mr-1">
                  {contactGroup.contactGroupType}
                </Typography>
              )}
              {contactGroup.description && (
                <Typography variant="body2" className="mr-1">
                  {contactGroup.description}
                </Typography>
              )}
            </Dimmer>
          </div>
        </CardContent>
      </Card>
    );
  }
}
