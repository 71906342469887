import { apiUrl, client } from "../modules/store";
export function getMonitoringLocations(params) {
  return client.get(apiUrl + "/monitoringLocations", {
    params: params,
  });
}

export function createMonitoringLocation(dto) {
  return client.post(apiUrl + "/monitoringLocations", {
    ...dto,
  });
}

export function deleteMonitoringLocation(id) {
  return client.delete(apiUrl + "/monitoringLocations/" + id);
}

export function patchMonitoringLocation(id, jPatch) {
  return client.patch(apiUrl + "/monitoringLocations/" + id, jPatch);
}

export function getMonitoringLocation(id) {
  return client.get(apiUrl + "/monitoringLocations/" + id);
}

export function getMonitoringLocationsForMap(params) {
  return client.get(apiUrl + "/monitoringLocations/map", {
    params: params,
  });
}

// Public endpoints
export function getMonitoringLocationsForMicrositeMap(url) {
  return client.get(apiUrl + "/monitoringLocations/portal?url=" + url);
}
export function getDistinctParamtersPerPortalType(portalTypes, url) {
  return client.get(
    apiUrl +
      `/monitoringLocations/portal/parameters?portalTypes=${portalTypes.join(
        ","
      )}&url=${url}`
  );
}
export function getPortalStats(portalTypes, url, monitoringLocationId = null) {
  return client.get(
    apiUrl +
      `/monitoringLocations/portal/stats?portalTypes=${portalTypes.join(
        ","
      )}&url=${url}${
        monitoringLocationId
          ? "&monitoringLocationId=" + monitoringLocationId
          : ""
      }`
  );
}
export function getLocationsPerPortalType(portalTypes, url) {
  return client.get(
    apiUrl +
      `/monitoringLocations/portal/locations?portalTypes=${portalTypes.join(
        ","
      )}&url=${url}`
  );
}
// Private endpoints
export function getMonitoringLocationForMicrositeMap(id) {
  return client.get(apiUrl + "/monitoringLocations/portal/" + id);
}

export function getDistinctParamtersPerMonitoringLocation(id) {
  return client.get(apiUrl + `/monitoringLocations/portal/${id}/parameters`);
}

export function getMonitoringLocationExportAsXLSX(params, columnDefinitionDto) {
  var url = `${apiUrl}/monitoringLocations/export`;

  return client
    .post(url, columnDefinitionDto, {
      responseType: "blob",
      params: params,
    })
    .then((res) => res);
}
