import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import configureAppStore from "./modules/store";
import "alertifyjs/build/css/alertify.min.css";
import "alertifyjs/build/css/themes/bootstrap.min.css";
import ReactGA from "react-ga4";
import "./styles/components/Dashboard.css";
import "./styles/print.css";
import "./fonts.css";
import "./index.css";

import { theme } from "./styles/mui-theme";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { useHistory } from "react-router-dom";
import { OnlineStatusProvider } from "./hooks/useOnlineStatus";
import AppRoute from "./app-route";
import Layout from "./components/layout";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import CacheBuster from "react-cache-buster";
import object from "../package.json";
import { createRoot } from "react-dom/client";

ReactGA.initialize("G-BZ2L5XJWK0");

export const store = configureAppStore();
LicenseInfo.setLicenseKey(
  "21167caa351901ed8731da6769476427Tz05NTg5MyxFPTE3NTQ4MzY4MzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPWluaXRpYWwsS1Y9Mg=="
);
TimeAgo.addDefaultLocale(en);
const container = document.getElementById("root");
const root = createRoot(container);

const AppRedux = (props) => {
  const isProduction = process.env.NODE_ENV === "production";
  return (
    <CacheBuster
      currentVersion={object.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      metaFileDirectory={"."}
    >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router history={useHistory()}>
            <OnlineStatusProvider>
              <Layout>
                <AppRoute />
              </Layout>
            </OnlineStatusProvider>
          </Router>
        </ThemeProvider>
      </Provider>
    </CacheBuster>
  );
};

root.render(<AppRedux />);
