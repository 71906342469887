import { apiUrl, client } from "../modules/store";
const requiredSize = 1024 * 1024 * 50;
export function addEntityAttachments(entityType, entityId, attachments) {
  let form = new FormData();

  for (var index = 0; index < attachments.length; index++) {
    var element = attachments[index];
    form.append("file", element);
  }

  return client.post(
    apiUrl +
      `/attachments/upload?entityType=${entityType}&entityId=${entityId}`,
    form
  );
}

export function getEntityAttachments(entityType, entityId) {
  return client.get(
    apiUrl + `/attachments?entityType=${entityType}&entityId=${entityId}`
  );
}

export function deleteEntityAttachment(id) {
  return client.delete(apiUrl + `/attachments/${id}`);
}

export function downloadAttachment(id) {
  return client
    .get(apiUrl + `/attachments/${id}/download`, {
      responseType: "blob",
    })
    .then((res) => res);
}

export function downloadThumbnail(id) {
  return client
    .get(apiUrl + `/attachments/${id}/thumbnail`, {
      responseType: "blob",
    })
    .then((res) => res);
}

export function validateFileSize(fileArray) {
  //convert 2mb to bytes

  var totalSize = fileArray
    .map((a) => a.size)
    .reduce((partialSum, a) => partialSum + a, 0);
  if (totalSize > requiredSize) {
    return false;
  }
  return true;
}

export function updateAttachmentDesciption(id, dto) {
  return client.put(`/attachments/${id}`, dto);
}
