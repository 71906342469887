import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import axios from "axios";

import axiosMiddleware from "redux-axios-middleware";
import rootReducer from "../reducers";
import { LocalStorageKeys } from "../types/enums";
import { configureStore } from "@reduxjs/toolkit";
import { apiService } from "services/rtkApi/apiService";
import auth from "reducers/auth";
import account from "reducers/account";
import organization from "reducers/organization";
import portal from "reducers/portal";

//update this to be devUrl, betaUrl, prodUrl or demoUrl depending on backend environment
export const mainUrl =
  process.env.REACT_APP_API_URL ?? "https://api.lynx-esg.com/";

export const apiUrl = mainUrl + "api";

export const client = axios.create({
  baseURL: apiUrl,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.request.use((config) => {
  const token = localStorage.getItem(LocalStorageKeys.AccessToken);
  config.headers["Authorization"] = "Bearer " + token;
  return config;
});

export default function configureAppStore(initialState) {
  // const composeEnhancers =
  //   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  // return createStore(
  //   rootReducer(),
  //   initialState,
  //   composeEnhancers(applyMiddleware(thunk, axiosMiddleware(client)))
  // );

  return configureStore({
    reducer: {
      auth,
      account,
      organization,
      portal,
      [apiService.reducerPath]: apiService.reducer,
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware()
        .concat(thunk, axiosMiddleware(client))
        .concat(apiService.middleware);
    },
  });
}
