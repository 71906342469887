import Backdrop from "@mui/material/Backdrop";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { DataGridPro } from "@mui/x-data-grid-pro";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, Grid } from "tabler-react";
import { roleMatch } from "../../../../actions/auth";
import { getLookups } from "../../../../services/lookup";
import { LookupTypes, UserRoles } from "../../../../types/enums";
import { DeleteConfirmModal } from "../DeleteConfirmModal";
import { SettingsLookUpEditModal } from "../SettingsLookUpEditModal";
import { AddLookupHeader } from "../add-lookup-header";

import { tagCategoryColumns, tagColumns, tagGroupColumns } from "./tag-columns";
import { TagGroupModal } from "./tag-group-modal";
import { TagModal } from "./tag-modal";

const initialLookupData1 = {
  id: "",
  lookupType: "",
  code: "",
};

export function TagSettings(props) {
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [tags, setTags] = useState([]);
  const [tagGroups, setTagGroups] = useState([]);
  const [tagCategories, setTagCategories] = useState([]);
  const [selectedDataId, setSelectedDataId] = useState(0);
  const [selectedLookupData, setSelectedLookupData] =
    useState(initialLookupData1);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  React.useEffect(() => {
    getTags();
    getTagGroups();
    getTagCategories();
  }, []);

  const getTagCategories = () => {
    getLookups(LookupTypes.TagCategory).then((res) => {
      setTagCategories(res.data);
    });
  };

  const setLookupDataFromProps = (LookupItem) => {
    let newState = {
      id: LookupItem.id,
      lookupType: LookupItem.lookupType,
      code: LookupItem.code,
    };
    setSelectedLookupData(newState);
  };

  const getTags = async () => {
    setLoading(true);
    const data = await getLookups(LookupTypes.Tag);
    setTags(data.data);
    setLoading(false);
  };

  const getTagGroups = async () => {
    setLoading(true);
    const data = await getLookups(LookupTypes.TagGroup);
    setTagGroups(data.data);
    setLoading(false);
  };

  const handleClose = (refreshData) => {
    setDeleteOpen(false);
    setEditOpen(false);
    setSelectedDataId(0);
    if (refreshData) {
      switch (selectedLookupData.lookupType) {
        case LookupTypes.Tag:
          getTags();
          getTagGroups();
          break;
        case LookupTypes.TagGroup:
          getTags();
          getTagGroups();
          break;
        case LookupTypes.TagCategory:
          getTags();
          getTagCategories();
          break;
      }
    }
    setSelectedLookupData({});
  };

  const refreshTags = (refreshData) => {
    if (refreshData) {
      getTags();
    }
  };
  const refreshTagGroups = (refreshData) => {
    if (refreshData) {
      getTagGroups();
    }
  };
  const handleRowSelected = (e) => {
    setSelectedDataId(e.id);
  };

  const handleEditDelete = (e) => {
    if (e.field == "delete" && roleMatch([UserRoles.Admin])) {
      setSelectedDataId(e.row.id);
      setLookupDataFromProps(e.row);
      setDeleteOpen(true);
    }

    if (e.field == "edit") {
      setSelectedDataId(e.row.id);
      setLookupDataFromProps(e.row);
      setEditOpen(true);
    }
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Tag Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">Tag Settings</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>

      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Tags
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Compliance code represents both the Compliance Framework and the
              individual line item code number f (e.g., GRI 305-1). Use the
              Category field to associate a Category to a Framework (e.g., GRI
              305-1 pertains to Scope 1 Emissions, for example)
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <TagModal
              handleAddTag={refreshTags}
              tagGroups={tagGroups}
              tagCategories={tagCategories}
            ></TagModal>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={tags}
                columns={tagColumns}
                disableMultipleSelection={true}
                onCellClick={handleEditDelete}
                onRowClick={handleRowSelected}
                loading={loading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Tag Groups
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Compliance frameworks range from internal company frameworks to
              international sustainability performance standards (e.g., RGMP -
              Responsible Gold Mining Principles, ICMM - International Council
              on Mining and Metals)
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <TagGroupModal handleAddTagGroup={refreshTagGroups}></TagGroupModal>
            <div style={{ height: 630, width: "100%" }}>
              {" "}
              <DataGridPro
                rows={tagGroups}
                columns={tagGroupColumns}
                disableMultipleSelection={true}
                onCellClick={handleEditDelete}
                onRowClick={handleRowSelected}
                loading={loading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Tag Categories
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Compliance categories provide a way to group your compliance codes
              for reporting purposes (e.g. Water, Social, Health and Safety,
              etc). The compliance category is optional.
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Category"
              descriptionLabel="Description"
              lookupType={LookupTypes.TagCategory}
              lookupName="Tag Category"
              handleAddLookup={() => {
                getTagCategories();
              }}
            ></AddLookupHeader>
            <div style={{ height: 630, width: "100%" }}>
              {" "}
              <DataGridPro
                rows={tagCategories}
                columns={tagCategoryColumns}
                disableMultipleSelection={true}
                onCellClick={handleEditDelete}
                onRowClick={handleRowSelected}
                loading={loading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={deleteOpen}
        onClose={handleClose}
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DeleteConfirmModal
          handleModalClose={handleClose}
          lookupId={selectedDataId}
          lookupItem={selectedLookupData}
        ></DeleteConfirmModal>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editOpen}
        onClose={handleClose}
        disableBackdropClick
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <SettingsLookUpEditModal
          handleModalClose={handleClose}
          lookupId={selectedDataId}
          lookupItem={selectedLookupData}
          children={tagCategories}
          parents={tagGroups}
        ></SettingsLookUpEditModal>
      </Modal>
    </Grid>
  );
}
