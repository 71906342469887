import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import { dateUtil } from "../../../services/date-util";
import { getComplianceColor } from "../map/map-sidebar";
import useOnlineStatus from "./../../../hooks/useOnlineStatus";
export function MonitoringEventMobileCard(props) {
  const event = props.entity;
  const history = useHistory();
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };
  const isOffline = !useOnlineStatus();
  return (
    <Card variant="outlined" className="w-100">
      <CardContent className="pb-0 pt-1">
        <Typography variant="h6">
          {!isOffline ? (
            <Link
              href="#"
              underline="none"
              onClick={(e) => handleNavigateTo(e, `monitoring/${event.id}`)}
            >
              {event.monitoringEventNumber}
            </Link>
          ) : (
            event.monitoringEventNumber
          )}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          Location: {event.monitoringLocationName} ({event.assetName})
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {event.endDateTimeUtc
            ? "End Date: " +
              dateUtil.convertDateTimeToLocal(event.endDateTimeUtc)
            : "Due: " + dateUtil.convertDateOnlyToLocal(event.dueDateTimeUtc)}
        </Typography>
        <Typography variant="h6">{event.eventTypeName}</Typography>
        {event.eventSubCategory && (
          <Typography color="text.secondary">
            {event.eventSubCategory}
          </Typography>
        )}
        <Typography sx={{ mt: 1 }} variant="body2">
          Sample ID: {event.name}
        </Typography>
        <Typography variant="body2">
          Description: {event.description || "-"}
        </Typography>
        <Typography variant="body2">
          # of Results: {event.numberOfResults}
        </Typography>
        <Typography variant="body2" className="mt-1 mb-1">
          <i
            className={"fe fe-circle "}
            style={{ color: getComplianceColor(event.isNonCompliant) }}
          />
          {event.isNonCompliant == true ? " Noncompliant" : " Compliant"}
        </Typography>

        <Typography variant="body2" sx={{ mb: 1 }}>
          Assigned To: {event.assignedToUserFullName || "-"}
        </Typography>
      </CardContent>
    </Card>
  );
}
