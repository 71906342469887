import decode from "jwt-decode";
import { apiUrl } from "../modules/store";
import { store } from "./../index";
import { EntityPermissions, LocalStorageKeys } from "../types/enums";
//API calls should be in actions and not reducers. Reducer is only for chagning state.
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

const requestLogin = (creds) => {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  };
};

const receiveLogin = (user) => {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    id_token: user.id_token,
  };
};

const loginError = (message) => {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message,
  };
};

export const loginUser = (creds) => {
  let config = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(creds),
  };

  return (dispatch) => {
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(loginError(null));
    dispatch(requestLogin(creds));

    return fetch(apiUrl + "/auth/login", config)
      .then((response) => response.json().then((user) => ({ user, response })))
      .then(({ user, response }) => {
        if (!response.ok) {
          dispatch(loginError(user.message));
          return Promise.reject(user);
        } else {
          // If login was successful, set the token in local storage

          localStorage.setItem(LocalStorageKeys.AccessToken, user.token);
          // Dispatch the success action
          dispatch(receiveLogin(user));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

// Logs the user out
export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    localStorage.removeItem(LocalStorageKeys.AccessToken);
    dispatch(receiveLogout());
  };
}

export function getName() {
  var decodedToken = decode(getToken());
  return decodedToken.name;
}

function getToken() {
  // Retrieves the user token from localStorage
  return localStorage.getItem(LocalStorageKeys.AccessToken);
}

export function loggedIn() {
  // Checks if there is a saved token and it's still valid
  const token = getToken(); // GEtting token from localstorage
  return !!token && !isTokenExpired(token); // handwaiving here
}

export function getUserId() {
  var decodedToken = decode(getToken());
  return decodedToken.nameid;
}

function isTokenExpired(token, isOfflineToken = false) {
  try {
    const decoded = decode(token);
    //subtract 30 days from ceiling date if offline mode
    var ceilingDate = !isOfflineToken
      ? Date.now() / 1000
      : Date.now() - (86400 * 1000) / 1000;
    if (decoded.exp < ceilingDate) {
      // Checking if token is expired. N
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
}

export function roleMatch(allowedRoles) {
  let isRoleMatch = false;
  let userRoles = decode(getToken()).role;
  if (userRoles === undefined) {
    userRoles = ["none"];
  }
  allowedRoles.forEach((element) => {
    if (userRoles.includes(element)) {
      isRoleMatch = true;
    }
  });
  return isRoleMatch;
}

export function hasLookupPermissions(lookupId, permission, userLookups) {
  //if userLookups are not passed in, will use current user
  //if permissions are not passed in, will check total access
  let permissions = [];
  if (!userLookups) {
    const state = store.getState();
    permissions = state.account.userLookups;
  } else {
    permissions = userLookups;
  }

  if (!permissions) {
    return false;
  }

  const lookup = permissions.find((x) => x.lookupId == lookupId);

  if (!lookup) {
    return false;
  }

  if (!permission) {
    return true;
  }

  if (permission == EntityPermissions.CanApprove) {
    return lookup.canApprove;
  }
  if (permission == EntityPermissions.CanVerify) {
    return lookup.canVerify;
  }
  if (permission == EntityPermissions.CanPerformInitialReview) {
    return lookup.canPerformInitialReview;
  }
  if (permission == EntityPermissions.CanPerformFinalReview) {
    return lookup.canPerformFinalReview;
  }

  return false;
}
export function hasAssetPermission(assetId, userAssets) {
  //if userLookups are not passed in, will use current user
  //if permissions are not passed in, will check total access
  let permissions = [];
  if (!userAssets) {
    const state = store.getState();
    permissions = state.account.userAssets;
  } else {
    permissions = userAssets;
  }

  if (!permissions) {
    return false;
  }

  const asset = permissions.find((x) => x.assetId == assetId);

  if (!asset) {
    return false;
  } else {
    return true;
  }

  return false;
}
