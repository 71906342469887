import React, { useEffect, useState } from "react";
import { Button, Card, Dimmer, Form, Grid, Icon } from "tabler-react";
import alertify from "alertifyjs";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router-dom";
import {
  addOrganization,
  getOrganization,
  updateOrganization,
} from "./../../services/admin";
import { validationService } from "./../../services/validation";
import { AdminUserModal } from "./../dashboards/settings/admin-user-modal";
import { LynxDataGrid } from "../data-grid/LynxDataGrid";
import { dateUtil } from "../../services/date-util";
const initialForm = {
  name: "",
  type: "miningOperator",
  nameError: "",
  typeError: "",
  userCount: 0,
  incidentsLicenseCount: 0,
  eventsLicenseCount: 0,
  monitoringLicenseCount: 0,
  inspectionsLicenseCount: 0,
  energyLogsLicenseCount: 0,
  timeSeriesLicenseCount: 0,
  licenseExpirationDateTimeUtc: "",
  hasPhotoAnalysis: false,
  hasPermitFeature: false,
};

const columns = [
  {
    field: "email",
    headerName: "Email",
    width: 200,
    type: "string",
  },
  {
    field: "firstName",
    headerName: "First name",
    width: 100,
    type: "string",
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 100,
    type: "string",
  },
  {
    field: "isActive",
    headerName: "Is Active?",
    width: 100,
    type: "boolean",
  },
  {
    field: "isAdmin",
    headerName: "Is Admin?",
    width: 100,
    type: "boolean",
  },
  {
    field: "isInternal",
    headerName: "Is Internal?",
    width: 100,
    type: "boolean",
  },
  {
    field: "registrationCompletedDateTimeUtc",
    headerName: "Registered Date",
    width: 150,
    type: "dateTime",
    valueGetter: (params) => {
      return dateUtil.convertDateTimeToLocal(params.value);
    },
  },
];

export function OrganizationModal(props) {
  const [formState, setFormState] = useState(initialForm);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [hasExpiration, setHasExpiration] = useState(false);
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value =
      e.target.type == "checkbox" ? e.target.checked : e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };
  const location = useLocation();

  useEffect(() => {
    if (props.entityId > 0) {
      getOrganization(props.entityId).then((res) => {
        setFormStateFromProps(res.data);
      });
    } else {
      setIsLoading(false);
    }
  }, [props.entityId]);

  const handleRefresh = () => {
    setIsLoading(true);
    getOrganization(props.entityId).then((res) => {
      setFormStateFromProps(res.data);
    });
  };

  const setFormStateFromProps = (data) => {
    var expiredDate = data.licenseExpirationDateTimeUtc
      ? moment(data.licenseExpirationDateTimeUtc).format("YYYY-MM-DD HH:mm")
      : "";
    if (expiredDate != "") {
      setHasExpiration(true);
    }
    let newState = {
      name: data.name,
      userCount: data.userCount,
      type: data.type,
      monitoringLicenseCount: data.monitoringLicenseCount,
      eventsLicenseCount: data.eventsLicenseCount,
      incidentsLicenseCount: data.incidentsLicenseCount,
      inspectionsLicenseCount: data.inspectionsLicenseCount,
      energyLogsLicenseCount: data.energyLogsLicenseCount,
      timeSeriesLicenseCount: data.timeSeriesLicenseCount,
      licenseExpirationDateTimeUtc: expiredDate,
      hasPhotoAnalysis: data.hasPhotoAnalysis,
      hasPermitFeature: data.hasPermitFeature,
    };
    setFormState(newState);
    setUsers(data.users);
    setIsLoading(false);
  };

  const handleSaveOrg = () => {
    if (!validateDataForSave(formState)) {
      return;
    }
    let formToSave = validationService.unsetErrors(
      formState,
      "nameError",
      "typeError",
      "userCountError",
      "incidentsLicenseCountError",
      "monitoringLicenseCountError",
      "eventsLicenseCountError"
    );

    formToSave.licenseExpirationDateTimeUtc =
      formToSave.licenseExpirationDateTimeUtc == ""
        ? ""
        : moment(formToSave.licenseExpirationDateTimeUtc).utc().format();

    if (props.entityId > 0) {
      updateOrganization(props.entityId, formToSave)
        .then((res) => {
          alertify.success("Organization Updated");
          props.handleModalClose(true);
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    } else {
      addOrganization(formToSave)
        .then((res) => {
          alertify.success("Organization Added");
          props.handleModalClose(true);
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );
    validationService.validateRequiredField(
      newState,
      "type",
      "typeError",
      "Type"
    );
    validationService.validateNumberField(
      newState,
      "userCount",
      "userCountError",
      true,
      "Total User Licenses"
    );

    validationService.validateNumberField(
      newState,
      "incidentsLicenseCount",
      "incidentsLicenseCountError",
      true,
      "Incidents License Count"
    );
    validationService.validateNumberField(
      newState,
      "monitoringLicenseCount",
      "monitoringLicenseCountError",
      true,
      "Monitoring License Count"
    );
    validationService.validateNumberField(
      newState,
      "eventsLicenseCount",
      "eventsLicenseCountError",
      true,
      "Events License Count"
    );
    validationService.validateNumberField(
      newState,
      "inspectionsLicenseCount",
      "inspectionsLicenseCountError",
      true,
      "Inspections License Count"
    );
    validationService.validateNumberField(
      newState,
      "energyLogsLicenseCount",
      "energyLogsLicenseCountError",
      true,
      "Energy Logs License Count"
    );
    validationService.validateNumberField(
      newState,
      "timeSeriesLicenseCount",
      "timeSeriesLicenseCountError",
      true,
      "Time Series License Count"
    );
    isFormValid = !validationService.hasError(
      newState,
      "nameError",
      "typeError",
      "userCountError",
      "incidentsLicenseCountError",
      "monitoringLicenseCountError",
      "eventsLicenseCountError",
      "timeSeriesLicenseCountError"
    );

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  return (
    <Form className="card dsmodal-main w-50">
      <Card.Body>
        <Card.Title>
          {props.entityId > 0 ? "Edit Organization" : "Add Organization"}

          <Icon
            name="x"
            onClick={props.handleModalClose}
            className="float-right pointer"
          ></Icon>
        </Card.Title>
        <Dimmer active={isLoading} loader>
          <Grid.Row>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Name" isRequired>
                <Form.Input
                  name="name"
                  onChange={handleInputChange}
                  value={formState.name}
                  error={formState.nameError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Organization Type" isRequired>
                <Form.Select
                  name="type"
                  onChange={handleInputChange}
                  value={formState.type}
                  error={formState.typeError}
                  // error={formState.urgencyError}
                >
                  <option value={"miningOperator"}>Mining Operator</option>
                  <option value={"miningFinance"}>Mining Finance</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Total User Licenses" isRequired>
                <Form.Input
                  type="number"
                  name={`userCount`}
                  onChange={handleInputChange}
                  value={formState.userCount}
                  error={formState.userCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Incidents License Count" isRequired>
                <Form.Input
                  type="number"
                  name={`incidentsLicenseCount`}
                  onChange={handleInputChange}
                  value={formState.incidentsLicenseCount}
                  error={formState.incidentsLicenseCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Events License Count" isRequired>
                <Form.Input
                  type="number"
                  name={`eventsLicenseCount`}
                  onChange={handleInputChange}
                  value={formState.eventsLicenseCount}
                  error={formState.eventsLicenseCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Monitoring License Count" isRequired>
                <Form.Input
                  type="number"
                  name={`monitoringLicenseCount`}
                  onChange={handleInputChange}
                  value={formState.monitoringLicenseCount}
                  error={formState.monitoringLicenseCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Inspections License Count" isRequired>
                <Form.Input
                  type="number"
                  name={`inspectionsLicenseCount`}
                  onChange={handleInputChange}
                  value={formState.inspectionsLicenseCount}
                  error={formState.inspectionsLicenseCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Energy Logs License Count" isRequired>
                <Form.Input
                  type="number"
                  name={`energyLogsLicenseCount`}
                  onChange={handleInputChange}
                  value={formState.energyLogsLicenseCount}
                  error={formState.energyLogsLicenseCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Time Series License Count" isRequired>
                <Form.Input
                  type="number"
                  name={`timeSeriesLicenseCount`}
                  onChange={handleInputChange}
                  value={formState.timeSeriesLicenseCount}
                  error={formState.timeSeriesLicenseCountError}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="">
                <Form.Checkbox
                  label="Has Photo Analysis?"
                  name={`hasPhotoAnalysis`}
                  checked={formState.hasPhotoAnalysis}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="">
                <Form.Checkbox
                  label="Has Permit Feature?"
                  name={`hasPermitFeature`}
                  checked={formState.hasPermitFeature}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="">
                <Form.Checkbox
                  label="Set expiration date?"
                  name="hasExpiration"
                  checked={hasExpiration}
                  onChange={(e) => {
                    setHasExpiration(e.target.checked);
                    if (e.target.checked == false) {
                      setFormState({
                        ...formState,
                        licenseExpirationDateTimeUtc: "",
                      });
                    }
                  }}
                />
              </Form.Group>
            </Grid.Col>
            {hasExpiration && (
              <Grid.Col md={6} width={12}>
                <Form.Group label="Expiration Date">
                  <Form.Input
                    type="datetime-local"
                    value={formState.licenseExpirationDateTimeUtc}
                    name="licenseExpirationDateTimeUtc"
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            {!isLoading && props.entityId > 0 && (
              <Grid.Col md={12} width={12}>
                <div style={{ height: "500px" }}>
                  <LynxDataGrid
                    data={users}
                    columns={columns}
                    {...props}
                    location={location}
                    entityName={"User"}
                    localStorageName="admin_users"
                    hasEditButton
                    entityModal={AdminUserModal}
                    passEntityOnClick
                    hasNoLookups
                    handleRefresh={handleRefresh}
                    entityModalProps={{ organizationId: props.entityId }}
                  />
                </div>
              </Grid.Col>
            )}
          </Grid.Row>
        </Dimmer>
      </Card.Body>
      <Card.Footer>
        {props.entityId <= 0 && (
          <Button
            color="primary"
            type="button"
            className="float-right"
            onClick={handleSaveOrg}
          >
            Add Organization
          </Button>
        )}
        {props.entityId > 0 && (
          <div>
            <Button
              color="danger"
              className="float-left"
              type="button"
              disabled
            >
              Lock Organization
            </Button>

            <Button
              color="primary"
              className="float-right"
              type="button"
              onClick={handleSaveOrg}
            >
              Save Organization
            </Button>
          </div>
        )}
      </Card.Footer>
    </Form>
  );
}
