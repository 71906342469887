import { apiUrl, client } from "../modules/store";

export function getImportSpecifications(params) {
  return client.get(apiUrl + `/imports/specifications`, {
    params: params,
  });
}

export function getImportHistory(params) {
  return client.get(apiUrl + "/imports/history", {
    params: params,
  });
}

export function uploadAndPrepImport(attachment, entityType, specId) {
  let form = new FormData();
  form.append("file", attachment);
  form.append("entityType", entityType);
  form.append("importSpecificationId", specId ?? 0);
  return client.post(apiUrl + `/imports/upload`, form);
}

export function uploadCorrectedImport(
  attachment,
  entityType,
  specId,
  importHistoryId
) {
  let form = new FormData();
  form.append("file", attachment);
  form.append("entityType", entityType);
  form.append("importHistoryId", importHistoryId);
  form.append("importSpecificationId", specId ?? 0);
  return client.post(apiUrl + `/imports/corrected`, form);
}

export function createMappings(dto) {
  return client.post(apiUrl + `/imports/mappings`, dto);
}

export function createTrasnlations(dto) {
  return client.post(apiUrl + `/imports/translations`, dto);
}

export function startImport(dto) {
  return client.post(apiUrl + `/imports`, dto);
}

export function proceedWithImport(historyId) {
  return client.post(apiUrl + `/imports/proceed/${historyId}`, {});
}

export function deleteDataByHistory(id) {
  return client.delete(apiUrl + `/imports/history/${id}`);
}

export function deleteSpecification(id) {
  return client.delete(apiUrl + `/imports/specifications/${id}`);
}
