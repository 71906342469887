import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { LineChartWidget } from "../dashboards/widgets/line-chart-widget";
export const prepareLastCalculatedValues = (obj, customFields) => {
  let valuesArray = [];
  if (!obj) {
    return valuesArray;
  }
  for (const [key, value] of Object.entries(JSON.parse(obj))) {
    if (key.includes("SUM(") || key.includes("AVG(")) {
      let fieldName = key.substring(4, key.indexOf(")"));
      fieldName = fieldName.replace("customFieldData.", "");

      const customField = customFields.find((x) => x.fieldName == fieldName);
      if (customField) {
        valuesArray.push([customField.fieldLabel, value, key]);
      }
    }
  }
  return valuesArray;
};
export function KpiDashboard(props) {
  const [lastCalculatedValues, setLastCalculatedValues] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(props.customFields) && !_.isEmpty(props.kpi)) {
      let array = prepareLastCalculatedValues(
        props.kpi.lastCalculatedValues,
        props.customFields
      );
      setLastCalculatedValues(array);
    }
  }, [props.customFields, props.kpi]);

  return (
    <>
      <div className="dashboard-grid-container-4col">
        <div className="dashboard-item">
          <Card>
            <CardContent>
              <Typography
                sx={{ fontSize: 14, fontWeight: "medium" }}
                color="text.primary"
              >
                Last Calculated Value
              </Typography>
              <Typography variant="h5" component="div">
                {props.kpi.lastCalculatedValue}
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.secondary">
                Last Calculated Date
              </Typography>
              <Typography color="text.secondary">
                {props.kpi.lastCalculatedDateTimeUtc
                  ? moment
                      .utc(props.kpi.lastCalculatedDateTimeUtc)
                      .local()
                      .format("YYYY-MM-DD HH:mm:ss")
                  : "None"}
              </Typography>
            </CardContent>
          </Card>
        </div>
        {props.kpi.lastCalculatedDateTimeUtc && (
          <div className="dashboard-item">
            <Card>
              <CardContent>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "medium" }}
                  color="text.primary"
                >
                  Calculation Values
                </Typography>
                {lastCalculatedValues.map((value) => (
                  <div>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: "medium" }}
                      color="text.primary"
                      variant="span"
                    >
                      {value[0]}:
                    </Typography>
                    &nbsp;
                    <Typography
                      sx={{ fontSize: 14, fontWeight: "medium" }}
                      color="text.secondary"
                      variant="span"
                    >
                      {value[1]}
                    </Typography>
                  </div>
                ))}
                <div>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: "medium" }}
                    color="text.primary"
                    variant="span"
                  >
                    Equation:
                  </Typography>
                  &nbsp;
                  <Typography
                    sx={{ fontSize: 14, fontWeight: "medium" }}
                    color="text.secondary"
                    variant="span"
                  >
                    {props.kpi.lastPopulatedEquation}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </div>
        )}
      </div>
      <div className="dashboard-grid-container-1col">
        <div className="dashboard-item">
          {!_.isEmpty(props.kpi) && (
            <LineChartWidget
              kpiId={props.kpi.id}
              title={props.kpi.name}
              {...props}
              hasDateFilter
            />
          )}
        </div>
      </div>
    </>
  );
}
