import React from "react";

import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { ProjectModal } from "./project-modal";
import { columns } from "./columns";
import { data } from "./columns";

export function ProjectList(props) {
  return (
    <>
      <LynxDataGrid
        columns={columns}
        data={data}
        entityModal={ProjectModal}
        entityName={"Project"}
        localStorageName="projects"
        {...props}
      />
    </>
  );
}
