import { Typography } from "@mui/material";

export const mainTourSteps = [
  {
    target: ".sidebar",
    placementBeacon: "top",
    placement: "right",
    disableBeacon: true,
    title: "Use the Left Sidebar to Navigate",
    content:
      "The left sidebar is the primary way to navigate the Lynx Platform.",
  },
  {
    target: ".sidebar-assets",
    placementBeacon: "right",
    placement: "right",
    title: "Assets are your Operational Areas",
    content:
      "Assets are operational areas that we’ll attach events, incidents, and other types of data to. Add your company or department’s asset and its location here. You’ll also see your asset(s) on the map.",
  },
  {
    target: ".sidebar-events",
    placementBeacon: "right",
    placement: "right",
    title:
      "Use Events to manage your organization’s activities around the asset ",
    content:
      "Events are used to track a variety of data, including community engagement, emissions, inspections and any other event based data. Track events as they happen in and around the asset area in real time. Events will be logged in the events grid, and appear on the map.",
  },
  {
    target: ".sidebar-incidents",
    placementBeacon: "right",
    placement: "right",
    title:
      "Accidents happen. Track them and other unexpected occurrences using Incidents.",
    content:
      "Keep track of incidents over time using a reviewal process, corrective/preventative actions and investigations to reduce risk to your organization and community.",
  },
  {
    target: ".sidebar-actions",
    placementBeacon: "right",
    placement: "right",
    title: "Assign corrective and preventative actions to your team",
    content:
      "Associate actions to events or incidents to correct a past occurence or prevent it from happening again. Built in approval and verification workflows and notifications ensure actions are prioritized and completed on time.",
  },
  {
    target: ".sidebar-monitoring",
    placementBeacon: "right",
    placement: "right",
    title: "Monitoring the Environment",
    content:
      "Monitor water quality, air quality, blast noise and more over time with Monitoring. Monitoring results are automatically compared to the configured monitoring limits to ensure compliance.",
  },
  {
    target: ".sidebar-dashboard",
    placementBeacon: "right",
    placement: "right",
    title: "Visualize All of Your Data with the Dashboard",
    content:
      "Data from your Events, Incidents and Monitoring can be visualized using widgets. Pick from predefined system widgets, or customize your own bar, donut, or count list chart on the dashboard.",
  },
  {
    target: ".sidebar-map",
    placementBeacon: "right",
    placement: "right",
    title: "The Map - a High-Level View of your Assets and Events",
    content:
      "Visualize your assets, events, monitoring and more using the map.",
  },
  {
    target: ".sidebar-settings",
    placementBeacon: "right",
    placement: "right",
    title: "Customize your Experience with Settings",
    content:
      "Settings contain all the options you’ll need for customizing the look, feel and functionality of the Lynx Platform.",
  },
  {
    target: ".help-menu",
    placementBeacon: "bottom",
    placement: "bottom",
    title: "Need More Help?",
    content:
      "That's it! If you need more help, click the question mark on the top right of the menu bar to read the user guide or contact support at support@lynxglobalintelligence.com. Thanks for using the Lynx Platform.",
  },
];

export const mainTourStepsMsha = [
  {
    target: ".sidebar-inspections",
    placementBeacon: "right",
    placement: "right",
    disableBeacon: true,
    title: "Perform and Track Daily Inspections",
    content: (
      <Typography>
        The Inspections tab is where you perform your daily examinations and
        inspection tasks.<br></br> Included by default:<br></br>
        <b>-Daily Examination of Working Places</b>
        <br></br>
        <b>-Surface Mobile Equipment Inspection</b>
        <br></br> Try clicking “Add Inspection” to add your first inspection.
        All past inspections can be viewed and filtered on the inspections grid.
      </Typography>
    ),
  },
  {
    target: ".sidebar-programs",
    placementBeacon: "right",
    placement: "right",
    title: "Safety Program for Surface Mobile Equipment",
    content: `The Programs tab houses the Safety Program for Surface Mobile Equipment and all ongoing evaluations. Please assign responsible persons to the safety program, then review and complete the initial forms and evaluations. You can add additional program evaluations at any time by clicking on the program details and clicking “Add Evaluation”. If you have multiple mine sites at your organization, you will see multiple programs in the program list.`,
  },
  {
    target: ".sidebar-equipment",
    placementBeacon: "right",
    placement: "right",
    title: "Equipment Tracking",
    content: `The Equipment tab allows for the tracking of surface mobile equipment. Setting up an equipment list is required before completing a Surface Mobile Equipment Inspection. You can also quickly add equipment using the inspection form.`,
  },
  {
    target: ".sidebar-dashboard",
    placementBeacon: "right",
    placement: "right",
    title: "Visualize All of Your Data with the Dashboard",
    content:
      "The Dashboard offers a comprehensive overview of your organizational metrics, presented through interactive widgets, detailed graphs, and informative charts. You can customize your dashboard to display the data that is most important to you. You can also quickly add new inspections from this screen.",
  },
];
