import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import React from "react";
import { Contacts } from "./contacts";
import { EntityTypes } from "../../../types/enums";

export function EventDetailsContacts(props) {
  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      <CardHeader className="lynx-card-header" title="Contacts" />
      <CardContent className="p-1 mt-2 mb-0 pb-0">
        <Contacts
          isLoading={props.commentsLoading}
          entityId={props.event.id}
          entityType={EntityTypes.Event}
          isNewEntity={false}
        />
      </CardContent>
    </Card>
  );
}
