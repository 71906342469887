import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState, useRef } from "react";
import { Form } from "tabler-react";
import { BulkUpdateActions, UserRoles } from "../../types/enums";
import SingleSelect from "../form-controls/single-select";
import { validateFileSize } from "../../services/attachments";
import { LynxDialog } from "../lynx-dialog";
import { roleMatch } from "../../actions/auth";
import { Alert } from "@mui/material";

const BULK_ATTACHMENT_UPLOAD_LIMIT = 100;

export function BulkUpdate(props) {
  const [updateConfig, setUpdateConfig] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showFileSizeError, setShowFileSizeError] = useState(false);
  const [showAttachmentsConfirm, setShowAttachmentsConfirm] = useState(false);
  const [showUpdateConfirm, setShowUpdateConfirm] = useState(false);

  const canUpload = props.totalItems <= BULK_ATTACHMENT_UPLOAD_LIMIT;

  const hiddenFileInput = useRef(null);
  const canSubmitUpdate = () => {
    let enabledCols = updateConfig.filter((x) => x.enabled);
    if (_.isEmpty(enabledCols)) return false;
    for (let i = 0; i < enabledCols.length; i++) {
      if (
        !enabledCols[i].action ||
        (enabledCols[i].action !== BulkUpdateActions.Clear &&
          !enabledCols[i].value)
      ) {
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    if (!_.isEmpty(props.columns)) {
      setUpdateConfig(
        props.columns
          .filter((x) => x.updateable)
          .map((col) => {
            return {
              field: col.dtoId ?? col.field,
              headerName: col.headerName,
              action: "",
              enabled: false,
              value: "",
              type: col.type,
              nullable: col.nullable,
              valueOptions: col.valueOptions,
              valueField: col.valueField,
              labelField: col.labelField,
              lookup: col.lookup,
            };
          })
      );
    }
  }, [props.columns]);

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    if (validateFileSize([...uploadedFiles, ...selectedFiles])) {
      setUploadedFiles([...uploadedFiles, ...selectedFiles]);
      setShowAttachmentsConfirm(true);
    } else {
      setShowFileSizeError(true);
    }
  };

  const handleActionChange = (e, field) => {
    let config = [...updateConfig];
    let colIndex = config.findIndex((x) => x.field === field);
    config[colIndex].action = e.target.value;
    setUpdateConfig(config);
  };

  const handleEnableChange = (e, field) => {
    let config = [...updateConfig];
    let colIndex = config.findIndex((x) => x.field === field);
    config[colIndex].enabled = e.target.checked;
    setUpdateConfig(config);
  };

  const submitUpdate = () => {
    setShowUpdateConfirm(true);
  };

  const transformConfig = () => {
    let config = [...updateConfig];
    let eventUpdateDto = { updateConfiguration: {} };
    config.forEach((col) => {
      if (col.enabled) {
        eventUpdateDto.updateConfiguration[col.field] = col.action;
        eventUpdateDto[col.field] =
          col.type == "dateTime" ? moment(col.value).utc().format() : col.value;
      }
    });
    return eventUpdateDto;
  };

  const getActionValues = (col) => {
    if (col.type === "string") {
      let values = [
        { value: BulkUpdateActions.Update, label: "Update" },
        { value: BulkUpdateActions.Append, label: "Append" },
        { value: BulkUpdateActions.Prepend, label: "Prepend" },
      ];
      if (col.nullable) {
        values.push({ value: BulkUpdateActions.Clear, label: "Clear" });
        values.push({
          value: BulkUpdateActions.UpdateIfEmpty,
          label: "Update If Empty",
        });
      }
      return values;
    }
    if (
      col.type === "date" ||
      col.type === "dateTime" ||
      col.type === "number" ||
      col.type == "singleSelect"
    ) {
      let values = [{ value: BulkUpdateActions.Update, label: "Update" }];
      if (col.nullable) {
        values.push({ value: BulkUpdateActions.Clear, label: "Clear" });
        values.push({
          value: BulkUpdateActions.UpdateIfEmpty,
          label: "Update If Empty",
        });
      }
      return values;
    }
    if (col.type === "boolean") {
      return [{ value: BulkUpdateActions.Update, label: "Update" }];
    }
  };

  const handleInputChange = (value, field) => {
    let config = [...updateConfig];
    let colIndex = config.findIndex((x) => x.field === field);
    config[colIndex].value = value;
    setUpdateConfig(config);
  };

  const getUpdateInput = (col) => {
    if (col.action == BulkUpdateActions.Clear || !col.action) {
      return <></>;
    }
    if (col.type == "date") {
      return (
        <>
          <Form.Input
            type="date"
            value={col.value}
            onChange={(e) => handleInputChange(e.target.value, col.field)}
          />
        </>
      );
    }
    if (col.type == "dateTime") {
      return (
        <>
          <Form.Input
            type="datetime-local"
            value={col.value}
            onChange={(e) => handleInputChange(e.target.value, col.field)}
          />
        </>
      );
    }
    if (col.type == "boolean") {
      return (
        <>
          <Form.Checkbox
            checked={col.value}
            label={" "}
            onChange={(e) => handleInputChange(e.target.checked, col.field)}
          />
        </>
      );
    }
    if (col.type == "number") {
      return (
        <>
          <Form.Input
            type="number"
            onChange={(e) => handleInputChange(e.target.value, col.field)}
            checked={col.value}
          ></Form.Input>
        </>
      );
    }
    if (col.type == "string") {
      return (
        <>
          <Form.Textarea
            onChange={(e) => handleInputChange(e.target.value, col.field)}
            value={col.value}
          ></Form.Textarea>
        </>
      );
    }
    if (col.type == "singleSelect") {
      var matchingLookups =
        col.valueField && col.labelField ? [] : col.valueOptions;
      for (const [key, value] of Object.entries(props.lookups)) {
        if (_.toLower(key) == _.toLower(col.lookup))
          matchingLookups = JSON.parse(value);
      }
      return (
        <>
          <Form.Select
            value={col.value}
            onChange={(e) => handleInputChange(e.target.value, col.field)}
          >
            <option value={""}></option>
            {matchingLookups.map((lookup) => (
              <option
                value={col.valueField ? lookup[col.valueField] : lookup}
                key={col.valueField ? lookup[col.valueField] : lookup}
              >
                {col.labelField ? lookup[col.labelField] : lookup}
              </option>
            ))}
          </Form.Select>
        </>
      );
    }
  };
  const handleInputClick = (event) => {
    event.target.value = "";
  };

  return (
    <>
      {roleMatch([UserRoles.Admin]) && (
        <>
          <div className="d-flex mt-1 ml-1 mb-1">
            <Alert severity="warning">{`${props.totalItems} items will be updated!`}</Alert>
          </div>
          <div className="d-flex flex-column">
            <TableContainer component={Paper}>
              <Table
                size="small"
                sx={{ maxWidth: 1200 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 50 }}>Update Field?</TableCell>
                    <TableCell sx={{ width: 150 }}>Field</TableCell>
                    <TableCell sx={{ width: 200 }}>Action</TableCell>
                    <TableCell sx={{ width: 200 }}>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {updateConfig.map((col) => (
                    <TableRow key={col.field}>
                      <TableCell component="th" scope="row">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => handleEnableChange(e, col.field)}
                            />
                          }
                        />
                      </TableCell>
                      <TableCell>{col.headerName}</TableCell>
                      <TableCell>
                        {col.enabled && (
                          <SingleSelect
                            disabled={!col.enabled}
                            onChange={(e) => handleActionChange(e, col.field)}
                            dropdownValues={getActionValues(col)}
                            label="label"
                            id="value"
                            value={col.action}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {col.enabled && getUpdateInput(col)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="d-flex flex-row align-items-center mb-5 mt-2">
              {canSubmitUpdate() && (
                <Button
                  variant="outlined"
                  color="error"
                  style={{ width: 200 }}
                  className=" ml-2"
                  onClick={submitUpdate}
                >
                  Bulk Update {props.totalItems} Items
                </Button>
              )}
              <Button
                variant="outlined"
                style={{ width: 300 }}
                disabled={!canUpload}
                className="ml-2"
                onClick={() => {
                  canUpload && hiddenFileInput.current.click();
                }}
              >
                Upload Attachments to {props.totalItems} Items
              </Button>
              {!canUpload && (
                <Alert severity="error" sx={{ py: 0, ml: 1 }}>
                  {`Can't add attachments to more than ${BULK_ATTACHMENT_UPLOAD_LIMIT} events at once.`}
                </Alert>
              )}
            </div>
            <input
              type="file"
              multiple
              ref={hiddenFileInput}
              onChange={handleFileChange}
              style={{ display: "none" }}
              onClick={handleInputClick}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,
    text/plain, image/*, text/html, video/*, audio/*, .pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
    .doc, .docx"
            />
            <LynxDialog
              open={showFileSizeError}
              handleClose={() => setShowFileSizeError(false)}
              title={`File size exceeded`}
              description={
                "Attachments are over the required total size limit of 50MB."
              }
              handleConfirm={() => setShowFileSizeError(false)}
            />
            <LynxDialog
              open={showAttachmentsConfirm}
              handleClose={() => {
                setShowAttachmentsConfirm(false);
                setUploadedFiles([]);
              }}
              title={`Upload Attachments?`}
              description={`Are you sure you want to add ${uploadedFiles.length} attachments to ${props.totalItems} items?`}
              handleConfirm={() => props.handleUploadAttachments(uploadedFiles)}
            />
            <LynxDialog
              open={showUpdateConfirm}
              handleClose={() => {
                setShowUpdateConfirm(false);
              }}
              title={`Proceed with Bulk Update?`}
              description={`Are you sure you want to update ${props.totalItems} items?`}
              handleConfirm={() => {
                const dto = transformConfig();
                props.postBulkUpdate(dto);
              }}
            />
          </div>
        </>
      )}
    </>
  );
}
