import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { apiUrl } from "../modules/store";
import { LocalStorageKeys } from "../types/enums";
import _ from "lodash";
const TIMEOUT_TIME_MS = 3000;
const onlinePollingInterval = 10000;

const timeout = (time, promise) => {
  return new Promise(function (resolve, reject) {
    setTimeout(() => {
      reject(new Error("Request timed out."));
    }, time);
    promise.then(resolve, reject);
  });
};

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;
  // If the browser has no network connection return offline

  if (!navigator.onLine) return navigator.onLine;
  var timeoutTime = TIMEOUT_TIME_MS;

  var offlineDisabledDateTime = localStorage.getItem(
    LocalStorageKeys.DisableOfflineDateTime
  );
  if (offlineDisabledDateTime) {
    var disabledDateAdjusted = new Date(
      moment(offlineDisabledDateTime).utc().add(5, "m").format()
    );
    var currentDate = new Date(moment.utc().format());
    if (disabledDateAdjusted > currentDate) {
      return true;
    }
  }

  var offlineTimeout = localStorage.getItem(LocalStorageKeys.OfflineTimeout);
  if (offlineTimeout && _.isFinite(_.toNumber(offlineTimeout))) {
    timeoutTime = _.toNumber(offlineTimeout);
  }

  try {
    await timeout(
      timeoutTime,
      fetch(`${apiUrl}/status`, {
        method: "GET",
        signal,
      })
    );
    return true;
  } catch (error) {
    // Error Log
    console.error(error);

    // This can be because of request timed out
    // so we abort the request for any case
    controller.abort();
  }
  return false;
};

const OnlineStatusContext = React.createContext(true);

export const OnlineStatusProvider = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState(true);

  const checkStatus = async () => {
    var offlineDisabled = localStorage.getItem(
      LocalStorageKeys.DisableOfflineMode
    );
    if (offlineDisabled == "false" || !offlineDisabled) {
      const online = await checkOnlineStatus();
      setOnlineStatus(online);
    }
  };

  useEffect(() => {
    window.addEventListener("offline", () => {
      var offlineDisabled = localStorage.getItem(
        LocalStorageKeys.DisableOfflineMode
      );
      if (offlineDisabled == "false") {
        setOnlineStatus(false);
      }
    });
    checkStatus();
    // Add polling incase of slow connection
    const id = setInterval(() => {
      checkStatus();
    }, onlinePollingInterval);

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });

      clearInterval(id);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

export const useOnlineStatus = () => {
  const store = useContext(OnlineStatusContext);
  return store;
};

export default useOnlineStatus;
