import Container from "@mui/material/Container";
import React from "react";
import { Grid } from "tabler-react";
import { EntityTypes } from "../../../types/enums";
import { AttachmentViewer } from "./../../attachment-viewer";
import SecurityIcon from "@mui/icons-material/Security";
import "./incident-form.css";

export function IncidentAttachments(props) {
  return (
    <Container className="mt-2" maxWidth="xl">
      <Grid.Col md={12} width={12} className="pl-0 pr-0 mb-2">
        <AttachmentViewer
          title={
            <div className="d-flex">
              Investigation Attachments <SecurityIcon className="ml-3" />
            </div>
          }
          isExistingEntity={true}
          entityType={EntityTypes.IncidentInvestigation}
          entityId={props.incident.id}
          cardClass="mb-0"
          rowHeight={200}
          cols={6}
        />
      </Grid.Col>
      <Grid.Col md={12} width={12} className="pl-0 pr-0">
        <AttachmentViewer
          title="Initial Report Attachments"
          isExistingEntity={true}
          entityType={EntityTypes.Incident}
          entityId={props.incident.id}
          cardClass="mb-0"
          rowHeight={200}
          cols={6}
        />
      </Grid.Col>
    </Container>
  );
}
