import { apiUrl, client } from "../modules/store";
export function getMonitoringLimits(params) {
  return client.get(apiUrl + "/monitoringLimits", {
    params: params,
  });
}

export function updateMonitoringLimit(id, dto) {
  return client.put(`${apiUrl}/monitoringLimits/${id}`, dto);
}
export function createMonitoringLimit(dto) {
  return client.post(`${apiUrl}/monitoringLimits`, dto);
}
export function deleteMonitoringLimit(id) {
  return client.delete(`${apiUrl}/monitoringLimits/${id}`);
}
