import React from "react";

import LayersIcon from "@mui/icons-material/Layers";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import { styled } from "@mui/material/styles";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: "absolute",
  bgcolor: "red",
  "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
  // "& .MuiFab-primary": { backgroundColor: "#227c9d", color: "#f3a738" },
}));
export function MapTopOverlay(props) {
  return (
    <div
      className={`map-header-overlay ${
        props.withSidebar ? "with-sidebar" : ""
      }`}
    >
      <StyledSpeedDial
        ariaLabel="SpeedDial playground example"
        icon={<SpeedDialIcon />}
        direction={"left"}
      >
        <SpeedDialAction
          tooltipPlacement="bottom"
          key={"Layers"}
          icon={<LayersIcon />}
          tooltipTitle={"Layers"}
          onClick={(e) => props.handleActionClick(e, "Layers")}
        />
      </StyledSpeedDial>
    </div>
  );
}
