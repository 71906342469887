import { apiUrl, client } from "../modules/store";
const url = `${apiUrl}/departments`;
export function getDepartments(params) {
  return client.get(url);
}

export function getDepartmentById(id) {
  return client.get(`${url}/${id}`);
}

export function getDepartmentByUserId(id) {
  return client.get(`${url}/userid/${id}`);
}

export function createDepartment(dto) {
  return client.post(url, dto);
}

export function updateDepartment(id, dto) {
  return client.put(`${url}/${id}`, dto);
}

export function deleteDepartment(id) {
  return client.delete(`${url}/${id}`);
}

