import LinkIcon from "@mui/icons-material/Link";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import alertify from "alertifyjs";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "tabler-react";
import { getEntityAttachments } from "../../../services/attachments";
import { getStatusChip } from "./incident-container";
export function IncidentQuickView(props) {
  const [incident, setIncident] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [attachmentsLoaded, setAttachmentesLoaded] = useState(false);
  const history = useHistory();
  useEffect(() => {
    if (!_.isEmpty(props.incident)) {
      setIncident(props.incident);
      getEntityAttachments("incident", props.incident.id).then((res) => {
        setAttachments(res.data);
        setAttachmentesLoaded(true);
      });
    }
  }, [props.incident]);

  const handleIncidentNumberClick = (e) => {
    e.preventDefault();
    history.push(`/incidents/${incident.id}`);
  };
  const handleLinkClick = (e) => {
    navigator.clipboard.writeText(`${window.origin}/incidents/${incident.id}`);
    alertify.success("Link copied!");
  };

  return (
    <>
      <DialogContent
        className="event-quick-view-dialog"
        sx={{ padding: "10px 12px" }}
      >
        <Grid>
          <Grid.Row>
            <Grid.Col width={10}>
              <div className="d-flex flex-wrap align-items-center mb-1">
                <Link
                  href="#"
                  onClick={(e) => handleIncidentNumberClick(e)}
                  underline="hover"
                  className="mr-2"
                >
                  <Typography variant="h6" component="span">
                    {incident.incidentNumber} - {incident.type}
                  </Typography>
                </Link>
                <Typography
                  className="mr-2"
                  variant="subtitle1"
                  component="span"
                >
                  {incident.subType}
                </Typography>
                <div> {getStatusChip(incident)}</div>
              </div>
            </Grid.Col>
            <Grid.Col width={2}>
              <div className="d-flex flex-wrap align-items-center mb-1">
                <div className="ml-auto">
                  <Tooltip title="Copy link to event">
                    <IconButton onClick={handleLinkClick}>
                      <LinkIcon />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col md={6} width={12} className="">
              {incident.createdDateTimeUtc && (
                <Typography variant="subtitle2">
                  Submitted:&nbsp;
                  {moment.utc(incident.createdDateTimeUtc).format("YYYY-MM-DD")}
                </Typography>
              )}
              {incident.createdByUserFullName && (
                <Typography variant="subtitle2">
                  Submitted By:&nbsp;
                  {incident.createdByUserFullName}
                </Typography>
              )}
            </Grid.Col>
            <Grid.Col md={6} width={12} className="">
              <Typography variant="subtitle2">{incident.assetName}</Typography>
            </Grid.Col>
          </Grid.Row>
          <Divider className="mt-2 mb-2" />{" "}
          <Grid.Row className="mt-2">
            <Grid.Col md={6} width={12} className="">
              <Typography variant="body">
                <b>Severity:</b>&nbsp;{incident.severity || "None"}
              </Typography>
            </Grid.Col>
            <Grid.Col md={6} width={12} className="">
              <Typography variant="body">
                <b>Title:</b>&nbsp;{incident.title}
              </Typography>
            </Grid.Col>
            <Grid.Col md={6} width={12} className="">
              <Typography variant="body">
                <b>Descirption:</b>&nbsp;{incident.description}
              </Typography>
            </Grid.Col>
          </Grid.Row>{" "}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.handleClose()}>Close</Button>
      </DialogActions>
    </>
  );
}
