import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "tabler-react";
import { getKpiById } from "../../../services/kpi-service";
import { getCustomFields } from "../../../services/custom-fields-service";
import { KpiDashboard } from "./kpi-dashboard";
import { KpiHistoryList } from "./kpi-history-list";
import { EntityTypes } from "../../../types/enums";

export function KpiDetails(props) {
  const [kpi, setKpi] = useState({});
  const [customFields, setCustomFields] = useState([]);
  const [view, setView] = React.useState("dashboard");
  const location = useLocation();
  useEffect(() => {
    getKpiById(props.match.params.id).then((res) => {
      setKpi(res.data);
    });
    getCustomFields(EntityTypes.Event).then((res) => {
      setCustomFields(res.data);
    });
    const values = queryString.parse(props.location.search);
    setView(values.view ?? "dashboard");
  }, []);

  const handleChange = (event, newValue) => {
    setView(newValue);
    window.history.pushState({}, "", location.pathname + `?view=${newValue}`);
  };

  return (
    <>
      <Paper>
        <Grid>
          <Grid.Row className="ml-0 mr-0">
            <Grid.Col lg={12} width={12} className="">
              <div className="d-flex">
                <Typography
                  variant="h3"
                  className="form-header-text"
                  component="div"
                >
                  {kpi.name}
                </Typography>
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
              <Tabs value={view} onChange={handleChange}>
                <Tab label="Dashboard" value="dashboard" />
                <Tab label="History" value="history" />
              </Tabs>
            </Box>
          </Grid.Row>
        </Grid>
      </Paper>
      {view == "dashboard" && !_.isEmpty(kpi) && (
        <KpiDashboard kpi={kpi} customFields={customFields} />
      )}
      {view == "history" && !_.isEmpty(kpi) && (
        <KpiHistoryList kpi={kpi} customFields={customFields} {...props} />
      )}
    </>
  );
}
