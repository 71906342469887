import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import React from "react";

import { EntityTypes } from "../../../types/enums";
import { Comments } from "./comments";

export function EventDetailsComments(props) {
  return (
    <Card
      className="lynx-card"
      variant="outlined"
      sx={{ width: "100%", overflow: "visible" }}
    >
      <CardHeader className="lynx-card-header" title="Comments" />
      <CardContent className="p-1 mt-2 mb-0 pb-0">
        <Comments
          handleAddComment={props.handleAddComment}
          users={props.users}
          entityType={EntityTypes.Event}
          entityId={props.event.id}
        />
      </CardContent>
    </Card>
  );
}
