import React, { useState, useEffect } from "react";
import { Icon, Grid, Card, Form } from "tabler-react";
import Autocomplete from "@mui/material/Autocomplete";
import MuiGrid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import _ from "lodash";

export function AutoComplete(props) {
  //values
  //defaultValues
  //idField - "id"
  //labelField = "name"
  //label = "Select values"
  const [autoCompleteValue, setAutoCompleteValue] = useState(null);

  const [autoCompleteInputValue, setAutoCompleteInputValue] = useState("");

  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(props.defaultSelectedValues)) {
      setSelectedValues(props.defaultSelectedValues);
    }
  }, [props.defaultSelectedValues]);

  useEffect(() => {
    props.handleSelectedValuesChanged(selectedValues);
  }, [selectedValues, props]);

  const handleValueSelected = (e, value) => {
    if (value) {
      setAutoCompleteValue(null);
      let existingValues = _.cloneDeep(selectedValues);
      let existingValue = existingValues.find(
        (x) => x[props.idField] == value[props.idField]
      );
      if (!existingValue) {
        setSelectedValues([...existingValues, { ...value, type: "manual" }]);
      }
    }
  };

  const autoCompleteValueInputChange = (e, newValue, reason) => {
    if (reason === "reset") {
      setAutoCompleteValue(null);
      setAutoCompleteInputValue("");
      return;
    } else {
      setAutoCompleteInputValue(newValue);
    }
  };

  const handleValueDeleted = (value) => {
    let newValues = _.cloneDeep(selectedValues);
    let index = newValues.findIndex((x) => x.id == value[props.idField]);
    newValues.splice(index, 1);
    setSelectedValues(newValues);
  };

  return (
    <>
      <Grid.Col md={12} width={12} className="mb-2 mt-1">
        {/* Add "search" to ID to disabled autofill / lastpass */}
        <Autocomplete
          id="parameter-grouped-search"
          options={_.sortBy(
            props.values.filter(
              (x) =>
                !selectedValues
                  .map((y) => y[props.idField])
                  .includes(x[props.idField])
            ),
            [
              (value) =>
                props.labelFunction
                  ? props.labelFunction(value)
                  : value[props.labelField].toLowerCase(),
            ]
          )}
          getOptionLabel={(option) =>
            props.labelFunction
              ? props.labelFunction(option)
              : option[props.labelField]
          }
          sx={{ width: props.width ?? 250 }}
          renderInput={(params) => (
            <TextField {...params} variant="standard" label={props.label} />
          )}
          onChange={handleValueSelected}
          value={autoCompleteValue}
          inputValue={autoCompleteInputValue}
          onInputChange={autoCompleteValueInputChange}
        />
      </Grid.Col>
      <Grid.Col md={12} width={12} className="mb-2">
        <MuiGrid container spacing={1}>
          {_.sortBy(selectedValues, [
            (value) =>
              props.labelFunction
                ? props.labelFunction(value)
                : value[props.labelField].toLowerCase(),
          ]).map((value) => (
            <MuiGrid item key={value[props.idField]}>
              <Chip
                label={
                  props.labelFunction
                    ? props.labelFunction(value)
                    : value[props.labelField]
                }
                variant="outlined"
                onDelete={() => handleValueDeleted(value)}
              />
            </MuiGrid>
          ))}
        </MuiGrid>
      </Grid.Col>
    </>
  );
}
