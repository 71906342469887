import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import alertify from "alertifyjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Form, Grid } from "tabler-react";
import lynxColors from "../../../modules/lynxColors";
import { validationService } from "../../../services";
import {
  createMshaUser,
  deleteMshaUser,
  disableMshaUser,
  enableMshaUser,
  updateMshaUser,
} from "../../../services/msha-service";
import { LynxDialog } from "../../lynx-dialog";

export function MshaEditUser(props) {
  const [user, setUser] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEnableDialog, setShowEnableDialog] = useState(false);
  const [showDisableDialog, setShowDisableDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [error, setError] = useState("");
  const [formState, setFormState] = useState({
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    title: "",
    email: "",
    emailError: "",
  });
  const isExistingUser = props.user != null;
  const handleInputChange = (e) => {
    let newState = { ...formState };
    let name = e.target.name;
    let value = e.target.value;

    _.set(newState, name, value);
    setFormState(newState);
  };

  useEffect(() => {
    if (props.user) {
      setFormState({
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        email: props.user.email,
        title: props.user.title,
      });
      setUser(props.user);
    }
  }, [props.user]);

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateEmailValue(
      newState.email,
      newState,
      "emailError",
      true
    );
    validationService.validateRequiredField(
      newState,
      "firstName",
      "firstNameError",
      "First Name"
    );
    validationService.validateRequiredField(
      newState,
      "lastName",
      "lastNameError",
      "Last Name"
    );

    isFormValid = !validationService.hasError(
      newState,
      "firstNameError",
      "lastNameError"
    );

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleSave = () => {
    if (!validateDataForSave()) {
      return;
    }
    if (isExistingUser) {
      updateMshaUser(user.id, {
        firstName: formState.firstName,
        lastName: formState.lastName,
        title: formState.title,
      })
        .then((res) => {
          alertify.success("User Updated.");
          props.handleClose(true);
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    } else {
      createMshaUser({
        firstName: formState.firstName,
        lastName: formState.lastName,
        title: formState.title,
        email: formState.email,
        organizationId: props.organizationId,
      })
        .then((res) => {
          setShowSuccessDialog(true);
        })
        .catch((err) => {
          setError(err.response.data.message);
        });
    }
  };
  return (
    <Grid>
      <div className="mb-4 ml-2"> {getStatusChip(user.status)}</div>
      <Grid.Row>
        <Grid.Col md={12} width={12}>
          <Form.Group label="Email" isRequired>
            <Form.Input
              type="text"
              onChange={handleInputChange}
              disabled={isExistingUser}
              name={`email`}
              value={formState.email}
              error={formState.emailError}
            ></Form.Input>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={6} width={12}>
          <Form.Group label="First Name" isRequired>
            <Form.Input
              type="text"
              onChange={handleInputChange}
              name={`firstName`}
              value={formState.firstName}
              error={formState.firstNameError}
            ></Form.Input>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={6} width={12}>
          <Form.Group label="Last Name" isRequired>
            <Form.Input
              type="text"
              onChange={handleInputChange}
              name={`lastName`}
              value={formState.lastName}
              error={formState.lastNameError}
            ></Form.Input>
          </Form.Group>
        </Grid.Col>
        <Grid.Col md={6} width={12}>
          <Form.Group label="Title">
            <Form.Input
              type="text"
              onChange={handleInputChange}
              name={`title`}
              value={formState.title}
            ></Form.Input>
          </Form.Group>
        </Grid.Col>
        {!user.registrationCompletedDateTimeUtc &&
          isExistingUser &&
          user.registrationGuid && (
            <Grid.Col md={12} width={12} className="mb-2">
              <Tooltip title="Copy" className="mr-2">
                <IconButton
                  className="grid-edit-button"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `${window.location.origin}/register/${user.registrationGuid}`
                    );
                    alertify.success("Registration Link Copied.");
                  }}
                  aria-label="Copy"
                  size="small"
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>

              <Typography variant="span">
                Registration Link:{" "}
                <span id="userRegistrationLink">
                  {`${window.location.origin}/register/${user.registrationGuid}`}
                </span>
              </Typography>
            </Grid.Col>
          )}

        <Grid.Col md={12} width={12}>
          <Divider className="mb-2"></Divider>
          {isExistingUser && (
            <>
              {user.registrationCompletedDateTimeUtc ? (
                <>
                  {user.isActive ? (
                    <Button
                      variant="contained"
                      color="error"
                      className="mr-2"
                      onClick={() => {
                        setShowDisableDialog(true);
                      }}
                    >
                      Disable User
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="success"
                      disabled
                      className="mr-2"
                      onClick={() => {
                        setShowEnableDialog(true);
                      }}
                    >
                      Enable User
                    </Button>
                  )}
                </>
              ) : (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setShowDeleteDialog(true);
                  }}
                >
                  Delete User
                </Button>
              )}
            </>
          )}

          <Button
            className="float-right"
            variant="contained"
            onClick={handleSave}
          >
            {isExistingUser ? "Save" : "Add"}
          </Button>
          <Button
            onClick={() => {
              props.handleClose(false);
            }}
            className="float-right mr-2"
            variant="contained"
          >
            Cancel
          </Button>
        </Grid.Col>
        {!user.isActive && isExistingUser && (
          <div
            className="ml-3 text-red"
            style={{
              height: 35,
              verticalAlign: "top",
              display: "table-cell",
              fontSize: 14,
            }}
          >
            <span className="">Contact Lynx to re-enable this user.</span>
          </div>
        )}
      </Grid.Row>
      {showDeleteDialog && (
        <LynxDialog
          open={showDeleteDialog}
          title={`Delete User?`}
          description={"This action cannot be undone."}
          handleClose={() => {
            setShowDeleteDialog(false);
          }}
          handleDelete={() => {
            deleteMshaUser(user.id).then(() => {
              alertify.success("User Deleted.");
              props.handleClose(true);
            });
          }}
        />
      )}
      {showDisableDialog && (
        <LynxDialog
          open={showDisableDialog}
          title={`Disable User?`}
          description={
            "The user will no longer be able to login. This cannot be undone."
          }
          handleClose={() => {
            setShowDisableDialog(false);
          }}
          handleConfirm={() => {
            disableMshaUser(user.id).then(() => {
              alertify.success("User Disabled.");
              props.handleClose(true);
            });
          }}
        />
      )}
      {showEnableDialog && (
        <LynxDialog
          open={showEnableDialog}
          title={`Enable User?`}
          description={
            "The user will now be able to login. If the user does not remember their password, they can reset it on the login screen."
          }
          handleClose={() => {
            setShowEnableDialog(false);
          }}
          handleConfirm={() => {
            enableMshaUser(user.id).then(() => {
              alertify.success("User Enabled.");
              props.handleClose(true);
            });
          }}
        />
      )}
      {!_.isEmpty(error) && (
        <LynxDialog
          open={!_.isEmpty(error)}
          title={error}
          handleConfirm={() => {
            setError("");
          }}
        />
      )}
      {showSuccessDialog && (
        <LynxDialog
          open={showSuccessDialog}
          title={"User Added"}
          description={`The user has been added. The user will
          recieve a registration email with directions for registering for the platform. If the user does not recieve the email, 
          you can copy their registraion link from the users grid.`}
          handleConfirm={() => {
            props.handleClose(true);
          }}
        />
      )}
    </Grid>
  );
}
const getStatusChip = (status) => {
  if (!status) return;
  if (_.toLower(status) == "inactive") {
    <Chip label="Inactive" color="error" />;
    return;
  }
  if (_.toLower(status) == "pending registration") {
    return (
      <Chip
        label={status}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }

  return <Chip label="Active" color="success" />;
};
