import { apiUrl, client } from "../modules/store";

export function getUserNoitifications(params) {
  return client.get(apiUrl + "/notifications", {
    params: params,
  });
}

export function markNotificationAsRead(id, isRead) {
  return client.post(`${apiUrl}/notifications/${id}/read`, isRead);
}

export function readAllNotifications() {
  return client.post(`${apiUrl}/notifications/readall`, {});
}
