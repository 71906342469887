import React from "react";
import { MainSidebar } from "./dashboards/MainSidebar";
import Navbar from "./Navbar";
import { urlService } from "../services/url-service";
import { useLocation } from "react-router-dom";
import { loggedIn } from "../actions/auth";
import { AdminSidebar } from "./admin/AdminSidebar";
import useWindowDimensions from "../hooks/useWindowDimensions";
import _ from "lodash";
import { WylooPortalNavbar } from "./microsites/wyloo/wyloo-portal-navbar";
export default function Layout(props) {
  const location = useLocation();
  const dimensions = useWindowDimensions();
  const hasNoOverflow = () => {
    if (_.toLower(location.pathname) == "/events" && dimensions.isMobile) {
      return true;
    }
    return false;
  };

  const isMicrosite = () => {
    if (
      _.toLower(location.pathname) == "/investor" ||
      _.toLower(location.pathname) == "/wyloo" ||
      _.toLower(location.pathname).includes("/wyloo/")
    ) {
      return true;
    }
    return false;
  };

  React.useEffect(() => {}, [location.pathname]);

  return (
    <>
      {!isMicrosite() && <Navbar />}
      {isMicrosite() && <WylooPortalNavbar />}
      {urlService.isPublicScreen() ? (
        <> {props.children} </>
      ) : (
        <>
          {loggedIn() &&
            (!urlService.isAdminScreen() ? (
              <MainSidebar className="d-print-none" />
            ) : (
              <AdminSidebar />
            ))}

          <div
            className={`${
              hasNoOverflow()
                ? "content-wrapper-no-overflow"
                : "content-wrapper"
            }  padding-left-sidebar d-print-block`}
          >
            {props.children}
          </div>
        </>
      )}
    </>
  );
}
