import Button from "@mui/material/Button";

import Container from "@mui/material/Container";
import alertify from "alertifyjs";
import * as jsonpatch from "fast-json-patch";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Dimmer, Form, Grid } from "tabler-react";
import { hasLookupPermissions } from "../../../actions/auth";
import { patchIncident } from "../../../services/incident-service";

import { validationService } from "./../../../services/validation";

import "./incident-form.css";
import { LynxDialog } from "../../lynx-dialog";
import CircularProgress from "@mui/material/CircularProgress";
import { dateUtil } from "../../../services/date-util";
import Typography from "@mui/material/Typography";

export function IncidentFinalReview(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({});
  const [initialFormState, setInitialFormState] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  let incidentStatus = _.toLower(props.incident.status);
  useEffect(() => {
    if (!_.isEmpty(props.incident)) {
      setFormStateFromIncident(props.incident);
    }
  }, [props.incident]);

  const setFormStateFromIncident = (incident) => {
    let newState = {
      ...formState,
      finalReviewComment: incident.finalReviewComment,
    };
    setFormState(newState);
    setInitialFormState(_.cloneDeep(newState));
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    let { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const saveIncident = (saveAction) => {
    if (saveAction != "finalreview") {
      if (!validateDataForSave(saveAction)) {
        return;
      }
    }
    setIsSaving(true);
    let form = validationService.unsetErrors(
      formState,
      "finalReviewCommentError"
    );

    var diff = jsonpatch.compare(initialFormState, form);
    var dto = {
      incidentParticipants: [],
      userWitnesses: [],
      userParticipants: [],
      incidentPatch: diff,
    };
    patchIncident(props.incident.id, dto, saveAction).then((res) => {
      alertify.success("Incident Saved.");
      props.updateIncident(res.data);
      setIsSaving(false);
    });
  };

  const validateDataForSave = (saveAction) => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "finalReviewComment",
      "finalReviewCommentError",
      "Final Review Comment"
    );

    isFormValid = !validationService.hasError(newState, [
      "finalReviewCommentError",
    ]);

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  const canPerformFinalReview = () => {
    return hasLookupPermissions(props.incident.typeId, "canPerformFinalReview");
  };

  const commentFieldLocked = () => {
    return !canPerformFinalReview() || incidentStatus != "final review";
  };

  return (
    <Container className="mt-2" maxWidth="xl">
      <Dimmer active={isLoading} loader>
        <Form className="card mb-0">
          <Card.Header>
            <Card.Title>Final Review</Card.Title>
          </Card.Header>
          <Card.Body>
            <Grid.Row>
              <Grid.Col md={12} width={12}>
                <Form.Group isRequired label="Final Review Comment">
                  <Form.Textarea
                    name="finalReviewComment"
                    onChange={handleInputChange}
                    value={formState.finalReviewComment}
                    error={formState.finalReviewCommentError}
                    disabled={commentFieldLocked()}
                  ></Form.Textarea>
                </Form.Group>
              </Grid.Col>
            </Grid.Row>
          </Card.Body>
          {canPerformFinalReview() && (
            <Card.Footer>
              <>
                {incidentStatus == "final review" && (
                  <>
                    <Button
                      variant="contained"
                      color="success"
                      className="float-right ml-2"
                      onClick={() => saveIncident("finalreviewapproved")}
                    >
                      Save and Submit - Approved
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      className="float-right ml-2 review-button"
                      onClick={() => saveIncident("finalreviewnotapproved")}
                    >
                      Save and Submit - Not Approved
                    </Button>
                  </>
                )}
                <Button
                  className="float-right  review-button"
                  variant="contained"
                  onClick={() => saveIncident("finalreview")}
                  disabled={incidentStatus == "closed"}
                >
                  Save
                </Button>
                {props.incident.finalReviewCompletedDateTimeUtc && (
                  <div>
                    <Typography component={"span"}>
                      Final Review submitted by:{" "}
                      {props.incident.finalReviewCompletedByUserFullName}{" "}
                      {dateUtil.convertDateTimeToLocal(
                        props.incident.finalReviewCompletedDateTimeUtc
                      )}
                    </Typography>
                  </div>
                )}
              </>
            </Card.Footer>
          )}
        </Form>
      </Dimmer>
      {isSaving && (
        <LynxDialog
          open={isSaving}
          title="Saving incident. Do not close the window."
          description={
            <>
              <div className="d-flex align-items-center justify-content-center mt-4">
                <CircularProgress />
              </div>
            </>
          }
        />
      )}
    </Container>
  );
}
