export const site = {
  type: "FeatureCollection",
  name: "test",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { ID: null },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [-86.289996832289191, 52.738737193918077],
              [-86.290007089485627, 52.73871478486484],
              [-86.290034590493065, 52.738698501115429],
              [-86.290047961930455, 52.738694896001313],
              [-86.296003614291521, 52.737509711559923],
              [-86.296027465586221, 52.737507516205412],
              [-86.296029053162613, 52.737507525628502],
              [-86.297332427647675, 52.737532062566544],
              [-86.297350964080181, 52.737533790257132],
              [-86.297890352190564, 52.73762956281827],
              [-86.29790725633778, 52.737634067094632],
              [-86.297933623259482, 52.737650132253478],
              [-86.298861247695797, 52.738615377359807],
              [-86.298861849664661, 52.738615920209064],
              [-86.298867584723482, 52.738623863708604],
              [-86.29918535040882, 52.739204580374398],
              [-86.299939306625603, 52.739646667251648],
              [-86.30241474507369, 52.739918943455812],
              [-86.303308276632649, 52.739890939183155],
              [-86.30331279680594, 52.739890875852836],
              [-86.303349827737861, 52.739897115441707],
              [-86.303376800072897, 52.739913722222411],
              [-86.303384076500961, 52.739924640649768],
              [-86.303704802781212, 52.74068413704596],
              [-86.303707060613931, 52.740695745043013],
              [-86.303696809959064, 52.740718155295774],
              [-86.303691607428874, 52.740722708721115],
              [-86.302525623341779, 52.741641909196495],
              [-86.302503327866773, 52.741653642472151],
              [-86.302487016734219, 52.741657770990948],
              [-86.300097764045788, 52.742068477172744],
              [-86.301164501775418, 52.744211614643078],
              [-86.301167078150456, 52.744218550712176],
              [-86.301283557648603, 52.74474117983398],
              [-86.301284186994565, 52.744747655021868],
              [-86.301273934128602, 52.744770065042424],
              [-86.301246433443254, 52.744786351382466],
              [-86.301229508874584, 52.744790565997206],
              [-86.300142385294194, 52.744971020509304],
              [-86.299896359083476, 52.745459333842398],
              [-86.299889363472843, 52.745468999771361],
              [-86.299861861831786, 52.745485285785186],
              [-86.299847141208261, 52.745489153685774],
              [-86.296466026962747, 52.74611737393392],
              [-86.296443518925656, 52.74611930770768],
              [-86.29640648373389, 52.746113065954319],
              [-86.296382034772719, 52.746098989264354],
              [-86.296300072944859, 52.746022822660535],
              [-86.296297548438929, 52.746020290996213],
              [-86.296291809994315, 52.74601252712673],
              [-86.295370266318002, 52.744346132309282],
              [-86.293587668350384, 52.744627644656099],
              [-86.293568223207387, 52.744629056834412],
              [-86.293531189696409, 52.744622814187487],
              [-86.293524028431221, 52.744619985200387],
              [-86.293042181539263, 52.744398252243997],
              [-86.293022371595143, 52.744384472147374],
              [-86.293016519078634, 52.74437625802679],
              [-86.290000508063059, 52.738751507132058],
              [-86.289996832289191, 52.738737193918077],
            ],
          ],
        ],
      },
    },
  ],
};

export const marten = {
  type: "FeatureCollection",
  name: "Lease_w_no_LUP",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { Rights: "Mining" },
      geometry: {
        type: "Polygon",
        coordinates: [
          // [
          //   [-86.225554202540195, 52.78018195022721],
          //   [-86.225761803935114, 52.776588388983726],
          //   [-86.225969865972331, 52.772998514268878],
          //   [-86.225994696951588, 52.772568864577458],
          //   [-86.225996105002167, 52.772568873763348],
          //   [-86.231859557934669, 52.772692206134145],
          //   [-86.237725863044759, 52.772815266938395],
          //   [-86.243557276831254, 52.772937365615313],
          //   [-86.249373597397195, 52.773058812360183],
          //   [-86.255297029363135, 52.773182264442255],
          //   [-86.261220217662697, 52.773305329236003],
          //   [-86.267143411060289, 52.773428188167912],
          //   [-86.273066612484854, 52.773550661405572],
          //   [-86.273274088082331, 52.76989921733832],
          //   [-86.273495000907801, 52.766008090238763],
          //   [-86.273572903408962, 52.762577450773584],
          //   [-86.273652375534027, 52.759113915569486],
          //   [-86.273731846230135, 52.755657570303143],
          //   [-86.273822076946317, 52.751736775619442],
          //   [-86.273787378485409, 52.74812366061122],
          //   [-86.273753078295229, 52.744520614626161],
          //   [-86.273720217920498, 52.740915777317653],
          //   [-86.273687951486707, 52.737297636203657],
          //   [-86.279680664738592, 52.737308022688325],
          //   [-86.285714469502949, 52.737318174105027],
          //   [-86.291703728538337, 52.737327932236987],
          //   [-86.29764077027427, 52.73733725853149],
          //   [-86.29765454851767, 52.733732255190098],
          //   [-86.297667112537624, 52.730106655262276],
          //   [-86.297679155095963, 52.726760190459487],
          //   [-86.297692261512708, 52.723151850463438],
          //   [-86.297757225853005, 52.719471625348596],
          //   [-86.297820272704058, 52.71589243472917],
          //   [-86.297884302298044, 52.712258588316189],
          //   [-86.29794579040464, 52.708710309924555],
          //   [-86.304014044053275, 52.708736195764224],
          //   [-86.309973845012564, 52.708761499610574],
          //   [-86.315838696441247, 52.708785955965801],
          //   [-86.321813643719125, 52.708810662098138],
          //   [-86.327649979486878, 52.708781329820319],
          //   [-86.333486318263624, 52.70875117091613],
          //   [-86.334709211304997, 52.708744823449742],
          //   [-86.336407054778419, 52.708735908176322],
          //   [-86.336513324714687, 52.708735334157126],
          //   [-86.337692015008301, 52.708729248003614],
          //   [-86.337940895264467, 52.708727940907515],
          //   [-86.339570272265362, 52.708719497097078],
          //   [-86.339745078974929, 52.708718582687773],
          //   [-86.345169897662998, 52.708690321138832],
          //   [-86.345218357318444, 52.712356631817677],
          //   [-86.345266680991074, 52.716024467690538],
          //   [-86.345312917460646, 52.719692649389586],
          //   [-86.345359137447105, 52.723367211544534],
          //   [-86.345285570190484, 52.727316384065723],
          //   [-86.345227358447232, 52.730443143988978],
          //   [-86.345157861924861, 52.734162190472887],
          //   [-86.34509419691561, 52.737619658231871],
          //   [-86.34498671362978, 52.741214788352572],
          //   [-86.344878756168484, 52.744809553994955],
          //   [-86.344770576382857, 52.748403956677599],
          //   [-86.344664607128237, 52.751970140807444],
          //   [-86.338808197884688, 52.751964701504996],
          //   [-86.332924099194017, 52.751959087048895],
          //   [-86.327499971903876, 52.751953541819873],
          //   [-86.321994598458232, 52.751947553903527],
          //   [-86.321493299005567, 52.75194684517944],
          //   [-86.321078699881951, 52.752804184288607],
          //   [-86.319775566012012, 52.755498745139342],
          //   [-86.320161461993166, 52.75905505157408],
          //   [-86.32055206994815, 52.762648599821063],
          //   [-86.320942665713218, 52.766239267673974],
          //   [-86.320819766859117, 52.770051202773438],
          //   [-86.320697965850101, 52.773828170746384],
          //   [-86.320574702532895, 52.777649897765023],
          //   [-86.320451285798356, 52.781475127517055],
          //   [-86.314682823044478, 52.781201469844717],
          //   [-86.308605526584998, 52.780912876796855],
          //   [-86.305437523615979, 52.78076231239848],
          //   [-86.30175941111905, 52.780587356404745],
          //   [-86.301703796646876, 52.780584691693825],
          //   [-86.299900143608212, 52.780498907197654],
          //   [-86.299809330596474, 52.780494505686065],
          //   [-86.296918576110585, 52.780357575712053],
          //   [-86.296916907858801, 52.780439014732757],
          //   [-86.296908937995596, 52.780829580600134],
          //   [-86.296908074873031, 52.780872097965727],
          //   [-86.296886798172153, 52.781943842910259],
          //   [-86.296886076425295, 52.781980337761553],
          //   [-86.296849289109829, 52.783833036755951],
          //   [-86.296777171426484, 52.787464813918064],
          //   [-86.29670511719705, 52.791091734678197],
          //   [-86.29662836832388, 52.794954521052013],
          //   [-86.290529265523645, 52.794930979854506],
          //   [-86.284428942998701, 52.794907027937867],
          //   [-86.278338018670809, 52.794882729556122],
          //   [-86.278289495255208, 52.794882524387511],
          //   [-86.276590217279093, 52.794875773747819],
          //   [-86.276499294958228, 52.794875399237299],
          //   [-86.273060362436169, 52.794861485091687],
          //   [-86.273083877196726, 52.797050318601784],
          //   [-86.273087394049767, 52.797378472726216],
          //   [-86.273087123407947, 52.797382246846844],
          //   [-86.272994904316391, 52.798656544112326],
          //   [-86.272753771452059, 52.802030245390036],
          //   [-86.270007459105869, 52.802006282425658],
          //   [-86.26850284744323, 52.801993152313258],
          //   [-86.267954077025735, 52.801988420077571],
          //   [-86.26587983008865, 52.801970303204818],
          //   [-86.265758037250578, 52.801969190178774],
          //   [-86.264100014432586, 52.801954695366412],
          //   [-86.263227125856702, 52.801947032026476],
          //   [-86.260855119408035, 52.801926169321881],
          //   [-86.255013743678731, 52.801874721079365],
          //   [-86.248995006563604, 52.801821323733293],
          //   [-86.243054864723277, 52.801760480852479],
          //   [-86.237149980848102, 52.801699657150991],
          //   [-86.231210423767493, 52.8016384048967],
          //   [-86.225268539779023, 52.801577019907185],
          //   [-86.225345116192528, 52.7981041606701],
          //   [-86.22542141686435, 52.79462761174441],
          //   [-86.225455295289834, 52.790952015671579],
          //   [-86.225488077908352, 52.78737853634842],
          //   [-86.225521436772993, 52.783717855801491],
          //   [-86.225554202540195, 52.78018195022721],
          // ],
          [
            [-86.285180426370388, 52.783960726706539],
            [-86.285184996808383, 52.78397279831556],
            [-86.285306974525696, 52.784124533418769],
            [-86.285403013975298, 52.784337141503933],
            [-86.285407192669055, 52.784343278600325],
            [-86.285423412531998, 52.784353263165478],
            [-86.285445603489876, 52.784356992096136],
            [-86.285465577707583, 52.784354236168816],
            [-86.286158992574769, 52.784149166399594],
            [-86.289123464825053, 52.784767549374791],
            [-86.289137501984072, 52.784768981713313],
            [-86.289159810646865, 52.78476552012922],
            [-86.289176353737119, 52.784755732286122],
            [-86.289179973752468, 52.784751170072887],
            [-86.289373096059933, 52.784424440398773],
            [-86.291176176335767, 52.782156738068259],
            [-86.291177930162576, 52.782154141983639],
            [-86.291746182454432, 52.781197963879372],
            [-86.292369186412529, 52.780768903125789],
            [-86.29356447565614, 52.780639855696798],
            [-86.29454804180827, 52.78059986816811],
            [-86.294563605779217, 52.780597533864032],
            [-86.295374602462275, 52.780372526657523],
            [-86.295377920455948, 52.780371467766621],
            [-86.295394460393467, 52.780361679038329],
            [-86.295398836076657, 52.780355683016722],
            [-86.295607183661289, 52.779929894637569],
            [-86.295993638077363, 52.779353083816183],
            [-86.296558716500186, 52.778668304912934],
            [-86.297330503871166, 52.777770470906134],
            [-86.297332001101466, 52.777768592295175],
            [-86.297847668938616, 52.777037135901381],
            [-86.29859521279991, 52.776092681449583],
            [-86.298597214736546, 52.776089817072247],
            [-86.298907465556212, 52.775541847352002],
            [-86.299364132634366, 52.774918791838523],
            [-86.29955534368932, 52.775099593414616],
            [-86.299561020458782, 52.775103851320956],
            [-86.299736040797029, 52.775212022865134],
            [-86.299745260891711, 52.775216391600928],
            [-86.299752439059716, 52.775218501231876],
            [-86.30010444650955, 52.775297157051469],
            [-86.30010590893761, 52.775297435327161],
            [-86.300658982251974, 52.775407745088529],
            [-86.301539015598223, 52.77559646642537],
            [-86.302570250991621, 52.775816809486287],
            [-86.30257220085042, 52.775817180458333],
            [-86.303174182985117, 52.775927496350398],
            [-86.304303348166812, 52.776209248950074],
            [-86.304319941276347, 52.776211233720595],
            [-86.304799448729312, 52.77621404428703],
            [-86.304821628222228, 52.77621066887199],
            [-86.305176945218022, 52.776090242156862],
            [-86.305177068880639, 52.776090153019403],
            [-86.305187784348803, 52.776085092507685],
            [-86.305468290063388, 52.775903107601209],
            [-86.305473242166414, 52.775899181847038],
            [-86.305729541681359, 52.775655755575734],
            [-86.3057304101645, 52.775654951775167],
            [-86.305732029192242, 52.775653073707481],
            [-86.30598954227554, 52.775333165174125],
            [-86.305993707272762, 52.775324920477239],
            [-86.306047952757538, 52.775080760891576],
            [-86.306154866264379, 52.774699211349137],
            [-86.306155120724569, 52.774698583614111],
            [-86.306263379066237, 52.774240013645766],
            [-86.306263654863869, 52.774238037854865],
            [-86.306292687777102, 52.773980338434733],
            [-86.3064754614485, 52.773539910403265],
            [-86.306476634315885, 52.773535333356065],
            [-86.306534646114798, 52.773061548986703],
            [-86.306618076556603, 52.772572633366487],
            [-86.30661836098534, 52.772570118326925],
            [-86.306618156526326, 52.772567600430165],
            [-86.306550274587067, 52.772078070067309],
            [-86.306509638141918, 52.77146628055263],
            [-86.306491439981755, 52.771042384099083],
            [-86.306592778485296, 52.770849282290669],
            [-86.306824819804177, 52.770499472158562],
            [-86.306826076134641, 52.770497322370652],
            [-86.307034534972033, 52.77008526611025],
            [-86.307035296802383, 52.77008347294246],
            [-86.307193278244512, 52.769671121477927],
            [-86.307194180821639, 52.769668160673071],
            [-86.307276231865828, 52.76924215316879],
            [-86.307458252403919, 52.768847288811948],
            [-86.307458378803673, 52.768847019900733],
            [-86.307693559306301, 52.768313598809343],
            [-86.307900768017717, 52.767948361814476],
            [-86.307903225286367, 52.767939837431562],
            [-86.307901629886445, 52.76793254772663],
            [-86.307731109516325, 52.767548928167344],
            [-86.307728850287319, 52.767544960173311],
            [-86.30745702735905, 52.767176028847004],
            [-86.30745510778921, 52.767173770650544],
            [-86.307448108296001, 52.767168157148539],
            [-86.307103786436016, 52.766955105323625],
            [-86.306912381165702, 52.766702139812317],
            [-86.306867641756654, 52.766417943343299],
            [-86.306924972853125, 52.765963029401121],
            [-86.307056763972668, 52.765567512506081],
            [-86.307057700714239, 52.765562394706279],
            [-86.307051926904521, 52.765548878776343],
            [-86.307041899953489, 52.765541539871499],
            [-86.306741517861425, 52.765386806908467],
            [-86.306735327053303, 52.765384164165106],
            [-86.306720104912301, 52.765380749558815],
            [-86.306251084324998, 52.765333067114831],
            [-86.305883080449618, 52.7651810823739],
            [-86.305880650806657, 52.765180169348866],
            [-86.305863719416408, 52.765176654769839],
            [-86.305435777756301, 52.765143500594405],
            [-86.30490726468966, 52.765094565695584],
            [-86.304900914623488, 52.765094258840662],
            [-86.304889903836582, 52.765095003274375],
            [-86.304466666465487, 52.765157866388961],
            [-86.305082771685051, 52.764656163905371],
            [-86.305085376509822, 52.764653752383666],
            [-86.305085872462016, 52.764653305915822],
            [-86.305906858429069, 52.763770263984696],
            [-86.305912564422925, 52.763757264595185],
            [-86.305909902504382, 52.763747901348474],
            [-86.305565894481887, 52.763163906275885],
            [-86.305562783253336, 52.76315975352567],
            [-86.305559772774899, 52.763156949611847],
            [-86.305011836529275, 52.762709906601053],
            [-86.305001546060481, 52.762703824295237],
            [-86.304124555854187, 52.762331877325209],
            [-86.304121640409093, 52.762330781626048],
            [-86.304115802445793, 52.762329039622976],
            [-86.303286738970058, 52.76212499635222],
            [-86.303270395553696, 52.762123012821796],
            [-86.303250434053439, 52.762125681941555],
            [-86.302743467239054, 52.762275681489513],
            [-86.302741132652386, 52.762276476716302],
            [-86.302734367717477, 52.76227922324756],
            [-86.302047403981334, 52.762627249059456],
            [-86.302043332873609, 52.762629651888737],
            [-86.300819300335618, 52.763433623844762],
            [-86.300817447330175, 52.76343487124516],
            [-86.300000424468493, 52.764041875816311],
            [-86.299996830287313, 52.764045000475811],
            [-86.299585851687937, 52.764456029168542],
            [-86.299585602963575, 52.764456297349454],
            [-86.29958160760178, 52.764461576758954],
            [-86.299165956671558, 52.765224012612492],
            [-86.298674906848049, 52.766015572173082],
            [-86.297902489472094, 52.767035740927447],
            [-86.297902115715743, 52.767036188088476],
            [-86.297153226055741, 52.76807302926111],
            [-86.296147378728904, 52.769428948170265],
            [-86.296147256516036, 52.769428947445213],
            [-86.294238289136985, 52.772019940685475],
            [-86.294236530091084, 52.772022896298083],
            [-86.294001423154455, 52.77247989212416],
            [-86.293999341204781, 52.772487699431686],
            [-86.293999428440458, 52.77248985707979],
            [-86.29404444610141, 52.772872929400172],
            [-86.294044559601176, 52.772873469309872],
            [-86.294134948449482, 52.773481874290979],
            [-86.293952690771548, 52.773920489013044],
            [-86.29395205065147, 52.773922282821459],
            [-86.293819416827489, 52.774376023051936],
            [-86.293539754403014, 52.774692268172579],
            [-86.293539632175481, 52.774692267444792],
            [-86.293231547882712, 52.775042317795673],
            [-86.293230800044711, 52.775043212149576],
            [-86.292536254264164, 52.775909572716017],
            [-86.29123200588046, 52.777095052162338],
            [-86.29123064086528, 52.777096302327372],
            [-86.291229645471915, 52.777097374976989],
            [-86.289686663696571, 52.778863212135434],
            [-86.286987932463759, 52.781815891709925],
            [-86.286987308007539, 52.781816696840025],
            [-86.285185391508136, 52.783948712542028],
            [-86.285180426370388, 52.783960726706539],
          ],
        ],
      },
    },
  ],
};

export const eaglesNest = {
  type: "FeatureCollection",
  name: "Lease_w_no_LUP",
  crs: { type: "name", properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" } },
  features: [
    {
      type: "Feature",
      properties: { Rights: "Mining" },
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [-86.225554202540195, 52.78018195022721],
            [-86.225761803935114, 52.776588388983726],
            [-86.225969865972331, 52.772998514268878],
            [-86.225994696951588, 52.772568864577458],
            [-86.225996105002167, 52.772568873763348],
            [-86.231859557934669, 52.772692206134145],
            [-86.237725863044759, 52.772815266938395],
            [-86.243557276831254, 52.772937365615313],
            [-86.249373597397195, 52.773058812360183],
            [-86.255297029363135, 52.773182264442255],
            [-86.261220217662697, 52.773305329236003],
            [-86.267143411060289, 52.773428188167912],
            [-86.273066612484854, 52.773550661405572],
            [-86.273274088082331, 52.76989921733832],
            [-86.273495000907801, 52.766008090238763],
            [-86.273572903408962, 52.762577450773584],
            [-86.273652375534027, 52.759113915569486],
            [-86.273731846230135, 52.755657570303143],
            [-86.273822076946317, 52.751736775619442],
            [-86.273787378485409, 52.74812366061122],
            [-86.273753078295229, 52.744520614626161],
            [-86.273720217920498, 52.740915777317653],
            [-86.273687951486707, 52.737297636203657],
            [-86.279680664738592, 52.737308022688325],
            [-86.285714469502949, 52.737318174105027],
            [-86.291703728538337, 52.737327932236987],
            [-86.29764077027427, 52.73733725853149],
            [-86.29765454851767, 52.733732255190098],
            [-86.297667112537624, 52.730106655262276],
            [-86.297679155095963, 52.726760190459487],
            [-86.297692261512708, 52.723151850463438],
            [-86.297757225853005, 52.719471625348596],
            [-86.297820272704058, 52.71589243472917],
            [-86.297884302298044, 52.712258588316189],
            [-86.29794579040464, 52.708710309924555],
            [-86.304014044053275, 52.708736195764224],
            [-86.309973845012564, 52.708761499610574],
            [-86.315838696441247, 52.708785955965801],
            [-86.321813643719125, 52.708810662098138],
            [-86.327649979486878, 52.708781329820319],
            [-86.333486318263624, 52.70875117091613],
            [-86.334709211304997, 52.708744823449742],
            [-86.336407054778419, 52.708735908176322],
            [-86.336513324714687, 52.708735334157126],
            [-86.337692015008301, 52.708729248003614],
            [-86.337940895264467, 52.708727940907515],
            [-86.339570272265362, 52.708719497097078],
            [-86.339745078974929, 52.708718582687773],
            [-86.345169897662998, 52.708690321138832],
            [-86.345218357318444, 52.712356631817677],
            [-86.345266680991074, 52.716024467690538],
            [-86.345312917460646, 52.719692649389586],
            [-86.345359137447105, 52.723367211544534],
            [-86.345285570190484, 52.727316384065723],
            [-86.345227358447232, 52.730443143988978],
            [-86.345157861924861, 52.734162190472887],
            [-86.34509419691561, 52.737619658231871],
            [-86.34498671362978, 52.741214788352572],
            [-86.344878756168484, 52.744809553994955],
            [-86.344770576382857, 52.748403956677599],
            [-86.344664607128237, 52.751970140807444],
            [-86.338808197884688, 52.751964701504996],
            [-86.332924099194017, 52.751959087048895],
            [-86.327499971903876, 52.751953541819873],
            [-86.321994598458232, 52.751947553903527],
            [-86.321493299005567, 52.75194684517944],
            [-86.321078699881951, 52.752804184288607],
            [-86.319775566012012, 52.755498745139342],
            [-86.320161461993166, 52.75905505157408],
            [-86.32055206994815, 52.762648599821063],
            [-86.320942665713218, 52.766239267673974],
            [-86.320819766859117, 52.770051202773438],
            [-86.320697965850101, 52.773828170746384],
            [-86.320574702532895, 52.777649897765023],
            [-86.320451285798356, 52.781475127517055],
            [-86.314682823044478, 52.781201469844717],
            [-86.308605526584998, 52.780912876796855],
            [-86.305437523615979, 52.78076231239848],
            [-86.30175941111905, 52.780587356404745],
            [-86.301703796646876, 52.780584691693825],
            [-86.299900143608212, 52.780498907197654],
            [-86.299809330596474, 52.780494505686065],
            [-86.296918576110585, 52.780357575712053],
            [-86.296916907858801, 52.780439014732757],
            [-86.296908937995596, 52.780829580600134],
            [-86.296908074873031, 52.780872097965727],
            [-86.296886798172153, 52.781943842910259],
            [-86.296886076425295, 52.781980337761553],
            [-86.296849289109829, 52.783833036755951],
            [-86.296777171426484, 52.787464813918064],
            [-86.29670511719705, 52.791091734678197],
            [-86.29662836832388, 52.794954521052013],
            [-86.290529265523645, 52.794930979854506],
            [-86.284428942998701, 52.794907027937867],
            [-86.278338018670809, 52.794882729556122],
            [-86.278289495255208, 52.794882524387511],
            [-86.276590217279093, 52.794875773747819],
            [-86.276499294958228, 52.794875399237299],
            [-86.273060362436169, 52.794861485091687],
            [-86.273083877196726, 52.797050318601784],
            [-86.273087394049767, 52.797378472726216],
            [-86.273087123407947, 52.797382246846844],
            [-86.272994904316391, 52.798656544112326],
            [-86.272753771452059, 52.802030245390036],
            [-86.270007459105869, 52.802006282425658],
            [-86.26850284744323, 52.801993152313258],
            [-86.267954077025735, 52.801988420077571],
            [-86.26587983008865, 52.801970303204818],
            [-86.265758037250578, 52.801969190178774],
            [-86.264100014432586, 52.801954695366412],
            [-86.263227125856702, 52.801947032026476],
            [-86.260855119408035, 52.801926169321881],
            [-86.255013743678731, 52.801874721079365],
            [-86.248995006563604, 52.801821323733293],
            [-86.243054864723277, 52.801760480852479],
            [-86.237149980848102, 52.801699657150991],
            [-86.231210423767493, 52.8016384048967],
            [-86.225268539779023, 52.801577019907185],
            [-86.225345116192528, 52.7981041606701],
            [-86.22542141686435, 52.79462761174441],
            [-86.225455295289834, 52.790952015671579],
            [-86.225488077908352, 52.78737853634842],
            [-86.225521436772993, 52.783717855801491],
            [-86.225554202540195, 52.78018195022721],
          ],
          [
            [-86.285180426370388, 52.783960726706539],
            [-86.285184996808383, 52.78397279831556],
            [-86.285306974525696, 52.784124533418769],
            [-86.285403013975298, 52.784337141503933],
            [-86.285407192669055, 52.784343278600325],
            [-86.285423412531998, 52.784353263165478],
            [-86.285445603489876, 52.784356992096136],
            [-86.285465577707583, 52.784354236168816],
            [-86.286158992574769, 52.784149166399594],
            [-86.289123464825053, 52.784767549374791],
            [-86.289137501984072, 52.784768981713313],
            [-86.289159810646865, 52.78476552012922],
            [-86.289176353737119, 52.784755732286122],
            [-86.289179973752468, 52.784751170072887],
            [-86.289373096059933, 52.784424440398773],
            [-86.291176176335767, 52.782156738068259],
            [-86.291177930162576, 52.782154141983639],
            [-86.291746182454432, 52.781197963879372],
            [-86.292369186412529, 52.780768903125789],
            [-86.29356447565614, 52.780639855696798],
            [-86.29454804180827, 52.78059986816811],
            [-86.294563605779217, 52.780597533864032],
            [-86.295374602462275, 52.780372526657523],
            [-86.295377920455948, 52.780371467766621],
            [-86.295394460393467, 52.780361679038329],
            [-86.295398836076657, 52.780355683016722],
            [-86.295607183661289, 52.779929894637569],
            [-86.295993638077363, 52.779353083816183],
            [-86.296558716500186, 52.778668304912934],
            [-86.297330503871166, 52.777770470906134],
            [-86.297332001101466, 52.777768592295175],
            [-86.297847668938616, 52.777037135901381],
            [-86.29859521279991, 52.776092681449583],
            [-86.298597214736546, 52.776089817072247],
            [-86.298907465556212, 52.775541847352002],
            [-86.299364132634366, 52.774918791838523],
            [-86.29955534368932, 52.775099593414616],
            [-86.299561020458782, 52.775103851320956],
            [-86.299736040797029, 52.775212022865134],
            [-86.299745260891711, 52.775216391600928],
            [-86.299752439059716, 52.775218501231876],
            [-86.30010444650955, 52.775297157051469],
            [-86.30010590893761, 52.775297435327161],
            [-86.300658982251974, 52.775407745088529],
            [-86.301539015598223, 52.77559646642537],
            [-86.302570250991621, 52.775816809486287],
            [-86.30257220085042, 52.775817180458333],
            [-86.303174182985117, 52.775927496350398],
            [-86.304303348166812, 52.776209248950074],
            [-86.304319941276347, 52.776211233720595],
            [-86.304799448729312, 52.77621404428703],
            [-86.304821628222228, 52.77621066887199],
            [-86.305176945218022, 52.776090242156862],
            [-86.305177068880639, 52.776090153019403],
            [-86.305187784348803, 52.776085092507685],
            [-86.305468290063388, 52.775903107601209],
            [-86.305473242166414, 52.775899181847038],
            [-86.305729541681359, 52.775655755575734],
            [-86.3057304101645, 52.775654951775167],
            [-86.305732029192242, 52.775653073707481],
            [-86.30598954227554, 52.775333165174125],
            [-86.305993707272762, 52.775324920477239],
            [-86.306047952757538, 52.775080760891576],
            [-86.306154866264379, 52.774699211349137],
            [-86.306155120724569, 52.774698583614111],
            [-86.306263379066237, 52.774240013645766],
            [-86.306263654863869, 52.774238037854865],
            [-86.306292687777102, 52.773980338434733],
            [-86.3064754614485, 52.773539910403265],
            [-86.306476634315885, 52.773535333356065],
            [-86.306534646114798, 52.773061548986703],
            [-86.306618076556603, 52.772572633366487],
            [-86.30661836098534, 52.772570118326925],
            [-86.306618156526326, 52.772567600430165],
            [-86.306550274587067, 52.772078070067309],
            [-86.306509638141918, 52.77146628055263],
            [-86.306491439981755, 52.771042384099083],
            [-86.306592778485296, 52.770849282290669],
            [-86.306824819804177, 52.770499472158562],
            [-86.306826076134641, 52.770497322370652],
            [-86.307034534972033, 52.77008526611025],
            [-86.307035296802383, 52.77008347294246],
            [-86.307193278244512, 52.769671121477927],
            [-86.307194180821639, 52.769668160673071],
            [-86.307276231865828, 52.76924215316879],
            [-86.307458252403919, 52.768847288811948],
            [-86.307458378803673, 52.768847019900733],
            [-86.307693559306301, 52.768313598809343],
            [-86.307900768017717, 52.767948361814476],
            [-86.307903225286367, 52.767939837431562],
            [-86.307901629886445, 52.76793254772663],
            [-86.307731109516325, 52.767548928167344],
            [-86.307728850287319, 52.767544960173311],
            [-86.30745702735905, 52.767176028847004],
            [-86.30745510778921, 52.767173770650544],
            [-86.307448108296001, 52.767168157148539],
            [-86.307103786436016, 52.766955105323625],
            [-86.306912381165702, 52.766702139812317],
            [-86.306867641756654, 52.766417943343299],
            [-86.306924972853125, 52.765963029401121],
            [-86.307056763972668, 52.765567512506081],
            [-86.307057700714239, 52.765562394706279],
            [-86.307051926904521, 52.765548878776343],
            [-86.307041899953489, 52.765541539871499],
            [-86.306741517861425, 52.765386806908467],
            [-86.306735327053303, 52.765384164165106],
            [-86.306720104912301, 52.765380749558815],
            [-86.306251084324998, 52.765333067114831],
            [-86.305883080449618, 52.7651810823739],
            [-86.305880650806657, 52.765180169348866],
            [-86.305863719416408, 52.765176654769839],
            [-86.305435777756301, 52.765143500594405],
            [-86.30490726468966, 52.765094565695584],
            [-86.304900914623488, 52.765094258840662],
            [-86.304889903836582, 52.765095003274375],
            [-86.304466666465487, 52.765157866388961],
            [-86.305082771685051, 52.764656163905371],
            [-86.305085376509822, 52.764653752383666],
            [-86.305085872462016, 52.764653305915822],
            [-86.305906858429069, 52.763770263984696],
            [-86.305912564422925, 52.763757264595185],
            [-86.305909902504382, 52.763747901348474],
            [-86.305565894481887, 52.763163906275885],
            [-86.305562783253336, 52.76315975352567],
            [-86.305559772774899, 52.763156949611847],
            [-86.305011836529275, 52.762709906601053],
            [-86.305001546060481, 52.762703824295237],
            [-86.304124555854187, 52.762331877325209],
            [-86.304121640409093, 52.762330781626048],
            [-86.304115802445793, 52.762329039622976],
            [-86.303286738970058, 52.76212499635222],
            [-86.303270395553696, 52.762123012821796],
            [-86.303250434053439, 52.762125681941555],
            [-86.302743467239054, 52.762275681489513],
            [-86.302741132652386, 52.762276476716302],
            [-86.302734367717477, 52.76227922324756],
            [-86.302047403981334, 52.762627249059456],
            [-86.302043332873609, 52.762629651888737],
            [-86.300819300335618, 52.763433623844762],
            [-86.300817447330175, 52.76343487124516],
            [-86.300000424468493, 52.764041875816311],
            [-86.299996830287313, 52.764045000475811],
            [-86.299585851687937, 52.764456029168542],
            [-86.299585602963575, 52.764456297349454],
            [-86.29958160760178, 52.764461576758954],
            [-86.299165956671558, 52.765224012612492],
            [-86.298674906848049, 52.766015572173082],
            [-86.297902489472094, 52.767035740927447],
            [-86.297902115715743, 52.767036188088476],
            [-86.297153226055741, 52.76807302926111],
            [-86.296147378728904, 52.769428948170265],
            [-86.296147256516036, 52.769428947445213],
            [-86.294238289136985, 52.772019940685475],
            [-86.294236530091084, 52.772022896298083],
            [-86.294001423154455, 52.77247989212416],
            [-86.293999341204781, 52.772487699431686],
            [-86.293999428440458, 52.77248985707979],
            [-86.29404444610141, 52.772872929400172],
            [-86.294044559601176, 52.772873469309872],
            [-86.294134948449482, 52.773481874290979],
            [-86.293952690771548, 52.773920489013044],
            [-86.29395205065147, 52.773922282821459],
            [-86.293819416827489, 52.774376023051936],
            [-86.293539754403014, 52.774692268172579],
            [-86.293539632175481, 52.774692267444792],
            [-86.293231547882712, 52.775042317795673],
            [-86.293230800044711, 52.775043212149576],
            [-86.292536254264164, 52.775909572716017],
            [-86.29123200588046, 52.777095052162338],
            [-86.29123064086528, 52.777096302327372],
            [-86.291229645471915, 52.777097374976989],
            [-86.289686663696571, 52.778863212135434],
            [-86.286987932463759, 52.781815891709925],
            [-86.286987308007539, 52.781816696840025],
            [-86.285185391508136, 52.783948712542028],
            [-86.285180426370388, 52.783960726706539],
          ],
        ],
      },
    },
  ],
};
