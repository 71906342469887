import { Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React from "react";
import { WylooMap } from "./wyloo-map";
import "../wyloo/wyloo-microsite.scss";
import { WylooMenu } from "./wyloo-menu";
import { wylooTheme } from "./wyloo-theme";
import { useSelector } from "react-redux";
import { Dimmer } from "tabler-react";
import PageNotFound from "components/PageNotFound";
export function WylooMicrositeHome(props) {
  var portal = useSelector((state) => state.portal);
  const getMapCard = () => {
    return <WylooMap />;
  };

  return (
    <ThemeProvider theme={wylooTheme}>
      {portal.isLoading || (!portal.isLoading && portal.isEnabled) ? (
        <div className="wyloo-microsite-container wyloo-colors ">
          <Dimmer active={portal.isLoading} loader>
            <div
              className="header-container-wyloo"
              style={{
                backgroundImage: `url(
                "https://wyloo.com/wp-content/uploads/2023/12/Eagles-nest-bg.png"
              )`,
              }}
            >
              <div className="wyloo-header-text">
                <Typography variant="h2">{portal.name}</Typography>
              </div>
            </div>
            <Container maxWidth="xl">
              <div className="wyloo-text-container  wyloo-colors">
                <>
                  <div className="flex-column d-flex wyloo-text-container">
                    <div className="wyloo-text-section mb-2">
                      <Typography variant="h4" sx={{ width: "fit-content" }}>
                        Welcome to the {portal.name}.
                      </Typography>
                    </div>
                    <WylooMenu {...props} />

                    <div className="d-flex flex-row w-100">
                      <div className="wyloo-text-section mr-5">
                        <Typography sx={{ width: "fit-content" }}>
                          Lorem ipsum odor amet, consectetuer adipiscing elit.
                          Pharetra elit dignissim leo, massa vivamus fusce ac.
                          Fusce et facilisi venenatis elit sed penatibus fames.
                          Hac penatibus parturient scelerisque tristique taciti
                          fames nisl volutpat. Fermentum dui fringilla quam
                          aliquam viverra. Pulvinar tincidunt bibendum quam
                          velit efficitur cras tempor. Malesuada rhoncus lectus
                          vestibulum quis mus, orci purus tellus himenaeos.
                          Ultrices arcu interdum ac non lectus convallis sit
                          porttitor commodo. Volutpat velit metus lobortis
                          feugiat malesuada.
                        </Typography>
                      </div>
                      <div className="wyloo-text-section ml-5">
                        <Typography sx={{ width: "fit-content" }}>
                          Lorem ipsum odor amet, consectetuer adipiscing elit.
                          Pharetra elit dignissim leo, massa vivamus fusce ac.
                          Fusce et facilisi venenatis elit sed penatibus fames.
                          Hac penatibus parturient scelerisque tristique taciti
                          fames nisl volutpat. Fermentum dui fringilla quam
                          aliquam viverra. Pulvinar tincidunt bibendum quam
                          velit efficitur cras tempor. Malesuada rhoncus lectus
                          vestibulum quis mus, orci purus tellus himenaeos.
                          Ultrices arcu interdum ac non lectus convallis sit
                          porttitor commodo. Volutpat velit metus lobortis
                          feugiat malesuada.
                        </Typography>
                      </div>
                    </div>
                    <div className="d-flex flex-row w-100">
                      <div className="wyloo-text-section mt-5">
                        <Typography sx={{ width: "fit-content" }}>
                          Lorem ipsum odor amet, consectetuer adipiscing elit.
                          Pharetra elit dignissim leo, massa vivamus fusce ac.
                          Fusce et facilisi venenatis elit sed penatibus fames.
                          Hac penatibus parturient scelerisque tristique taciti
                          fames nisl volutpat. Fermentum dui fringilla quam
                          aliquam viverra. Pulvinar tincidunt bibendum quam
                          velit efficitur cras tempor. Malesuada rhoncus lectus
                          vestibulum quis mus, orci purus tellus himenaeos.
                          Ultrices arcu interdum ac non lectus convallis sit
                          porttitor commodo. Volutpat velit metus lobortis
                          feugiat malesuada.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </>
              </div>
            </Container>
            <Container maxWidth="xl" className="mb-5">
              {!portal.isLoading && getMapCard()}
            </Container>
          </Dimmer>
        </div>
      ) : (
        <div className="content-wrapper">
          <PageNotFound />
        </div>
      )}
    </ThemeProvider>
  );
}
