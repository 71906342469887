import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { getAssetExportAsXLSX, getAssets } from "../../../services/assets";

import _ from "lodash";

import lynxColors from "../../../modules/lynxColors";
import { UserRoles } from "../../../types/enums";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { roleMatch } from "../../../actions/auth";
import { AssetMobileCard } from "./asset-mobile-card";

export function AssetList(props) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let defaultColumns = [
      {
        field: "assetNumber",
        headerName: "Asset #",
        width: 90,
        type: "string",
        renderCell: (params) => (
          <>
            {roleMatch([UserRoles.MshaManagedUser]) ? (
              <div>{params.value}</div>
            ) : (
              <Button
                variant="text"
                className="event-number-button"
                onClick={() => handleAssetNumberClick(params.row)}
              >
                {params.value}
              </Button>
            )}
          </>
        ),
      },
      {
        field: "name",
        headerName: "Name",
        width: 250,
        type: "string",
      },
      {
        field: "status",
        headerName: "Status",
        width: 100,
        type: "singleSelect",
        valueOptions: ["Green", "Yellow", "Red"],
        renderCell: (params) =>
          _.isEmpty(params.value) ? (
            <div></div>
          ) : (
            <div>
              <i
                className={"fe fe-circle "}
                style={{ color: statusColorSwitch(params.value) }}
              />
              &nbsp;
              {params.value}
            </div>
          ),
      },

      {
        field: "assetType",
        headerName: "Type",
        width: 150,
        type: "singleSelect",

        lookupType: "assetType",
      },
    ];

    setColumns([...defaultColumns]);
  }, []);
  const handleAssetNumberClick = (row) => {
    props.history.push(`/assets/${row.id}`);
  };
  return (
    <>
      <LynxDataGrid
        columns={columns}
        getDataFunction={getAssets}
        localStorageName="assets"
        mobileCard={AssetMobileCard}
        entityName={"Asset"}
        disableAddButton={roleMatch([UserRoles.MshaManagedUser])}
        handleEventsExportXLSX={getAssetExportAsXLSX}
        addButtonAction={() => {
          props.history.push(`/add-asset`);
        }}
        {...props}
      />
    </>
  );
}
export const statusColorSwitch = (type) => {
  switch (type) {
    case "Green":
      return lynxColors.success;
    case "Yellow":
      return lynxColors.warning;
    case "Red":
      return lynxColors.error;
    default:
      return "";
  }
};
