import React from "react";
import { getAuditLogs } from "../../../services/auditLogs";

import { columns } from "./columns";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";

export function AuditLogList(props) {
  return (
    <>
      <LynxDataGrid
        columns={columns}
        getDataFunction={getAuditLogs}
        entityName={"Audit Log"}
        localStorageName="auditLog"
        {...props}
        title={"Audit Log"}
      />
    </>
  );
}
