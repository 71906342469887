import React from "react";
import { Card } from "tabler-react";
import { dateUtil } from "../../../services/date-util";
import { getPhotoImportHistories } from "../../../services/time-series-service";
import { LookupTypes, PhotoImportHistoryStatuses } from "../../../types/enums";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import _ from "lodash";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
export function PhotoImportHistoryModal(props) {
  const history = useHistory();
  const handleNavigateTo = (url) => {
    history.push(url);
  };

  const photoImportHistoryColumns = [
    {
      field: "importDateTimeUtc",
      headerName: "Import Date",
      width: 200,
      type: "dateTime",
      valueGetter: (params) => {
        return dateUtil.convertDateTimeToLocal(params.value);
      },
    },
    {
      field: "importerUserFullName",
      headerName: "Imported By",
      width: 200,
      type: "singleSelect",
      lookupType: LookupTypes.Users,
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      type: "string",
      valueGetter: (value) => {
        value = value.value;
        if (value == PhotoImportHistoryStatuses.AnalyzingPhotos) {
          return "Analyzing Photos";
        }
        if (value == PhotoImportHistoryStatuses.StartingModel) {
          return "Starting Model";
        }
        if (value == PhotoImportHistoryStatuses.UploadingPhotos) {
          return "Uploading Photos";
        }
        if (value == PhotoImportHistoryStatuses.AwaitingReview) {
          return "Awaiting Review";
        }
        if (value == PhotoImportHistoryStatuses.AwaitingModelTraining) {
          return "Awaiting Model Training";
        }
        return value;
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 280,
      type: "string",
      renderCell: (params) => (
        <>
          {params.row.status ===
            PhotoImportHistoryStatuses.AwaitingModelTraining && (
            <Button
              variant="outlined"
              onClick={(e) => {
                handleNavigateTo(`/photo-import-history/${params.id}/training`);
              }}
            >
              Train Model
            </Button>
          )}
          {params.row.status === PhotoImportHistoryStatuses.AwaitingReview && (
            <Button
              variant="outlined"
              onClick={(e) => {
                handleNavigateTo(`/photo-import-history/${params.id}`);
              }}
            >
              Review Analyzed Photos
            </Button>
          )}
        </>
      ),
    },
    //{
    //  field: "View Imported Data",
    //  headerName: "",
    //  width: 200,
    //  type: "string",
    //  resizable: false,
    //  disableColumnMenu: true,
    //  disableExport: true,
    //  hideSortIcons: true,
    //  renderCell: (params) => (
    //    <>
    //       <Button
    //          variant="outlined"
    //          onClick={(e) => {
    //            props.handleViewSelectedImportHistory(params.id);
    //          }}
    //        >
    //          View Imported Data
    //        </Button>
    //    </>
    //  ),
    //}
  ];

  return (
    <Card>
      <div style={{ height: 550, minWidth: 500 }}>
        <LynxDataGrid
          columns={photoImportHistoryColumns}
          getDataFunction={getPhotoImportHistories}
          onlyShowTable
          hasNoLookups
          pageSize={10}
          entityName="photoImportHistory"
          localStorageName={`photoImportHistory`}
          {...props}
        />
      </div>
    </Card>
  );
}
