import * as React from "react";

import _ from "lodash";
import { dateUtil } from "../../services/date-util";
import lynxColors from "../../modules/lynxColors";
export const submissionColumns = [
  {
    field: "submissionNumber",
    headerName: "Submission #",
    width: 100,
    type: "string",
  },

  {
    field: "status",
    headerName: "Status",
    width: 200,
    type: "string",
    valueOptions: ["Pending Review", "Closed"],
    renderCell: (params) => (
      <div>
        <i
          className={"fe fe-circle "}
          style={{ color: statusColorSwitch(params.row) }}
        />
        &nbsp;
        {params.value}
      </div>
    ),
  },
  {
    field: "submittedDateTimeUtc",
    headerName: "Submission Date Time",
    width: 200,
    type: "dateTime",
    valueGetter: (params) => {
      return dateUtil.convertDateTimeToLocal(params.value);
    },
  },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    type: "string",
  },
  {
    field: "contactDetails",
    headerName: "Contact Details",
    width: 200,
    type: "string",
  },
  {
    field: "submissionDetails",
    headerName: "Submission Details",
    width: 300,
    type: "string",
  },
];

export const statusColorSwitch = (row) => {
  switch (_.toLower(row.status)) {
    case "pending review":
      return lynxColors.harvestOrange;

    default:
      return lynxColors.primary;
  }
};
