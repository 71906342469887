import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import { Card, Form, Grid } from "tabler-react";
import { getActionCard } from "../events/event-details-actions";
import "./incident-form.css";
export function IncidentActions(props) {
  const history = useHistory();
  const navigateToAddActions = () => {
    history.push(`/incidents/${props.incident.id}/add-action`);
  };
  const handleActionNumberClick = (e, action) => {
    e.preventDefault();
    history.push(`/incidents/${props.incident.id}/actions/${action.id}`);
  };
  return (
    <Container className="mt-2" maxWidth="xl">
      <Form className="card mb-0">
        <Card.Header>
          <Card.Title>Actions</Card.Title>
          <Button onClick={navigateToAddActions} className="ml-auto">
            Add Linked Action
          </Button>
        </Card.Header>
        <Card.Body>
          <Grid.Row>
            <Grid.Col md={6} width={12}>
              {_.isEmpty(props.incident.actions) ? (
                <div>No linked actions</div>
              ) : (
                <div className="compliance-grid-container">
                  {props.incident.actions.map((action) => (
                    <div className="dashboard-item">
                      {getActionCard(handleActionNumberClick, action)}
                    </div>
                  ))}
                </div>
              )}
            </Grid.Col>
          </Grid.Row>
        </Card.Body>
      </Form>
    </Container>
  );
}
