import { apiUrl, client } from "../modules/store";

export function getMonitoringLocationTimeSeries(params) {
  return client.get(apiUrl + "/monitoringLocationTimeSeries", {
    params: params,
  });
}

export function getPhotoImportConfigurationSupportedLabels() {
  return client.get(apiUrl + "/photoImportConfigurations/supportedLabels");
}

export function getPhotoImportHistories(params) {
  return client.get(apiUrl + "/photoimporthistories", {
    params: params,
  });
}

export function getPhotoImportHistory(id) {
  return client.get(apiUrl + "/photoimporthistories/" + id);
}

export function sendPhotoForTraining(importHistoryId, filePath, dto, label) {
  return client.post(
    apiUrl + `/photoimporthistories/${importHistoryId}/submitTraining`,
    {
      filePath: filePath,
      groundTruth: JSON.stringify(dto),
      label: label,
    }
  );
}

export function skipPhotoForTraining(importHistoryId, filePath) {
  return client.post(
    apiUrl + `/photoimporthistories/${importHistoryId}/skipTraining`,
    filePath
  );
}

export function getPhotoImportHistoryResults(id) {
  return client.get(apiUrl + "/photoimporthistories/" + id + "/results");
}
export function submitTimeseriesReview(importHistoryId, sequences) {
  return client.post(
    `${apiUrl}/monitoringLocationTimeSeries/${importHistoryId}/submitReview`,
    sequences
  );
}

export function downloadImage(filePath) {
  return client
    .get(
      apiUrl +
        "/monitoringLocationTimeSeries/downloadImage?filePath=" +
        filePath,
      {
        responseType: "blob",
      }
    )
    .then((res) => res);
}

export function analyzeMonitoringLocationFiles(attachments, id) {
  let form = new FormData();

  for (var index = 0; index < attachments.length; index++) {
    var element = attachments[index];
    form.append("file", element);
  }

  return client.post(
    apiUrl +
      "/monitoringLocationTimeSeries/analyzeMonitoringLocationFiles?monitoringLocationId=" +
      id,
    form
  );
}

export function uploadTimeseriesPhotos(attachments, id, isFinalUpload, guid) {
  let form = new FormData();

  for (var index = 0; index < attachments.length; index++) {
    var element = attachments[index];
    form.append("file", element);
  }

  return client.post(
    apiUrl +
      `/monitoringLocationTimeSeries/uploadPhotos?monitoringLocationId=${id}&isFinalUpload=${isFinalUpload}&guid=${guid}`,
    form
  );
}
