import { getParameters } from "./../../../services/parameters";

export const monitoringVarianceLimitColumns = [
  {
    field: "edit",
    headerName: "Edit / Delete",
    width: 100,
    type: "actions",
    sortable: false,
  },
  {
    field: "parameterName",
    headerName: "Parameter",
    width: 250,
    type: "singleSelect",
    lookupFunction: getParameters,
    valueField: "id",
    labelField: "name",
    importable: true,
  },
  {
    field: "varianceLimit",
    headerName: "Allowance",
    width: 150,
    type: "string",
    importable: true,
  },
];
