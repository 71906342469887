import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { CircularProgress, Typography } from "@mui/material";
import lynxColors from "../../../../modules/lynxColors";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Dimmer } from "tabler-react";
export function BadgeWidget(props) {
  return (
    <Card
      className={`${props.className} lynx-card `}
      variant="outlined"
      style={{
        width: "fit-content",
        display: "inline-block",
        minHeight: 120,
      }}
    >
      <CardContent className="pt-1 pb-1">
        <Dimmer active={props.isLoading} loader>
          <Typography variant="subtitle2">{props.title}</Typography>
          <Typography component={"span"} variant="h3">
            {props.value}
          </Typography>
          <Typography className="ml-1" component={"span"} variant="subtitle1">
            {props.unit}
          </Typography>
          {props.subValue && (
            <div className="d-flex" style={{ color: props.subValueColor }}>
              <Typography
                className="ml-1"
                component={"span"}
                variant="subtitle1"
              >
                {props.subValue}
              </Typography>
              {props.arrow == 1 && <ArrowUpwardIcon />}
              {props.arrow == 2 && <ArrowDownwardIcon />}
            </div>
          )}
        </Dimmer>
      </CardContent>
    </Card>
  );
}
