import React from "react";
import { Card, Form, Grid } from "tabler-react";
import { LocationSelectionControl } from "./../../location-selection-control";
import Button from "@mui/material/Button";

import PrintIcon from "@mui/icons-material/Print";

export function getIncidentLocationSection(
  handleInputChange,
  formState,
  assetLookups,
  defaultLatitude,
  defaultLongitude,
  handleLatLongChange,
  setLocationTouched,
  selectedAssetGeojson,
  isDisabled,
  isExistingIncident
) {
  return (
    <Form className="card mb-0">
      {isExistingIncident && (
        <Card.Header>
          <Card.Title>Initial Report</Card.Title>
          <Button
            variant="outlined"
            onClick={() => {
              window.print();
            }}
            className="ml-5"
            startIcon={<PrintIcon />}
          >
            Print
          </Button>
        </Card.Header>
      )}

      <Card.Header>
        <Card.Title>Location</Card.Title>
      </Card.Header>
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={12} width={12}>
            <Form.Group isRequired label="Asset">
              <Form.Select
                name="assetId"
                onChange={handleInputChange}
                value={formState.assetId}
                error={formState.assetIdError}
                disabled={isDisabled}
              >
                <option value={""}></option>
                {assetLookups.map((asset) => (
                  <option value={asset.id} key={asset.id}>
                    {asset.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={12} width={12}>
            <Form.Group isRequired label="Location Details">
              <Form.Textarea
                name="locationDetails"
                disabled={isDisabled}
                onChange={handleInputChange}
                value={formState.locationDetails}
                error={formState.locationDetailsError}
              ></Form.Textarea>
            </Form.Group>
          </Grid.Col>

          <LocationSelectionControl
            isRequired
            isMapExpandable
            defaultLatitude={defaultLatitude}
            defaultLongitude={defaultLongitude}
            handleLatLongChange={handleLatLongChange}
            handleLocationTouched={() => setLocationTouched(true)}
            supportingGeojson={selectedAssetGeojson}
            error={formState.locationError}
            disabled={isDisabled}
          />
        </Grid.Row>
      </Card.Body>
    </Form>
  );
}
