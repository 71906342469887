export default {
  harvestOrange: "#f3a738",
  lightCoral: "#fe6d73",
  forestMint: "#a2faa3",
  oceanBlue: "#17c3b2",
  sapphireBlue: "#227c9d",
  gray: "#ced4da",
  primary: "#3770df",
  error: "#d23731",
  success: "#00c064",
  warning: "#fad748",
  disabled: "#f8f9fa",
  selected: "rgba(0, 0, 0, 0.04)",
};
