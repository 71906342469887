import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { getMshaOrganizations } from "../../../services/msha-service";
import { EntityTypes } from "../../../types/enums";
import { mshaCustomerColumns } from "./customer-columns";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

export function MshaCustomerList(props) {
  const [columns, setColumns] = useState([]);
  const { width } = useWindowDimensions();
  useEffect(() => {
    let newColumns = [...mshaCustomerColumns];
    let colIndex = newColumns.findIndex((x) => x.field == "name");
    newColumns[colIndex].renderCell = (params) => {
      return (
        <Button
          variant="text"
          className="customer-name-button"
          onClick={() => handleCustomerClick(params.row)}
        >
          {params.value}
        </Button>
      );
    };
    setColumns(newColumns);
  }, [mshaCustomerColumns]);

  useEffect(() => {}, []);

  const navigateToAddCustomer = () => {
    props.history.push(`/add-msha-client`);
  };

  const handleCustomerClick = (row) => {
    props.history.push(`/msha-clients/${row.id}`);
  };

  return (
    <>
      <LynxDataGrid
        columns={columns}
        hasNoLookups
        getDataFunction={getMshaOrganizations}
        title={width < 600 ? "Clients" : "MSHA Clients"}
        localStorageName="msha_clients"
        entityName={EntityTypes.Organization}
        addButtonAction={navigateToAddCustomer}
        addButtonText={width < 600 ? "Add" : "Add New Client"}
        {...props}
      />
    </>
  );
}
