export const columns = [
  {
    field: "assetName",
    headerName: "Asset",
    width: 150,
    type: "string",
  },
  {
    field: "projectName",
    headerName: "Name",
    width: 250,
    type: "string",
  },

  {
    field: "projectStatus",
    headerName: "Status",
    width: 100,
    type: "string",
    renderCell: (params) => (
      <div>
        <i className={"fe fe-circle " + colorSwitch(params.value)} />
        &nbsp;{params.value}
      </div>
    ),
  },
  {
    field: "projectType",
    headerName: "Type",
    width: 100,
    type: "string",
  },
  {
    field: "description",
    headerName: "Description",
    width: 350,
    type: "string",
  },
  {
    field: "startDate",
    headerName: "Start Date",
    width: 100,
    type: "string",
  },
  {
    field: "dueDate",
    headerName: "Due Date",
    width: 100,
    type: "string",
  },
  {
    field: "completeDate",
    headerName: "Complete Date",
    width: 100,
    type: "string",
  },
  {
    field: "estimatedCost",
    headerName: "Estimate Cost",
    width: 150,
    type: "string",
  },
  {
    field: "actualCost",
    headerName: "Actual Cost",
    width: 100,
    type: "string",
  },
  {
    field: "projectManager",
    headerName: "Project Manager",
    width: 150,
    type: "string",
  },
];

export const data = [
  {
    id: "1",
    assetName: "Westbrook Name",
    projectName: "Westbrook Sanitation Improvement",
    projectType: "Sanitation",
    description: "Improve access to sanitation facilities and drinking water",
    startDate: "07/07/2022",
    dueDate: "07/07/2023",
    completeDate: "",
    estimatedCost: "$245,000",
    actualCost: "",
    projectManager: "Matt Carter",
    projectStatus: "Open",
  },
];

const colorSwitch = (status) => {
  switch (status) {
    case "Closed":
      return "text-danger";
    case "Open":
      return "text-success";
  }
};
