import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React from "react";
import { Grid } from "tabler-react";

export function Step7(props) {
  return (
    <Grid>
      <Grid.Row>
        <Typography>
          {!_.isEmpty(props.importSummary.errorMessages) ? (
            <>
              There were errors in the import file. You can choose to fix the
              issues and reupload the file, or you can ignore the errors and
              proceed with the import.
            </>
          ) : (
            <>
              There were no errors in the import file. You can continue with the
              import.
            </>
          )}
        </Typography>
      </Grid.Row>
      <Grid.Row>
        <Typography variant="subtitle2" component="p">
          Total Errors:{" "}
          {props.importSummary.errorMessages.length +
            props.importSummary.fatalErrorMessages.length +
            props.importSummary.rowErrorMessages.length}
        </Typography>
      </Grid.Row>
      <Grid.Row>
        <Typography variant="subtitle2" component="p">
          Successful Rows: {props.importSummary.importableRows}
        </Typography>
      </Grid.Row>
      <Grid.Row>
        <Typography variant="subtitle2" component="p">
          Error Rows: {props.importSummary.errorRows}
        </Typography>
      </Grid.Row>
      <Grid.Row>
        <Typography variant="subtitle2" component="p">
          Events Updated: {props.importSummary.eventsUpdated}
        </Typography>
      </Grid.Row>
      <Grid.Row>
        <Typography variant="subtitle2" component="p">
          Events Created: {props.importSummary.eventsCreated}
        </Typography>
      </Grid.Row>
      {(!_.isEmpty(props.importSummary.errorMessages) ||
        !_.isEmpty(props.importSummary.fatalErrorMessages) ||
        !_.isEmpty(props.importSummary.rowErrorMessages)) && (
        <>
          <Grid.Row>
            <Typography variant="subtitle2" component="p">
              Row Errors:
            </Typography>
          </Grid.Row>

          <div className="error-scroll-box">
            {props.importSummary.rowErrorMessages.map((row) => (
              <Grid.Row>
                <Typography variant="subtitle2" className="text-red">
                  {row}
                </Typography>
              </Grid.Row>
            ))}
          </div>
          <Grid.Row>
            <Typography variant="subtitle2" component="p">
              All Errors:
            </Typography>
          </Grid.Row>
          <div className="error-scroll-box">
            {props.importSummary.fatalErrorMessages.map((row) => (
              <Grid.Row>
                <Typography variant="subtitle2" className="text-red">
                  {row}
                </Typography>
              </Grid.Row>
            ))}
            {props.importSummary.errorMessages.map((row) => (
              <Grid.Row>
                <Typography variant="subtitle2">{row}</Typography>
              </Grid.Row>
            ))}
          </div>
        </>
      )}

      <Grid.Row>
        {!_.isEmpty(props.importSummary.errorMessages) ||
        !_.isEmpty(props.importSummary.fatalErrorMessages) ||
        !_.isEmpty(props.importSummary.rowErrorMessages) ? (
          <>
            {props.importSummary.importableRows > 0 && (
              <Button
                color="error"
                variant="contained"
                className="mr-2"
                onClick={props.handleImportFile}
              >
                Proceed and import {props.importSummary.importableRows} rows
              </Button>
            )}

            <Button variant="contained" component="label">
              Upload Corrected File
              <input
                hidden
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                type="file"
                onChange={props.handleFileUpload}
              />
            </Button>
            <Button onClick={props.handleBack} sx={{ mt: 1, mr: 1 }}>
              Back
            </Button>
          </>
        ) : (
          <>
            <Button
              color="success"
              variant="contained"
              onClick={props.handleImportFile}
            >
              Proceed and import {props.importSummary.importableRows} rows
            </Button>
            <Button onClick={props.handleBack} sx={{ mt: 1, mr: 1 }}>
              Back
            </Button>
          </>
        )}
      </Grid.Row>
    </Grid>
  );
}
