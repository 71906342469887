import { getGridSingleSelectOperators } from "@mui/x-data-grid-pro";
import _ from "lodash";
import { customFieldPrefix } from "./../components/dashboards/events/custom-field";
import { dateUtil } from "./date-util";
import moment from "moment";
import { apiUrl, client } from "../modules/store";
export function createCustomField(dto) {
  return client.post(`${apiUrl}/customFields`, { ...dto });
}
export function getCustomFields(entityType) {
  return client.get(`${apiUrl}/customFields?entityType=${entityType}`);
}

export function deleteCustomField(id) {
  return client.delete(`${apiUrl}/customFields/${id}`);
}

export function updateCustomFieldSort(dto) {
  let url = `${apiUrl}/customFields/sort`;

  return client.post(url, { ...dto });
}

export function patchCustomField(id, jPatch) {
  return client.patch(`${apiUrl}/customFields/${id}`, jPatch);
}

const getFieldName = (def) => {
  if (def.fieldType.toLowerCase() == "datetime") {
    return `${customFieldPrefix}${def.fieldName}.dateTimeValue`;
  }
  if (def.fieldType.toLowerCase() == "date") {
    return `${customFieldPrefix}${def.fieldName}.dateValue`;
  }
  if (def.fieldType.toLowerCase() == "number") {
    return `${customFieldPrefix}${def.fieldName}.numericValue`;
  }
  if (def.fieldType.toLowerCase() == "checkbox") {
    return `${customFieldPrefix}${def.fieldName}.boolenValue`;
  }
  return `${customFieldPrefix}${def.fieldName}.textValue`;
};
export function transformCustomFieldDefsToColumnDefs(customFieldDefs) {
  var columnDefs = [];
  if (_.isEmpty(customFieldDefs)) {
    return columnDefs;
  }
  columnDefs = customFieldDefs
    .sort((a, b) => (a.position > b.position ? 1 : -1))
    .filter((x) => !x.isDeleted)
    .map((def) => ({
      field: getFieldName(def),
      headerName: `${def.fieldLabel}${
        def.parentLookupCode
          ? ` (${def.parentLookupCode}${
              def.childLookupCode ? ` / ${def.childLookupCode}` : ""
            })`
          : ""
      }`,
      eventCategoryId: def.parentLookupId,
      eventSubCategoryId: def.childLookupId,
      importable: true,
      isEventField: true,
      width: 100,
      isMultiSelect:
        def.fieldType.toLowerCase() == "multiselect" ? true : false,
      type: getColumnTypeFromFieldType(def.fieldType),
      sortable: true,
      disabled: def.parentLookupCode ? true : false,
      valueGetter: getCustomFieldValue,
      isCustomField: true,

      valueOptions: def.dropdownValues ? def.dropdownValues.split("|") : [],
      ...(def.fieldType.toLowerCase() == "multiselect" && {
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value !== "isAnyOf"
        ),
      }),
    }));
  return columnDefs;
}

function getColumnTypeFromFieldType(fieldType) {
  switch (fieldType.toLowerCase()) {
    case "dropdown":
      return "singleSelect";
    case "multiselect":
      return "singleSelect";
    case "number":
      return "number";
    case "date":
      return "date";
    case "datetime":
      return "dateTime";
    case "checkbox":
      return "boolean";
    default:
      return "string";
  }
}

export function setupInitialCustomFieldState(
  eventCustomFields,
  existingCustomFieldValues
) {
  var customFields = eventCustomFields.map((field) => {
    var existingValue =
      existingCustomFieldValues &&
      existingCustomFieldValues.find((x) => x.fieldName == field.fieldName);
    return {
      [`${field.fieldName}`]: {
        numericValue: existingValue ? existingValue.numericValue : "",
        textValue: existingValue ? existingValue.textValue : "",
        booleanValue: existingValue ? existingValue.booleanValue : "",
        dateTimeValue: existingValue
          ? existingValue.dateTimeValue
            ? moment(existingValue.dateTimeValue).format("YYYY-MM-DD HH:mm")
            : ""
          : "",
        dateValue: existingValue
          ? existingValue.dateValue
            ? dateUtil.convertDateOnlyToLocal(existingValue.dateValue)
            : ""
          : "",
      },
    };
  });
  var requiredCustomFields = eventCustomFields
    .filter((x) => x.isRequired && !x.isDeleted)
    .map((field) => ({
      [`${field.fieldName}Error`]: "",
    }));
  customFields = Object.assign(...customFields, ...requiredCustomFields);
  return customFields;
}

export function transformCustomFieldState(
  eventCustomFields,
  customFieldState,
  parentLookupId,
  childLookupId
) {
  let customFieldArray = [];

  eventCustomFields.forEach((f) => {
    const field = customFieldState[f.fieldName];
    if (!field) {
      return;
    }
    if (
      (f.parentLookupId != null && f.parentLookupId != parentLookupId) ||
      (f.childLookupId != null && f.childLookupId != childLookupId)
    ) {
      return;
    }
    if (_.toLower(f.fieldType) == "datetime" && field && field.dateTimeValue) {
      customFieldArray.push({
        fieldName: f.fieldName,
        dateTimeValue: (field.dateTimeValue =
          field.dateTimeValue == ""
            ? ""
            : moment(field.dateTimeValue).utc().format()),
      });
    } else {
      customFieldArray.push({
        fieldName: f.fieldName,
        textValue: field.textValue ?? null,
        booleanValue: field.booleanValue ?? null,
        dateValue: field.dateValue ?? null,
        numericValue: field.numericValue ?? null,
      });
    }
  });
  return customFieldArray;
}

function getCustomFieldValue(params) {
  let fieldName = params.field.substring(
    params.field.indexOf(".") + 1,
    params.field.lastIndexOf(".")
  );

  const field = params.row.eventCustomFieldValues.find(
    (x) => x.fieldName == fieldName
  );

  if (params.colDef.type == "dateTime" && field && field.dateTimeValue) {
    return dateUtil.convertDateTimeToLocal(field.dateTimeValue);
  }
  if (params.colDef.type == "date" && field && field.dateValue) {
    return dateUtil.convertDateOnlyToLocal(field.dateValue);
  }
  if (params.colDef.type == "boolean" && field && field.booleanValue) {
    return field.booleanValue;
  }
  if (params.colDef.type == "number" && field && field.numericValue) {
    return field.numericValue;
  }

  if (params.colDef.isMultiSelect && field && field.textValue) {
    return field.textValue ? field.textValue.split("|").join(", ") : "";
  }
  if (field && field.textValue) {
    return field.textValue;
  }

  return "";
}
