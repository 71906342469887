import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import alertify from "alertifyjs";
import * as jsonpatch from "fast-json-patch";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, Dimmer, Form, Grid } from "tabler-react";
import { getUserId, hasLookupPermissions } from "../../../actions/auth";
import lynxColors from "../../../modules/lynxColors";
import {
  createAction,
  getAction,
  patchAction,
} from "../../../services/action-service";
import { getAssetLookups } from "../../../services/assets";
import { dateUtil } from "../../../services/date-util";
import { getEvent } from "../../../services/events";
import { getIncident } from "../../../services/incident-service";
import { getLookups } from "../../../services/lookup";
import { ActionStatuses } from "../../../types/enums";
import { LynxDialog } from "../../lynx-dialog";
import PageNotFound from "../../PageNotFound";
import { getUsersLookups } from "./../../../services/users";
import { validationService } from "./../../../services/validation";
import { AttachmentViewer } from "./../../attachment-viewer";
import "./action-form.css";
import { Box } from "@mui/material";

const ACTION_TITLE_MAX_LENGTH = 50;

export function ActionForm(props) {
  const organization = useSelector((state) => state.organization);
  const initalFrom = {
    title: "",
    description: "",
    priority: "",
    completedComment: "",
    assignedToUserId: "",
    evidenceRequiredId: "",
    dueDate: "",
    linkedEventId: "",
    verifiedComment: "",
    approvedByUserId: "",
    approvedDateTime: "",
    categoryId: "",
    descriptionError: "",
    titleError: "",
    priorityError: "",
    categoryIdError: "",
    assignedToUserIdError: "",
    dueDateError: "",
    evidenceRequiredIdError: "",
    completedCommentError: "",
    verifiedCommentError: "",
  };

  const [formState, setFormState] = useState(initalFrom);
  const [initialFormState, setInitialFormState] = useState({});

  const [assetLookups, setAssetLookups] = useState([]);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [evidenceRequired, setEvidenceRequired] = useState([]);
  const [existingAction, setExistingAction] = useState({});
  const [event, setEvent] = useState({});
  const [incident, setIncident] = useState({});
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [actionSaving, setActionSaving] = useState(false);
  const [actionLoading, setActionLoading] = useState(true);
  const [lookupsLoading, setLookupsLoading] = useState(true);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [showNotFound, setShowNotFound] = useState(false);
  useEffect(() => {
    if (props.match.params.id) {
      loadAction();
    } else {
      setActionLoading(false);
    }
    loadLookups();
  }, []);

  useEffect(() => {
    if (props.match.params.eventId) {
      loadEvent(props.match.params.eventId);
    }
    if (props.match.params.incidentId) {
      loadIncident(props.match.params.incidentId);
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(incident) && !_.isEmpty(users)) {
      setAvailableUsers(
        users.filter(
          (x) =>
            x.userLookups
              .filter((y) => y.lookupId == incident.typeId)
              .map((a) => a.userId)
              .includes(x.id) &&
            x.userAssets
              .filter((y) => y.assetId == incident.assetId)
              .map((a) => a.userId)
              .includes(x.id)
        )
      );
    }
  }, [users, incident]);

  useEffect(() => {
    if (!_.isEmpty(event) && !organization.isLoading && !_.isEmpty(users)) {
      if (!organization.multipleAssetEvents) {
        setAvailableUsers(
          users.filter(
            (x) =>
              x.userLookups
                .filter((y) => y.lookupId == event.eventCategoryId)
                .map((a) => a.userId)
                .includes(x.id) &&
              x.userAssets
                .filter((y) => y.assetId == event.assetId)
                .map((a) => a.userId)
                .includes(x.id)
          )
        );
      } else {
        setAvailableUsers(
          users.filter((x) =>
            x.userLookups
              .filter((y) => y.lookupId == event.eventCategoryId)
              .map((a) => a.userId)
              .includes(x.id)
          )
        );
      }
    }
  }, [users, event, organization]);
  const getLookupId = () => {
    return existingAction.eventCategoryId ?? incident.typeId;
  };

  const canApproveAction = () => {
    return hasLookupPermissions(getLookupId(), "canApprove");
  };

  const canVerifyAction = () => {
    return hasLookupPermissions(getLookupId(), "canVerify");
  };

  const canCompleteAction = () => {
    return existingAction.assignedToUserId == getUserId() || canApproveAction();
  };

  const isCommentFieldDisabled = () => {
    if (isApprovedStatus()) {
      return false;
    }
    if (
      _.toLower(existingAction.status) ==
        _.toLower(ActionStatuses.PendingVerification) &&
      canCompleteAction()
    ) {
      return false;
    }
    return true;
  };

  const isDetailsFieldLocked = () => {
    if (isExistingAction()) {
      if (
        _.toLower(existingAction.status) == _.toLower(ActionStatuses.Closed)
      ) {
        return true;
      }
      if (
        isApprovedStatus() ||
        _.toLower(existingAction.status) ==
          _.toLower(ActionStatuses.PendingVerification)
      ) {
        if (!canApproveAction()) {
          return true;
        }
      }
    }

    return false;
  };

  const loadAction = () => {
    getAction(props.match.params.id)
      .then((res) => {
        setFormStateFromAction(res.data);
        setExistingAction(res.data);
        setActionLoading(false);
      })
      .catch((err) => {
        setShowNotFound(true);
      });
  };

  const loadEvent = (eventId) => {
    getEvent(eventId).then((res) => {
      setEvent(res.data);
      if (!isExistingAction()) {
        setFormState({
          ...formState,
          linkedEventId: res.data.id,
        });
      }
    });
  };

  const loadIncident = (incidentId) => {
    getIncident(incidentId).then((res) => {
      setIncident(res.data);
      if (!isExistingAction()) {
        setFormState({
          ...formState,
          linkedIncidentId: res.data.id,
        });
      }
    });
  };

  const setFormStateFromAction = (action) => {
    let newState = {
      title: action.title,
      description: action.description,
      priority: action.priority,
      assignedToUserId: action.assignedToUserId,
      evidenceRequiredId: action.evidenceRequiredId,
      dueDate: dateUtil.convertDateOnlyToLocal(action.dueDate),
      approvedByUserId: action.approvedByUserId,
      approvedDateTime: action.approvedDateTime,
      categoryId: action.categoryId,
      completedComment: action.completedComment,
      verifiedComment: action.verifiedComment,
      linkedEventId: action.linkedEventId,
      linkedIncidentId: action.linkedIncidentId,
    };
    if (action.linkedEventId) {
      loadEvent(action.linkedEventId);
    }
    if (action.linkedIncidentId) {
      loadIncident(action.linkedIncidentId);
    }

    setFormState(newState);
    setInitialFormState(newState);
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    props.history.push(url);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    let { name, value } = e.target;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const loadLookups = () => {
    loadAssetLookups();
    loadCategories();
    loadEvidenceRequired();
    loadUsers();
    setLookupsLoading(false);
  };

  const loadAssetLookups = () => {
    getAssetLookups()
      .then((res) => {
        setAssetLookups(res.data);
      })
      .catch(() => {});
  };

  const loadCategories = () => {
    getLookups("ActionCategory")
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {});
  };

  const loadEvidenceRequired = () => {
    getLookups("ActionEvidenceRequired")
      .then((res) => {
        setEvidenceRequired(res.data);
      })
      .catch((err) => {});
  };

  const loadUsers = () => {
    getUsersLookups()
      .then((res) => {
        setUsers(res.data);
      })
      .catch(() => {});
  };

  const isExistingAction = () => {
    return props.match.params.id;
  };

  const isForEvent = () => {
    return props.match.params.eventId;
  };

  const isForIncident = () => {
    return props.match.params.incidentId;
  };

  const isApprovedStatus = () => {
    return (
      _.toLower(existingAction.status) == _.toLower(ActionStatuses.Approved) ||
      _.toLower(existingAction.status) == _.toLower(ActionStatuses.Overdue)
    );
  };

  const saveForm = (saveAction) => {
    if (!validateDataForSave(saveAction)) {
      return;
    }
    let form = validationService.unsetErrors(
      formState,
      "descriptionError",
      "titleError",
      "priorityError",
      "categoryIdError",
      "assignedToUserIdError",
      "dueDateError",
      "evidenceRequiredIdError",
      "completedCommentError",
      "verifiedCommentError"
    );

    let formToSave = _.cloneDeep(form);
    setActionSaving(true);
    if (!isExistingAction()) {
      createAction(formToSave, uploadedFiles)
        .then((res) => {
          alertify.success("Action Created.");
          setExistingAction(res.data);
          setFormStateFromAction(res.data);
          if (isForEvent()) {
            props.history.push(
              `/events/${props.match.params.eventId}/actions/${res.data.id}`
            );
          } else if (isForIncident()) {
            props.history.push(
              `/incidents/${props.match.params.incidentId}/actions/${res.data.id}`
            );
          } else {
            props.history.push(`/actions/${res.data.id}`);
          }
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        })
        .finally(() => setActionSaving(false));
    } else {
      var diff = jsonpatch.compare(initialFormState, formToSave);
      patchAction(props.match.params.id, diff, saveAction)
        .then((res) => {
          alertify.success("Action Saved");
          setExistingAction(res.data);
          setFormStateFromAction(res.data);
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        })
        .finally(() => setActionSaving(false));
    }
  };

  const validateDataForSave = (saveAction) => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "description",
      "descriptionError",
      "Description"
    );

    validationService.validateRequiredField(
      newState,
      "title",
      "titleError",
      "Action"
    );

    validationService.validateRequiredField(
      newState,
      "priority",
      "priorityError",
      "Priority"
    );

    validationService.validateRequiredField(
      newState,
      "categoryId",
      "categoryIdError",
      "Category"
    );

    validationService.validateRequiredField(
      newState,
      "assignedToUserId",
      "assignedToUserIdError",
      "Assigned To"
    );
    validationService.validateRequiredField(
      newState,
      "dueDate",
      "dueDateError",
      "Due Date"
    );
    validationService.validateRequiredField(
      newState,
      "evidenceRequiredId",
      "evidenceRequiredIdError",
      "Evidence Required"
    );
    let errorFields = [
      "descriptionError",
      "titleError",
      "priorityError",
      "categoryIdError",
      "assignedToUserIdError",
      "dueDateError",
      "evidenceRequiredIdError",
    ];
    if (_.toLower(saveAction) == "complete") {
      validationService.validateRequiredField(
        newState,
        "completedComment",
        "completedCommentError",
        "Completed Comment"
      );
      errorFields = [...errorFields, "completedCommentError"];
    }
    if (_.toLower(saveAction) == "closeverified") {
      validationService.validateRequiredField(
        newState,
        "verifiedComment",
        "verifiedCommentError",
        "Verified Comment"
      );
      errorFields = [...errorFields, "verifiedCommentError"];
    }

    isFormValid = !validationService.hasError(newState, ...errorFields);

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  const hideFooter = () => {
    if (_.toLower(existingAction.status) == _.toLower(ActionStatuses.Closed))
      return true;
    if (
      _.toLower(existingAction.status) ==
        _.toLower(ActionStatuses.PendingVerification) &&
      !canCompleteAction() &&
      !canVerifyAction()
    )
      return true;
    if (isApprovedStatus() && !canCompleteAction()) return true;
    return false;
  };
  if (showNotFound) {
    return <PageNotFound message="Action not found" />;
  } else
    return (
      <Grid>
        <Dimmer active={actionLoading} loader>
          <Paper>
            <Grid.Row className="ml-0 mr-0">
              <Grid.Col lg={12} width={12} className="">
                <div className="d-flex">
                  <Typography variant="h3" component="div">
                    {isExistingAction()
                      ? existingAction.actionNumber
                      : "Add Action"}
                  </Typography>
                  {isExistingAction() && (
                    <div className="align-self-center ml-5">
                      {getStatusChip(existingAction)}
                    </div>
                  )}
                </div>
              </Grid.Col>
              <Grid.Col lg={12} width={12} className="">
                {!isForEvent() && !isForIncident() ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) => handleNavigateTo(e, "/actions")}
                    >
                      Actions
                    </Link>
                    <Typography color="text.primary">
                      {isExistingAction()
                        ? existingAction.actionNumber
                        : "Add Action"}
                    </Typography>
                  </Breadcrumbs>
                ) : isForEvent() ? (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) => handleNavigateTo(e, "/events")}
                    >
                      Events
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) =>
                        handleNavigateTo(e, `/events/${event.id}`)
                      }
                    >
                      {event.eventNumber}
                    </Link>
                    <Typography color="text.primary">
                      {isExistingAction()
                        ? existingAction.actionNumber
                        : "Add Action"}
                    </Typography>
                  </Breadcrumbs>
                ) : (
                  <Breadcrumbs aria-label="breadcrumb">
                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) => handleNavigateTo(e, "/incidents")}
                    >
                      Incidents
                    </Link>
                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) =>
                        handleNavigateTo(
                          e,
                          `/incidents/${incident.id}?view=actions`
                        )
                      }
                    >
                      {incident.incidentNumber}
                    </Link>
                    <Typography color="text.primary">
                      {isExistingAction()
                        ? existingAction.actionNumber
                        : "Add Action"}
                    </Typography>
                  </Breadcrumbs>
                )}
              </Grid.Col>
            </Grid.Row>
          </Paper>
        </Dimmer>
        <Grid.Row>
          <Container className="form-container" maxWidth="xl">
            <Dimmer active={lookupsLoading} loader>
              <Form className="card ">
                <Card.Header>
                  <Card.Title>Details</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Grid.Row>
                    {(isForEvent() || existingAction.linkedEventId) && (
                      <Grid.Col md={6} width={12}>
                        <Form.Group isRequired label="Source Event">
                          <Form.Select
                            name="linkedEventId"
                            disabled
                            onChange={handleInputChange}
                            value={formState.linkedEventId}
                          >
                            <option value={event.id}>
                              {event.eventNumber}
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </Grid.Col>
                    )}
                    {(isForIncident() || existingAction.linkedIncidentId) && (
                      <Grid.Col md={6} width={12}>
                        <Form.Group isRequired label="Source Incident">
                          <Form.Select
                            name="linkedIncidentId"
                            disabled
                            onChange={handleInputChange}
                            value={formState.linkedIncidentId}
                          >
                            <option value={event.id}>
                              {incident.incidentNumber}
                            </option>
                          </Form.Select>
                        </Form.Group>
                      </Grid.Col>
                    )}
                    <Grid.Col md={12} width={12}>
                      <Form.Group label="Action" isRequired>
                        <Form.Input
                          maxLength={ACTION_TITLE_MAX_LENGTH}
                          type="text"
                          name="title"
                          disabled={isDetailsFieldLocked()}
                          onChange={handleInputChange}
                          value={formState.title}
                          error={formState.titleError}
                        ></Form.Input>
                        <Box
                          fontSize={13}
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            mr: 2,
                          }}
                        >{`${formState.title.length}/${ACTION_TITLE_MAX_LENGTH}`}</Box>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={12} width={12}>
                      <Form.Group label="Description" isRequired>
                        <Form.Textarea
                          name="description"
                          disabled={isDetailsFieldLocked()}
                          onChange={handleInputChange}
                          value={formState.description}
                          error={formState.descriptionError}
                        ></Form.Textarea>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <Form.Group isRequired label="Priority">
                        <Form.Select
                          name="priority"
                          disabled={isDetailsFieldLocked()}
                          onChange={handleInputChange}
                          value={formState.priority}
                          error={formState.priorityError}
                        >
                          <option value={""}></option>
                          <option value="P1">P1</option>
                          <option value="P2">P2</option>
                          <option value="P3">P3</option>
                          <option value="P4">P4</option>
                          <option value="P5">P5</option>
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <Form.Group isRequired label="Category">
                        <Form.Select
                          name="categoryId"
                          onChange={handleInputChange}
                          value={formState.categoryId}
                          disabled={isDetailsFieldLocked()}
                          error={formState.categoryIdError}
                        >
                          <option value={""}></option>
                          {categories.map((cat) => (
                            <option value={cat.id} key={cat.id}>
                              {cat.code}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <Form.Group label="Assigned To" isRequired>
                        <Form.Select
                          name="assignedToUserId"
                          onChange={handleInputChange}
                          value={formState.assignedToUserId}
                          disabled={isDetailsFieldLocked()}
                          error={formState.assignedToUserIdError}
                        >
                          <option value={""}></option>
                          {_.sortBy(availableUsers, ["fullName"]).map(
                            (user) => (
                              <option value={user.id} key={user.id}>
                                {`${user.fullName}`}
                              </option>
                            )
                          )}
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <Form.Group label="Due Date" isRequired>
                        <Form.Input
                          type="date"
                          value={formState.dueDate}
                          name="dueDate"
                          disabled={isDetailsFieldLocked()}
                          onChange={handleInputChange}
                          error={formState.dueDateError}
                        />
                      </Form.Group>
                    </Grid.Col>
                    <Grid.Col md={6} width={12}>
                      <Form.Group isRequired label="Evidence Required">
                        <Form.Select
                          name="evidenceRequiredId"
                          onChange={handleInputChange}
                          value={formState.evidenceRequiredId}
                          error={formState.evidenceRequiredIdError}
                          disabled={isDetailsFieldLocked()}
                        >
                          <option value={""}></option>
                          {evidenceRequired.map((cat) => (
                            <option value={cat.id} key={cat.id}>
                              {cat.code}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Grid.Col>
                    {((isApprovedStatus() && canCompleteAction()) ||
                      _.toLower(existingAction.status) ==
                        _.toLower(ActionStatuses.PendingVerification) ||
                      _.toLower(existingAction.status) ==
                        _.toLower(ActionStatuses.Closed)) && (
                      <Grid.Col md={12} width={12}>
                        <Form.Group label="Completed Comment" isRequired>
                          <Form.Textarea
                            disabled={isCommentFieldDisabled()}
                            name="completedComment"
                            onChange={handleInputChange}
                            value={formState.completedComment}
                            error={formState.completedCommentError}
                          ></Form.Textarea>
                        </Form.Group>
                      </Grid.Col>
                    )}
                    {(_.toLower(existingAction.status) ==
                      _.toLower(ActionStatuses.PendingVerification) ||
                      _.toLower(existingAction.status) ==
                        _.toLower(ActionStatuses.Closed)) && (
                      <Grid.Col md={12} width={12}>
                        <Form.Group label="Verified Comment" isRequired>
                          <Form.Textarea
                            name="verifiedComment"
                            disabled={
                              !canVerifyAction() ||
                              _.toLower(existingAction.status) == "closed"
                            }
                            onChange={handleInputChange}
                            value={formState.verifiedComment}
                            error={formState.verifiedCommentError}
                          ></Form.Textarea>
                        </Form.Group>
                      </Grid.Col>
                    )}
                  </Grid.Row>
                </Card.Body>
                {!hideFooter() && (
                  <Card.Footer>
                    {!isExistingAction() && (
                      <Button
                        variant="contained"
                        className="float-right"
                        onClick={saveForm}
                      >
                        Submit
                      </Button>
                    )}
                    {_.toLower(existingAction.status) == "pending approval" && (
                      <>
                        <Button
                          variant="contained"
                          className="float-right ml-2 action-button"
                          onClick={saveForm}
                        >
                          Save Changes
                        </Button>
                        {canApproveAction() && (
                          <>
                            <Button
                              variant="contained"
                              color="error"
                              className="float-right ml-2 action-button"
                              onClick={() => saveForm("closenotapproved")}
                            >
                              Close - Not Approved
                            </Button>
                            <Button
                              variant="contained"
                              color="success"
                              className="float-right action-button"
                              onClick={() => saveForm("approve")}
                            >
                              Approve
                            </Button>
                          </>
                        )}
                      </>
                    )}
                    {isApprovedStatus() && (
                      <>
                        {canCompleteAction() && (
                          <Button
                            variant="contained"
                            className="float-right"
                            onClick={saveForm}
                          >
                            Save Changes
                          </Button>
                        )}
                        {canCompleteAction() && (
                          <Button
                            variant="contained"
                            color="success"
                            className="float-right mr-2"
                            onClick={() => saveForm("complete")}
                          >
                            Complete
                          </Button>
                        )}
                      </>
                    )}
                    {_.toLower(existingAction.status) ==
                      _.toLower(ActionStatuses.PendingVerification) && (
                      <>
                        <Button
                          variant="contained"
                          className="float-right"
                          onClick={saveForm}
                        >
                          Save Changes
                        </Button>
                        {canVerifyAction() && (
                          <Button
                            variant="contained"
                            color="success"
                            className="float-right mr-2"
                            onClick={() => saveForm("closeverified")}
                          >
                            Close - Verified
                          </Button>
                        )}
                      </>
                    )}
                  </Card.Footer>
                )}
              </Form>
              <Grid.Row>
                <Grid.Col
                  md={isExistingAction() ? 6 : 12}
                  width={12}
                  className="pl-0 pr-0"
                >
                  <div className="action-details-card">
                    <AttachmentViewer
                      isExistingEntity={isExistingAction()}
                      entityType={"Action"}
                      disableDelete={
                        _.toLower(existingAction.status) ==
                        _.toLower(ActionStatuses.Closed)
                      }
                      entityId={existingAction.id}
                      handleSetUploadedFiles={(files) => {
                        setUploadedFiles(files);
                      }}
                      rowHeight={300}
                    />
                  </div>
                </Grid.Col>
                {isExistingAction() && (
                  <Grid.Col md={6} width={12} className="pl-0 pr-0">
                    {getHistoryCard(existingAction)}
                  </Grid.Col>
                )}
              </Grid.Row>
              <div style={{ width: "50%" }}></div>{" "}
            </Dimmer>
          </Container>
        </Grid.Row>
        {actionSaving && (
          <LynxDialog
            open={actionSaving}
            title="Saving Action"
            description={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <CircularProgress />
                </div>
              </>
            }
          />
        )}
      </Grid>
    );
}
export const getStatusChip = (action, size) => {
  if (_.toLower(action.status) == _.toLower(ActionStatuses.Approved)) {
    return <Chip label={ActionStatuses.Approved} color="success" size={size} />;
  }
  if (_.toLower(action.status) == _.toLower(ActionStatuses.PendingApproval)) {
    return (
      <Chip
        label={ActionStatuses.PendingApproval}
        size={size}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }
  if (_.toLower(action.status) == _.toLower(ActionStatuses.Overdue)) {
    return (
      <Chip
        label={ActionStatuses.Overdue}
        size={size}
        sx={{ backgroundColor: lynxColors.error, color: "white" }}
      />
    );
  }
  if (
    _.toLower(action.status) == _.toLower(ActionStatuses.PendingVerification)
  ) {
    return (
      <Chip
        label={ActionStatuses.PendingVerification}
        size={size}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }
  return <Chip label={ActionStatuses.Closed} size={size} color="primary" />;
};

function getHistoryCard(existingAction) {
  return (
    <Card>
      <Card.Header>
        <Card.Title>History</Card.Title>
      </Card.Header>
      <Card.Body className="p-0">
        <TableContainer>
          <Table aria-label="simple table" size="small">
            <TableBody>
              <TableRow>
                <TableCell align="right" width="33%">
                  Created By:
                </TableCell>
                <TableCell>{existingAction.createdByUserFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingAction.createdDateTimeUtc
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">Approved By:</TableCell>
                <TableCell>{existingAction.approvedByUserFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingAction.approvedDateTime
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">Completed By:</TableCell>
                <TableCell>{existingAction.completedByUserFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingAction.completedDateTime
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">Verified By:</TableCell>
                <TableCell>{existingAction.verifierUserFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingAction.verifiedDateTime
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">Closed By:</TableCell>
                <TableCell>{existingAction.closedByUserFullName}</TableCell>
                <TableCell>
                  {dateUtil.convertDateTimeToLocal(
                    existingAction.closedDateTime
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card.Body>
    </Card>
  );
}
