import React from "react";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";

import { EntityTypes } from "../../../types/enums";
import { EquipmentModal } from "./equipment-modal";
import { getEquipment } from "./../../../services/equipment-service";
import { useSelector } from "react-redux";
export const equipmentColumns = [
  {
    field: "name",
    headerName: "Name",
    width: 300,
    type: "string",
  },
  {
    field: "description",
    headerName: "Description",
    width: 300,
    type: "string",
  },
  {
    field: "assetName",
    headerName: "Mine Site",
    width: 150,
    type: "string",
  },
];
export function EquipmentList(props) {
  var organization = useSelector((state) => state.organization);
  return (
    <>
      {organization && organization.id && (
        <LynxDataGrid
          columns={equipmentColumns}
          hasNoLookups
          getDataFunction={getEquipment}
          title="Equipment"
          localStorageName="equipment"
          entityName={EntityTypes.Equipment}
          entityModal={EquipmentModal}
          getDataParams={{
            organizationId: organization.id,
          }}
          hasEditButton
          passEntityOnClick
          addButtonText="Add Equipment"
          {...props}
        />
      )}
    </>
  );
}
