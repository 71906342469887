import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import React from "react";
export function Step3(props) {
  return (
    <>
      <Button variant="outlined" sx={{ marginTop: 1 }} component="label">
        Upload
        <input
          hidden
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          type="file"
          onChange={props.handleFileUpload}
        />
      </Button>

      {props.uploadedFile && (
        <div className="mt-2">
          <Chip
            label={props.uploadedFile.name}
            component="a"
            variant="outlined"
            clickable
            onDelete={() => props.setUploadedFile(null)}
          />
        </div>
      )}
      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={props.handleNext}
            sx={{ mt: 1, mr: 1 }}
            disabled={!props.uploadedFile}
          >
            Continue
          </Button>
          <Button onClick={props.handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
        </div>
      </Box>
    </>
  );
}
