import GroupIcon from "@mui/icons-material/Group";
import React from "react";
import { Mention, MentionsInput } from "react-mentions";
import { searchContacts } from "../../../services/contacts";
import { EventContactTypes } from "../../../types/enums";
export function CommentsInput(props) {
  const renderSuggestion = (
    entry,
    search,
    highlightedDisplay,
    index,
    focused
  ) => {
    return (
      <div className="d-flex">
        {entry.type == "ContactGroup" && (
          <GroupIcon
            className="mr-2"
            style={{ color: "rgba(0, 0, 0, 0.26)" }}
          />
        )}
        {highlightedDisplay}
      </div>
    );
  };

  const fetchContacts = (query, callback) => {
    if (!query) return;
    searchContacts(query)
      .then((res) => {
        return res.data.map((contact) => ({
          id:
            contact.type == EventContactTypes.Contact
              ? "c_" + contact.id
              : "g_" + contact.id,
          display: contact.name,
          type: contact.type,
        }));
      })
      .then(callback);
  };
  return (
    <MentionsInput
      allowSpaceInQuery
      className="lynx-mentions-input"
      rows={4}
      value={props.value}
      onChange={props.onChange}
      style={props.style}
    >
      <Mention
        trigger="@"
        style={{
          backgroundColor: "#f3a738",
          opacity: 0.3,
          borderRadius: 3,
        }}
        displayTransform={(id, display) => `@${display}`}
        markup="@[__display__](__id__)"
        data={props.users}
      />
      <Mention
        trigger="#"
        style={{
          backgroundColor: "#00A9E0",
          opacity: 0.3,

          borderRadius: 3,
        }}
        renderSuggestion={renderSuggestion}
        displayTransform={(id, display) => `#${display}`}
        markup="#[__display__](__id__)"
        data={fetchContacts}
      />
    </MentionsInput>
  );
}
