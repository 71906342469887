import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import { Divider } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import alertify from "alertifyjs";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Dimmer, Form, Grid } from "tabler-react";
import { validationService } from "../../../services";
import { createMshaOrganization } from "../../../services/msha-service";
import { Accordion, AccordionDetails, AccordionSummary } from "../../accordion";
import { LynxDialog } from "../../lynx-dialog";
export function MshaCustomerForm(props) {
  const initialUserForm = {
    email: "",
    firstName: "",
    lastName: "",
    title: "",
    emailError: "",
    firstNameError: "",
    lastNameError: "",
    id: 1,
  };
  const intialForm = {
    name: "",
    nameError: "",
    description: "",
    newUsers: [initialUserForm],
  };

  const defaultMineSiteFormState = {
    name: "",
    nameError: "",
    assetNumber: "",
    assetNumberError: "",
  };
  const [detailsExpanded, setDetailsExpanded] = useState(true);
  const [usersExpanded, setUsersExpanded] = useState(true);
  const [formState, setFormState] = useState(intialForm);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [mineSiteFormState, setMineSiteFormState] = useState([
    defaultMineSiteFormState,
  ]);

  useEffect(() => {
    if (!isExistingCustomer()) {
      setIsLoading(false);
    } else {
      //load custy
    }
  }, []);

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    props.history.push(url);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    let name = e.target.name;
    let value = e.target.value;

    _.set(newState, name, value);

    setFormState(newState);
  };

  const isExistingCustomer = () => {
    return props.match.params.id;
  };

  const handleAddUser = () => {
    let existingUsers = [...formState.newUsers];
    let highestNumber = _.isEmpty(existingUsers)
      ? 0
      : _.orderBy(existingUsers, "id").findLast((x) => x.id).id;
    let newNumber = highestNumber + 1;
    existingUsers = [
      ...existingUsers,
      {
        ...initialUserForm,
        id: newNumber,
      },
    ];

    setFormState({ ...formState, newUsers: existingUsers });
  };

  const handleNewUserChange = (e, id) => {
    let existingState = { ...formState };
    let resultIndex = existingState.newUsers.findIndex((x) => x.id == id);
    let name = e.target.name;
    let value = e.target.value;
    _.set(existingState.newUsers[resultIndex], name, value);
    setFormState(existingState);
  };

  const handleUserDelete = (user) => {
    let existingState = { ...formState };
    var userIndex = existingState.newUsers.findIndex((x) => x.id == user.id);
    existingState.newUsers.splice(userIndex, 1);
    setFormState(existingState);
  };

  const saveForm = () => {
    if (!validateDataForSave()) {
      return;
    }
    setIsSaving(true);
    let objectToCreate = {
      name: formState.name,
      description: formState.description,
      users: formState.newUsers,
      assets: mineSiteFormState,
    };
    createMshaOrganization(objectToCreate)
      .then((res) => {
        setShowSuccessDialog(true);
        setIsSaving(false);
      })
      .catch((err) => {
        setErrorMessage(err.response.data.message);
        setIsSaving(false);
      });
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );

    isFormValid = !validationService.hasError(newState, "nameError");
    var usersValid = true;
    newState.newUsers.forEach((user) => {
      validationService.validateRequiredField(
        user,
        `firstName`,
        `firstNameError`,
        `First Name`
      );
      validationService.validateRequiredField(
        user,
        `lastName`,
        `lastNameError`,
        `Last Name`
      );

      validationService.validateEmailValue(
        user.email,
        user,
        "emailError",
        true
      );
      usersValid = !validationService.hasError(
        user,
        "firstNameError",
        "lastNameError",
        "emailError"
      );
    });
    var minesValid = true;
    mineSiteFormState.forEach((mine) => {
      validationService.validateRequiredField(
        mine,
        `name`,
        `nameError`,
        `Mine Site Name`
      );
      validationService.validateRequiredField(
        mine,
        `assetNumber`,
        `assetNumberError`,
        `Mine Site ID`
      );
      minesValid = !validationService.hasError(
        mine,
        "nameError",
        "assetNumberError"
      );
    });
    if (!isFormValid || !usersValid || !minesValid) {
      setFormState(newState);
      setMineSiteFormState(mineSiteFormState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid && usersValid && minesValid;
  };

  const handleMineInputChange = (e, index) => {
    let existingState = [...mineSiteFormState];
    let name = e.target.name;
    let value = e.target.value;
    _.set(existingState[index], name, value);
    setMineSiteFormState(existingState);
  };

  const handleRemoveMineSite = (index) => {
    let existingState = [...mineSiteFormState];
    existingState.splice(index, 1);
    setMineSiteFormState(existingState);
  };
  return (
    <Grid>
      <Dimmer active={isLoading} loader>
        <Paper>
          <Grid.Row className="ml-0 mr-0">
            <Grid.Col lg={12} width={12} className="">
              <div className="d-flex">
                <Typography
                  variant="h3"
                  className="form-header-text"
                  component="div"
                >
                  {isExistingCustomer() ? formState.name : "Add MSHA Customer"}
                </Typography>
              </div>
            </Grid.Col>
            <Grid.Col lg={12} width={12} className="">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={(e) => handleNavigateTo(e, "/msha-clients")}
                >
                  Manage Clients
                </Link>
                <Typography color="text.primary">
                  {isExistingCustomer() ? formState.name : "Add MSHA Customer"}
                </Typography>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>
        <Grid.Row>
          <Container className="mt-2" maxWidth="xl">
            <Form className="card ">
              <Card.Body className="p-0">
                <Accordion
                  expanded={detailsExpanded}
                  onChange={() => setDetailsExpanded(!detailsExpanded)}
                  className="w-100"
                >
                  <AccordionSummary
                    aria-controls="details-content"
                    id="details-header"
                  >
                    <Typography>Customer / Operator Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid.Row>
                      <Grid.Col md={12} width={12}>
                        <Form.Group isRequired label="Customer / Operator Name">
                          <Form.Input
                            type="text"
                            value={formState.name}
                            name="name"
                            id="customerName"
                            className="customer-name"
                            error={formState.nameError}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={12} width={12}>
                        <Form.Group label="Notes">
                          <Form.Textarea
                            name="description"
                            onChange={handleInputChange}
                            value={formState.description}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={12} width={12}>
                        <Divider />
                        <p className="mt-2">Mine Site Details</p>
                      </Grid.Col>
                      <Grid.Col md={12} width={12}>
                        <Fab
                          className="ml-2 mb-3"
                          size="small"
                          color="primary"
                          aria-label="add"
                          id="addUserBtn"
                          onClick={() => {
                            setMineSiteFormState([
                              ...mineSiteFormState,
                              defaultMineSiteFormState,
                            ]);
                          }}
                        >
                          <AddIcon />
                        </Fab>{" "}
                        <Typography
                          variant="h6"
                          className="ml-3"
                          component={"b"}
                        >
                          Add Mine Site
                        </Typography>
                      </Grid.Col>

                      {mineSiteFormState.map((mineFormState, index) => (
                        <>
                          <Grid.Col md={1} width={12}>
                            {index !== 0 && (
                              <Tooltip
                                title="Remove"
                                className="mt-5 float-right"
                              >
                                <IconButton
                                  onClick={() => {
                                    handleRemoveMineSite(index);
                                  }}
                                  className="grid-remove-button"
                                  aria-label="Remove"
                                  size="small"
                                >
                                  <ClearIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Grid.Col>
                          <Grid.Col md={6} width={12}>
                            <Form.Group isRequired label="Mine Site Name">
                              <Form.Input
                                type="text"
                                name="name"
                                className="mine-site-name-input"
                                error={mineSiteFormState[index].nameError}
                                onChange={(e) => {
                                  handleMineInputChange(e, index);
                                }}
                                value={mineSiteFormState[index].name}
                              />
                            </Form.Group>
                          </Grid.Col>
                          <Grid.Col md={5} width={12}>
                            <Form.Group isRequired label="Mine Site ID">
                              <Form.Input
                                type="text"
                                name="assetNumber"
                                error={
                                  mineSiteFormState[index].assetNumberError
                                }
                                onChange={(e) => {
                                  handleMineInputChange(e, index);
                                }}
                                value={mineSiteFormState[index].assetNumber}
                              />
                            </Form.Group>
                          </Grid.Col>
                        </>
                      ))}

                      <Grid.Col md={12} width={12}>
                        <Typography>
                          The client will be added to the system with the
                          following programs and inspection forms:
                          <br />
                          <ul>
                            <li>Daily Examination Of Working Places</li>
                            <li>Surface Mobile Equipment Inspection</li>
                            <li>Safety Program for Surface Mobile Equipment</li>
                            <li>Program Evaluation and Update</li>
                            <li>Technology Evaluation</li>
                          </ul>
                        </Typography>
                      </Grid.Col>
                    </Grid.Row>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  expanded={usersExpanded}
                  onChange={() => setUsersExpanded(!usersExpanded)}
                  className="w-100"
                >
                  <AccordionSummary
                    aria-controls="form-content"
                    id="form-header"
                  >
                    <Typography>Users</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Fab
                      className="ml-2 mb-3"
                      size="small"
                      color="primary"
                      aria-label="add"
                      id="addUserBtn"
                      onClick={handleAddUser}
                    >
                      <AddIcon />
                    </Fab>
                    <Typography variant="h6" className="ml-3" component={"b"}>
                      Add User
                    </Typography>
                    {formState.newUsers.map((user, i) => (
                      <div className="pt-2 " key={i}>
                        <Grid.Row>
                          <React.Fragment key={i}>
                            <Grid.Col
                              className="align-self-center"
                              md={1}
                              width={12}
                            >
                              {i !== 0 && (
                                <Tooltip title="Remove">
                                  <IconButton
                                    onClick={() => {
                                      handleUserDelete(user);
                                    }}
                                  >
                                    <ClearIcon />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </Grid.Col>
                            <Grid.Col md={6} width={12}>
                              <Form.Group isRequired label={"Email"}>
                                <Form.Input
                                  type="text"
                                  name={`email`}
                                  onChange={(e) =>
                                    handleNewUserChange(e, user.id)
                                  }
                                  value={
                                    formState.newUsers.find(
                                      (x) => x.id == user.id
                                    ).email
                                  }
                                  error={
                                    formState.newUsers.find(
                                      (x) => x.id == user.id
                                    ).emailError
                                  }
                                ></Form.Input>
                              </Form.Group>
                            </Grid.Col>{" "}
                            <Grid.Col md={5} width={12}>
                              <Form.Group label="Title">
                                <Form.Input
                                  type="text"
                                  name={`title`}
                                  onChange={(e) =>
                                    handleNewUserChange(e, user.id)
                                  }
                                  value={
                                    formState.newUsers.find(
                                      (x) => x.id == user.id
                                    ).title
                                  }
                                ></Form.Input>
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col
                              className="align-self-center"
                              md={1}
                              width={12}
                            ></Grid.Col>
                            <Grid.Col md={6} width={12}>
                              <Form.Group label="First Name" isRequired>
                                <Form.Input
                                  type="text"
                                  name={`firstName`}
                                  onChange={(e) =>
                                    handleNewUserChange(e, user.id)
                                  }
                                  value={
                                    formState.newUsers.find(
                                      (x) => x.id == user.id
                                    ).firstName
                                  }
                                  error={
                                    formState.newUsers.find(
                                      (x) => x.id == user.id
                                    ).firstNameError
                                  }
                                ></Form.Input>
                              </Form.Group>
                            </Grid.Col>
                            <Grid.Col md={5} width={12}>
                              <Form.Group label="Last Name" isRequired>
                                <Form.Input
                                  type="text"
                                  name={`lastName`}
                                  onChange={(e) =>
                                    handleNewUserChange(e, user.id)
                                  }
                                  value={
                                    formState.newUsers.find(
                                      (x) => x.id == user.id
                                    ).lastName
                                  }
                                  error={
                                    formState.newUsers.find(
                                      (x) => x.id == user.id
                                    ).lastNameError
                                  }
                                ></Form.Input>
                              </Form.Group>
                            </Grid.Col>
                          </React.Fragment>
                        </Grid.Row>
                        <Divider></Divider>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </Card.Body>
              <Card.Footer>
                <Button
                  variant="contained"
                  className="float-right"
                  onClick={saveForm}
                  id="addBtn"
                >
                  {`Add Customer${
                    !_.isEmpty(formState.newUsers)
                      ? ` and ${formState.newUsers.length} Users`
                      : ""
                  }`}
                </Button>
              </Card.Footer>
            </Form>
          </Container>
        </Grid.Row>
        {isSaving && (
          <LynxDialog
            open={isSaving}
            title={`Saving Client. Do not close the window.`}
            description={
              <>
                <div className="d-flex align-items-center justify-content-center mt-4">
                  <CircularProgress />
                </div>
              </>
            }
          />
        )}
        {showSuccessDialog && (
          <LynxDialog
            open={showSuccessDialog}
            title={"Client Added"}
            description={`The client and ${formState.newUsers.length} user(s) have been added. The users will
            recieve a registration email with directions for registering for the platform. If the user does not recieve the email, 
            you can copy their registraion link from the users grid.`}
            handleConfirm={() => {
              handleNavigateTo(null, "/msha-clients");
            }}
          />
        )}
        {errorMessage != "" && (
          <LynxDialog
            open={errorMessage != ""}
            title={errorMessage}
            handleConfirm={() => {
              setErrorMessage("");
            }}
          />
        )}
      </Dimmer>
    </Grid>
  );
}
