import { Container, IconButton } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import React, { useState, useEffect } from "react";
import { Grid } from "tabler-react";
import { BadgeWidget } from "../../dashboards/dashboards/widgets/badge-widget";
import { WylooMap } from "./wyloo-map";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { wylooTheme } from "./wyloo-theme";
import moment from "moment";
import { MonitoringLocationPortalTypes } from "../../../types/enums";
import { WylooLineChart } from "./wyloo-line-chart";
import { Dimmer } from "tabler-react";
import { useHistory } from "react-router-dom";
import { navigateTo } from "services/navigation-service";
import { getPortalStats } from "services/organization-portal-service";
import { useSelector } from "react-redux";
import PageNotFound from "components/PageNotFound";
export function WylooMicrositeWater(props) {
  const [portalStats, setPortalStats] = useState({});
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [statsLoading, setStatsLoading] = useState(true);
  const history = useHistory();
  const portal = useSelector((state) => state.portal);
  useEffect(() => {
    getPortalStats(
      [
        MonitoringLocationPortalTypes.SurfaceWater,
        MonitoringLocationPortalTypes.GroundWater,
      ],
      window.location.href
    )
      .then((response) => {
        setPortalStats(response.data);
      })
      .finally(() => {
        setStatsLoading(false);
      });
  }, []);

  const handleLocationSelected = (e) => {
    setSelectedLocation(e);
  };

  return (
    <ThemeProvider theme={wylooTheme}>
      {portal.isLoading || (!portal.isLoading && portal.isEnabled) ? (
        <div className="wyloo-microsite-container wyloo-colors ">
          <Dimmer active={portal.isLoading} loader>
            <div className="header-container-wyloo" style={{}}>
              <img
                alt="wyloo-header-image"
                className="wyloo-header-image"
                src="https://wyloo.com/wp-content/uploads/2024/03/Mincor-220906-016-64.png"
              ></img>
              <div className="wyloo-header-text">
                <Typography variant="h2">
                  <IconButton
                    onClick={(e) => navigateTo(history, "/wyloo/", e)}
                    variant="outlined"
                    className="mr-5"
                    sx={{ color: "white", border: "3px solid white" }}
                    size="large"
                  >
                    <ArrowBackIcon />
                  </IconButton>
                  {`Water Monitoring`}
                </Typography>
              </div>
            </div>
            <Container maxWidth="xl">
              <Grid className="">
                <Grid.Row className="mt-5">
                  <Grid.Col width={6}>
                    <div className="wyloo-text-section mb-5">
                      <Typography sx={{ width: "fit-content" }}>
                        Lorem ipsum odor amet, consectetuer adipiscing elit.
                        Pharetra elit dignissim leo, massa vivamus fusce ac.
                        Fusce et facilisi venenatis elit sed penatibus fames.
                        Hac penatibus parturient scelerisque tristique taciti
                        fames nisl volutpat. Fermentum dui fringilla quam
                        aliquam viverra. Pulvinar tincidunt bibendum quam velit
                        efficitur cras tempor. Malesuada rhoncus lectus
                        vestibulum quis mus, orci purus tellus himenaeos.
                        Ultrices arcu interdum ac non lectus convallis sit
                        porttitor commodo. Volutpat velit metus lobortis feugiat
                        malesuada.
                      </Typography>
                    </div>
                    <WylooLineChart
                      handleLocationSelected={handleLocationSelected}
                      portalTypes={[
                        MonitoringLocationPortalTypes.SurfaceWater,
                        MonitoringLocationPortalTypes.GroundWater,
                      ]}
                    />
                  </Grid.Col>
                  <Grid.Col width={6}>
                    <div
                      className="d-flex mb-5"
                      style={{ justifyContent: "space-between" }}
                    >
                      <BadgeWidget
                        title={"Total Sample Events"}
                        value={portalStats.totalSampleEvents}
                        subValue={`Between ${moment(
                          portalStats.firstSampleDate
                        ).format("MMM YYYY")} and ${moment(
                          portalStats.lastSampleDate
                        ).format("MMM YYYY")}`}
                        className="mr-2 portal-badge"
                        isLoading={statsLoading}
                      />

                      <BadgeWidget
                        title={"Unique Parameters Sampled"}
                        value={portalStats.distinctParametersSampled}
                        subValue="Across ground and surface water"
                        className="portal-badge"
                        isLoading={statsLoading}
                      />
                    </div>
                    <WylooMap
                      selectedLocation={selectedLocation}
                      portalTypes={[
                        MonitoringLocationPortalTypes.SurfaceWater,
                        MonitoringLocationPortalTypes.GroundWater,
                      ]}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Grid>
            </Container>
          </Dimmer>
        </div>
      ) : (
        <div className="content-wrapper">
          <PageNotFound />
        </div>
      )}
    </ThemeProvider>
  );
}
