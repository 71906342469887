import * as React from "react";

import _ from "lodash";
import lynxColors from "../../../modules/lynxColors";
import {
  multipleSelectContainsOperator,
  multipleSelectDoesNotContainOperator,
} from "./../../data-grid/multiple-select-operator";
import { dateUtil } from "../../../services/date-util";
export const incidentColumns = [
  {
    field: "incidentNumber",
    headerName: "Incident #",
    width: 100,
    type: "string",
  },

  {
    field: "status",
    headerName: "Status",
    width: 200,
    type: "singleSelect",
    valueOptions: [
      "Pending Review",
      "Investigation",
      "Final Review",
      "Pending Actions",
      "Closed",
    ],
    renderCell: (params) => (
      <div className="incident-status">
        <i
          className={"fe fe-circle "}
          style={{ color: statusColorSwitch(params.row.status) }}
        />
        &nbsp;
        {params.value}
      </div>
    ),
  },
  {
    field: "incidentDateTime",
    headerName: "Incident Date Time",
    width: 200,
    type: "dateTime",
    valueGetter: (params) => {
      return dateUtil.convertDateTimeToLocal(params.value);
    },
  },
  {
    field: "title",
    headerName: "Title",
    width: 200,
    type: "string",
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
    type: "string",
  },
  {
    field: "severity",
    headerName: "Severity",
    width: 100,
    type: "singleSelect",
    lookup: "incidentSeverity",
    renderCell: (params) => (
      <div className="incident-severity">
        <i
          className={"fe fe-circle "}
          style={{ color: severityColorSwitch(params.row.severity) }}
        />
        &nbsp;
        {params.value}
      </div>
    ),
  },
  {
    field: "type",
    headerName: "Type",
    width: 150,
    type: "singleSelect",
    lookup: "incidentType",
  },
  {
    field: "subType",
    headerName: "Subtype",
    width: 200,
    type: "singleSelect",
    lookup: "incidentSubType",
  },
  {
    field: "departmentName",
    headerName: "Department",
    width: 200,
    type: "string",
  },

  {
    field: "immediateActions",
    headerName: "Immediate Actions Taken",
    width: 200,
    type: "string",
  },
  {
    field: "contributingFactors",
    headerName: "Contributing Factors",
    width: 200,
    type: "string",
  },
  {
    field: "investigationRequired",
    headerName: "Investigation Required?",
    width: 150,
    type: "boolean",
  },
  {
    field: "investigationLeadUserFullName",
    headerName: "Investigation Lead",
    width: 200,
    type: "singleSelect",
    lookup: "users",
    valueField: "id",
    labelField: "fullName",
  },
  {
    field: "affectedPartiesContactTypes",
    headerName: "Affected Party Types",
    width: 150,
    type: "singleSelect",
    lookup: "incidentParticipantType",
    valueField: "id",
    labelField: "code",
    importable: true,

    filterOperators: [
      ...multipleSelectContainsOperator,
      ...multipleSelectDoesNotContainOperator,
    ],
    sortable: false,
  },
];

export const statusColorSwitch = (status) => {
  switch (_.toLower(status)) {
    case "pending review":
    case "final review":
    case "pending actions":
      return lynxColors.harvestOrange;

    default:
      return lynxColors.primary;
  }
};
export const severityColorSwitch = (status) => {
  switch (_.toLower(status)) {
    case "low":
      return lynxColors.success;
    case "medium":
      return lynxColors.warning;
    case "high":
      return lynxColors.error;

    default:
      return lynxColors.success;
  }
};
