import { createTheme } from "@mui/material/styles";
import lynxColors from "../../../modules/lynxColors";

export const wylooColors = {
  white: "#ffffff",
  black: "#322c2c",
  grey: "#beb7b3",
  lightGrey: "#e4e1dc",
  lighterGrey: "#f8f8f6",
  orange: "#FF7500",
  ocean: "#1b587c",
  eucalyptus: "#15685a",
  blue: "#008AFF",
  skyBlue: "#00c2c6",
  green: "#00b93f",
  tan: "#bba79c",
};
export const wylooTheme = createTheme({
  typography: {
    h1: {
      fontFamily: "Poppins",
    },
    h2: { fontFamily: "Poppins" },
    h3: { fontFamily: "Poppins" },
    h4: { fontFamily: "Poppins" },
    h5: { fontFamily: "Poppins" },
    h6: { fontFamily: "Poppins" },
    body1: { fontFamily: "Inter" },
    body2: { fontFamily: "Inter" },
    subtitle1: { fontFamily: "Poppins" },
    subtitle2: { fontFamily: "Poppins" },
  },

  palette: {
    primary: {
      main: "#1b587c",
    },
    error: {
      main: lynxColors.error,
      contrastText: "#fff",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    secondary: {
      light: "#0066ff",
      main: wylooColors.orange,
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    success: {
      main: "#2b80f0",
      contrastText: "#fff",
    },
  },
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: { fontFamily: "Poppins" },
      },
    },
    // Name of the component
    MuiChip: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: wylooColors.black,
          backgroundColor: wylooColors.white,
          borderColor: wylooColors.black,
          borderWidth: "1px",
          fontFamily: "Inter",
          "&&:hover": {
            color: wylooColors.black,
            backgroundColor: wylooColors.white,
            borderColor: wylooColors.orange,
          },
        },

        icon: { color: "#fff" },
      },
    },
    MuiSlider: {
      styleOverrides: {
        rail: {
          color: wylooColors.grey,
          opacity: 0.5,
        },
        track: {
          color: wylooColors.black,
          opacity: 0.5,
        },
        thumb: {
          color: wylooColors.orange,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: wylooColors.white,
          color: wylooColors.black,
          borderColor: wylooColors.black,
          fontFamily: "Inter",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
          color: wylooColors.black,
          backgroundColor: wylooColors.lighterGrey,
          borderColor: "#121212",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "Inter",
        },
      },
    },
  },
});
