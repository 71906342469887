import { apiUrl, client } from "../modules/store";
import _ from "lodash";

export function getMonitoringEvents(params) {
  return client.get(apiUrl + "/monitoringEvents", {
    params: params,
  });
}

export function getMonitoringEventsAttachments(params) {
  var url = `${apiUrl}/monitoringEvents/attachments`;

  return client
    .get(url, {
      responseType: "blob",
      params: params,
    })
    .then((res) => res);
}

export function createMonitoringEvent(form) {
  return client.post(apiUrl + "/monitoringEvents", form);
}

export function deleteMonitoringEvent(id) {
  return client.delete(apiUrl + "/monitoringEvents/" + id);
}

export function patchMonitoringEvent(id, dto) {
  return client.post(apiUrl + "/monitoringEvents/" + id, dto);
}

export function getMonitoringEvent(id) {
  return client.get(apiUrl + "/monitoringEvents/" + id);
}

export function getMonitoringEventCountByFilterId(filterId) {
  let url = apiUrl + "/monitoringEvents/count/" + filterId;

  return client.get(url);
}

export function getMonitoringEventsExportAsXLSX(params, columnDefinitionDto) {
  var url = `${apiUrl}/monitoringEvents/export`;

  return client
    .post(url, columnDefinitionDto, {
      responseType: "blob",
      params: params,
    })
    .then((res) => res);
}
export function bulkUpdateMonitoringEvents(params, data) {
  return client.post(apiUrl + "/monitoringEvents/bulkUpdate", data, {
    params: params,
  });
}

export function bulkUpdateMonitoringEventAttachments(params, attachments) {
  let form = new FormData();

  for (var index = 0; index < attachments.length; index++) {
    var element = attachments[index];
    form.append("file", element);
  }

  return client.post(apiUrl + "/monitoringEvents/bulkUpdateAttachments", form, {
    params: params,
  });
}
