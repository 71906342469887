import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import GroupIcon from "@mui/icons-material/Group";
import Chip, { chipClasses } from "@mui/material/Chip";
import React, { useState } from "react";
import _ from "lodash";
import Popover from "@mui/material/Popover";
import { ContactQuickCard } from "../contacts/contact-quick-card";
import { EventContactTypes } from "../../../types/enums";
export function CorrespondenceContactChip({ contact, onDelete, className }) {
  const [selectedContact, setSelectedContact] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleContactClick = (event, contact) => {
    setAnchorEl(event.currentTarget);
    setSelectedContact(contact);
  };
  return (
    <>
      <Chip
        onClick={contact.userId ? null : (e) => handleContactClick(e, contact)}
        className={className}
        icon={
          contact.userId ? (
            <AccountCircleIcon />
          ) : contact.contactGroupId ? (
            <GroupIcon />
          ) : null
        }
        onDelete={
          onDelete
            ? () => {
                onDelete(contact);
              }
            : null
        }
        label={
          contact.userId
            ? contact.userFullName
            : contact.contactId
            ? contact.contactFullName
            : contact.contactGroupId
            ? contact.contactGroupName
            : ""
        }
        variant="outlined"
        sx={{
          [`& .${chipClasses.icon}`]: {
            color: "rgba(0, 0, 0, 0.26)",
          },
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableScrollLock
        onClose={() => setAnchorEl(null)}
      >
        {!_.isEmpty(selectedContact) && (
          <ContactQuickCard
            id={selectedContact.contactId ?? selectedContact.contactGroupId}
            type={
              selectedContact.contactId
                ? EventContactTypes.Contact
                : EventContactTypes.ContactGroup
            }
          />
        )}
      </Popover>
    </>
  );
}
