import * as actions from "../actions/account";

const initialState = {
  initialTourCompletedDateTimeUtc: null,
  eventsGridTourCompletedDateTimeUtc: null,
  monitoringGridTourCompletedDateTimeUtc: null,
  incidentsGridTourCompletedDateTimeUtc: null,
  isLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.ACCOUNT_LOAD:
      return {
        ...state,
        isLoading: true,
        hasErrored: false,
      };
    case actions.ACCOUNT_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload.data,
        isLoading: false,
        hasErrored: false,
      };
    case actions.ACCOUNT_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        hasErrored: true,
        errorMessage: action.error.message,
      };

    case actions.ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.meta.previousAction,
      };
    default:
      break;
  }
  return state;
};
