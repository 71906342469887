import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import React from "react";
import { EntityTypes } from "../../../types/enums";
import { Tags } from "./tags";

export function EventDetailsTags(props) {
  return (
    <Card className="lynx-card" variant="outlined" sx={{ width: "100%" }}>
      <CardHeader className="lynx-card-header" title="Tags" />
      <CardContent className="p-1 mt-2 mb-0 pb-0">
        <Tags
          isLoading={props.commentsLoading}
          entityId={props.event.id}
          entityType={EntityTypes.Event}
        />
      </CardContent>
    </Card>
  );
}
