export const monitoringGridTourSteps = [
  {
    disableBeacon: true,
    target: ".grid-view-select",
    placementBeacon: "right",
    placement: "bottom",
    title: "Switch your Grid View",
    content:
      "Use Select Grid View to toggle between monitoring events, monitoring results, and monitoring locations.",
  },
  {
    target: ".filter-grid-button",
    placementBeacon: "bottom",
    placement: "bottom",
    title: "Create and Save Filters",
    content:
      "Filters are used to quickly view the data you need, when you need it. Filters are also used to create widgets for the Dashboard. Use the Filters button on any grid to create custom filters. Filters can be saved and applied quickly using the Saved Filters button.",
  },
  {
    target: ".export-grid-button",
    placementBeacon: "bottom",
    placement: "bottom",
    title: "Export Your Data",
    content:
      "Export your monitoring data in .csv format by clicking the Export button. Share results with colleagues, prepare for permitting activities and demonstrate a consistent log of record.",
  },
  {
    target: ".monitoring-event-number-button:first-of-type",
    placement: "bottom",
    title: "The Monitoring Event Number",
    content:
      "Each Monitoring Event is assigned a unique Event Number. Clicking on the Event Number will open the Event Details page.",
  },
  {
    target: ".grid-add-button",
    placementBeacon: "left",
    placement: "left",
    title: "Add an Event!",
    content:
      "Let’s go ahead and add your first monitoring event. Click the Add Monitoring Event on the top right to get started!",
  },
];
