import { apiUrl, client } from "../modules/store";
import queryString from "query-string";
export function getKpis(params) {
  return client.get(apiUrl + "/kpis", {
    params: params,
  });
}

export function getKpiById(id) {
  return client.get(`${apiUrl}/kpis/${id}`);
}

export function getKpiHistoryById(id, params) {
  return client.get(`${apiUrl}/kpis/${id}/history`, {
    params: params,
  });
}

export function getKpiDashboardDataById(id, dateFilter, refreshData) {
  let url = `${apiUrl}/kpis/${id}/dashboard?`;
  let queryObject = {};
  if (dateFilter) {
    queryObject = { dateFilter: dateFilter, ...queryObject };
  }
  if (refreshData) {
    queryObject = { refreshData: refreshData, ...queryObject };
  }
  url = url + queryString.stringify(queryObject);
  return client.get(url);
}
