import React, { useEffect, useState } from "react";
import { Grid, Card, Form } from "tabler-react";
import { createLookup, getLookups } from "../../../../services/lookup";

import _ from "lodash";
import alertify from "alertifyjs";
import { validationService } from "../../../../services";
import Button from "@mui/material/Button";
import { LookupTypes } from "../../../../types/enums";
export function TagModal(props) {
  const initialForm = {
    id: "",
    lookupType: "",
    code: "",
    description: "",
    childLookupId: "",
    descriptionError: "",
    codeError: "",
  };
  const [formState, setFormState] = useState(initialForm);
  const [tagGroups, setTagGroups] = useState([]);
  const [tagCategories, setTagCategories] = useState([]);
  useEffect(() => {
    if (props.isForEvent) {
      getLookups(LookupTypes.TagGroup).then((res) => {
        setTagGroups(res.data);
      });
      getLookups(LookupTypes.TagCategory).then((res) => {
        setTagCategories(res.data);
      });
    } else {
      setTagGroups(props.tagGroups);
      setTagCategories(props.tagCategories);
    }
  }, [props.isForEvent, props.tagGroups, props.tagCategories]);
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const { name, value } = e.target;

    _.set(newState, name, value);

    setFormState(newState);
  };

  const saveForm = () => {
    if (!validateDataForSave()) {
      return;
    }
    let formToSave = validationService.unsetErrors(
      formState,
      "codeError",
      "parentLookupIdError"
    );
    if (props.id > 0) {
    } else {
      formToSave.id = 0;
      formToSave.lookupType = LookupTypes.Tag;
      createLookup(formToSave)
        .then((res) => {
          alertify.success("Tag Created.");
          props.handleAddTag(true);
          clearForm();
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    }
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "code",
      "codeError",
      "Tag"
    );

    isFormValid = !validationService.hasError(newState, "codeError");

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  const clearForm = () => {
    let formToSet = {
      ...initialForm,
      parentLookupId: formState.parentLookupId,
    };
    setFormState(formToSet);
  };

  return (
    <Form className="w-30" id="incident-type-form">
      <Card.Body>
        <Grid.Row>
          <Grid.Col md={props.isForEvent ? 6 : 5} width={12}>
            <Form.Group label="Tag" isRequired>
              <Form.Input
                type="text"
                value={formState.code}
                name="code"
                onChange={handleInputChange}
                error={formState.codeError}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={props.isForEvent ? 6 : 5} width={12}>
            <Form.Group label="Group">
              <Form.Select
                name="parentLookupId"
                onChange={handleInputChange}
                value={formState.parentLookupId}
                error={formState.parentLookupIdError}
              >
                <option value={""}></option>
                {tagGroups.map((type) => (
                  <option value={type.id} key={type.id}>
                    {type.code}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={props.isForEvent ? 6 : 5} width={12}>
            <Form.Group label="Category">
              <Form.Select
                name="childLookupId"
                onChange={handleInputChange}
                value={formState.childLookupId}
                error={formState.childLookupIdError}
              >
                <option value={""}></option>
                {tagCategories.map((cat) => (
                  <option value={cat.id} key={cat.id}>
                    {cat.code}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={props.isForEvent ? 6 : 5} width={12}>
            <Form.Group label="Description">
              <Form.Input
                type="text"
                value={formState.description}
                name="description"
                onChange={handleInputChange}
              />
            </Form.Group>
          </Grid.Col>
          <Grid.Col md={props.isForEvent ? 12 : 2} width={12}>
            <Form.Group label="&nbsp;&nbsp;">
              <Button
                variant="contained"
                className="float-right"
                onClick={saveForm}
              >
                Add
              </Button>
            </Form.Group>
          </Grid.Col>
        </Grid.Row>
      </Card.Body>
    </Form>
  );
}
