import { apiUrl, client } from "../modules/store";
export function getSubmissions(params) {
  return client.get(apiUrl + "/portalsubmissions", {
    params: params,
  });
}

export function getSubmission(id) {
  return client.get(apiUrl + "/portalsubmissions/" + id);
}

export function createSubmission(dto) {
  return client.post(apiUrl + "/portalsubmissions", dto);
}

export function deleteSubmission(id) {
  return client.delete(apiUrl + "/portalsubmissions/" + id);
}

export function patchSubmission(id, jPatch) {
  var url = `${apiUrl}/portalsubmissions/${id}`;

  return client.patch(url, jPatch);
}
