import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import { Form } from "tabler-react";
import { MultiSelect } from "./../../form-controls/multi-select";
import { getUsersLookups } from "../../../services/users";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { useSelector } from "react-redux";
import { sendEmail } from "../../../services/email-service";
import alertify from "alertifyjs";
import _ from "lodash";
export function EventEmailModal(props) {
  var account = useSelector((state) => state.account);
  const [message, setMessage] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [usersError, setUsersError] = useState("");
  const [users, setUsers] = useState([]);
  const handleSendEmail = () => {
    if (_.isEmpty(selectedUsers)) {
      setUsersError("Recipients is required.");
      return;
    } else {
      setUsersError("");
    }

    const messageToSend = {
      to: selectedUsers.map((x) => x.email),
      subject: `[LYNX] ${account.firstName} ${account.lastName} shared event ${props.event.eventNumber} with you`,
      body: `<div>${account.firstName} ${
        account.lastName
      } shared an event with you.
      <a href="${window.location.href}">${window.location.href}</a>
      <br/>Event Number: ${props.event.eventNumber}
      <br/>Event Category: ${props.event.eventCategory}
     ${
       props.event.EventSubCategory
         ? `<br/>Event Category:${props.event.eventSubCategory}`
         : ""
     }
     ${message ? `<hr>${message}` : ``}
      </div>`,
    };
    sendEmail(messageToSend).then((res) => {
      alertify.success("Email sent.");
      props.handleClose();
    });
  };
  const handleChange = (e) => {
    setSelectedUsers(e.target.value);
  };

  useEffect(() => {
    getUsersLookups().then((res) => {
      setUsers(res.data);
    });
  }, []);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle>Email Event</DialogTitle>
      <DialogContent>
        <Form.Group label="Select Recipients" id="emailUsersInput" isRequired>
          <MultiSelect
            name="selectedUsers"
            id="selectedUsers"
            onChange={handleChange}
            value={selectedUsers}
            dropdownValues={users}
            key="id"
            label="fullName"
            open={dropdownOpen}
            onOpen={() => setDropdownOpen(true)}
            onClose={() => setDropdownOpen(false)}
            error={usersError}
          />
        </Form.Group>
        <Form.Group
          label="
Recipients will see the event number, event category / subcategory, a link to the event, and your message."
        >
          <Form.Textarea
            name="emailBody"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          ></Form.Textarea>
        </Form.Group>
      </DialogContent>
      <DialogActions className="pt-0">
        <Button
          variant="contained"
          className="mr-1 mb-2"
          onClick={props.handleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          className="mr-4 mb-2"
          onClick={handleSendEmail}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
