import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import alertify from "alertifyjs";
import TimeAgo from "javascript-time-ago";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dimmer, Form, Grid } from "tabler-react";
import colors from "../../../modules/lynxColors";
import {
  createComment,
  deleteComment,
  getCommentsForEntity,
  updateComment,
} from "../../../services/comment-service";
import { EventContactTypes } from "../../../types/enums";
import { LynxDialog } from "../../lynx-dialog";
import { ContactQuickCard } from "../contacts/contact-quick-card";
import { CommentsInput } from "./comments-input";
import { UserQuickCard } from "./user-quick-card";
import { getUserId } from "../../../actions/auth";
export function Comments(props) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [plainTextComment, setPlainTextComment] = useState("");
  const [commentCount, setCommentCount] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userData, setUserData] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedUser, setSelectedUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [commentToDelete, setCommentToDelete] = useState({});
  const [commentToEdit, setCommentToEdit] = useState({});
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  useEffect(() => {
    if (props.entityType && props.entityId) {
      loadComments();
    }
  }, [props.entityType, props.entityId]);

  const loadComments = () => {
    getCommentsForEntity(props.entityType, props.entityId).then((res) => {
      setComments(res.data);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    if (!_.isEmpty(props.users)) {
      var usersToSet = props.users.map((user) => ({
        id: user.id,
        display: user.fullName,
      }));
    }
    setUserData(usersToSet);
  }, [props.users]);
  const timeAgo = new TimeAgo("en-US");
  const getInitials = (fullName) => {
    if (fullName) {
      return fullName
        .split(" ")
        .map((n) => n[0])
        .join("");
    }
    return "";
  };

  const handleTagChipClick = (event, id, type) => {
    setAnchorEl(event.currentTarget);
    if (type !== "User") {
      setSelectedUser({});
      setSelectedContact({
        type: type,
        id: id.replace("c_", "").replace("g_", ""),
      });
    } else {
      setSelectedContact({});
      setSelectedUser({
        type: type,
        id: id,
      });
    }
  };

  const getTagChip = (id, type, name) => {
    return (
      <Chip
        size="small"
        sx={{ paddingBottom: "0.1rem" }}
        variant="outlined"
        label={name}
        onClick={(e) => {
          handleTagChipClick(e, id, type);
        }}
      ></Chip>
    );
  };
  const parser = (markup) => {
    var contactAndUserRegex = /(@|#)\[(.*?)]\((.*?)\)/g;
    var contactMatches = [...markup.matchAll(contactAndUserRegex)];
    //remove input property or it may be too large
    var chipArray = [];
    contactMatches.forEach((value) => {
      var tag = value[1];
      var name = value[2];
      var id = value[3];
      if (tag == "@") {
        //handle user
        chipArray.push(getTagChip(id, "User", name));
      }
      if (tag == "#") {
        if (id[0] == "c") {
          //handle contact
          chipArray.push(getTagChip(id, EventContactTypes.Contact, name));
        }
        if (id[0] == "g") {
          chipArray.push(getTagChip(id, EventContactTypes.ContactGroup, name));
          //handle group
        }
      }
    });

    var markupSplit = markup.split(contactAndUserRegex);
    var i = 0;
    var newArray = [];
    var matchIndex = 0;
    while (i < markupSplit.length) {
      if (i % 4 == 0) {
        newArray.push(<span>{markupSplit[i]}</span>);
        if (chipArray[matchIndex]) {
          newArray.push(chipArray[matchIndex]);
        }

        matchIndex++;
      }
      i++;
    }
    return newArray;
  };

  const handleAddComment = () => {
    if (_.trim(newComment) == "") {
      return;
    }
    setIsLoading(true);
    props.handleAddComment(true);
    const dtoToCreate = {
      eventId: props.entityId,
      comment: newComment,
      plainTextComment: plainTextComment,
    };
    createComment(dtoToCreate)
      .then((res) => {
        setComments(res.data);
        setNewComment("");
        setPlainTextComment("");
        setIsLoading(false);
        props.handleAddComment(false);
        alertify.success("Comment Added");
      })
      .catch((err) => {
        alertify.error(err.response.data.message);
        setIsLoading(false);
      });
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleCommentDelete = (comment) => {
    setCommentToDelete(comment);
    setShowDeleteDialog(true);
  };

  const handleConfirmDeleteComment = () => {
    setShowDeleteDialog(false);
    setIsLoading(true);
    deleteComment(commentToDelete.id)
      .then((res) => {
        alertify.success("Comment Deleted");
        setCommentToDelete({});
        loadComments();
      })
      .catch((err) => {
        alertify.error(err.response.data.message);
        setCommentToDelete({});
        setIsLoading(false);
      });
  };

  const handleCommentUpdate = () => {
    setIsLoading(true);
    updateComment(commentToEdit.id, commentToEdit)
      .then((res) => {
        alertify.success("Comment Saved");
        setCommentToEdit({});
        loadComments();
      })
      .catch((err) => {
        alertify.error(err.response.data.message);
        setCommentToEdit({});
        setIsLoading(false);
      });
  };
  return (
    <>
      <Dimmer active={isLoading} loader>
        <>
          <Grid.Col md={12} width={12}>
            <Form.Group label="Add Comment" className="mb-0">
              <CommentsInput
                value={newComment}
                users={userData}
                onChange={(e, n, p) => {
                  setNewComment(n);
                  setPlainTextComment(p);
                }}
              />
            </Form.Group>
            <Grid.Col md={12} width={12} className="pl-0 mb-2">
              <Typography variant="caption" className="">
                Type "@" to tag users or "#" to tag contacts or contact groups
              </Typography>
            </Grid.Col>
          </Grid.Col>

          <>
            <Grid.Col md={12} width={12}>
              <Button
                variant="outlined"
                className={_.isEmpty(comments) ? "mb-2" : ""}
                onClick={handleAddComment}
              >
                Add Comment
              </Button>
            </Grid.Col>
          </>
        </>
        {!_.isEmpty(comments) && (
          <Grid.Col md={12} width={12}>
            <Form.Group className="mb-1">
              <List>
                <Divider />
                {!_.isEmpty(comments) &&
                  comments.slice(0, commentCount).map((comment, index) => (
                    <React.Fragment key={_.toString(comment.id)}>
                      <ListItem
                        className="comment-item"
                        key={_.toString(comment.id)}
                        alignItems="flex-start"
                      >
                        {commentToEdit.id === comment.id ? (
                          <>
                            <ListItemAvatar>
                              <Avatar
                                src="/broken-image.jpg"
                                sx={{ bgcolor: colors.harvestOrange }}
                              >
                                {getInitials(comment.createdByUserFullName)}
                              </Avatar>
                            </ListItemAvatar>

                            <ListItemText
                              primary={
                                <CommentsInput
                                  style={{ width: "100%" }}
                                  value={commentToEdit.comment}
                                  users={userData}
                                  onChange={(e, n, p) => {
                                    setCommentToEdit({
                                      ...commentToEdit,
                                      comment: n,
                                      plainTextComment: p,
                                    });
                                  }}
                                />
                              }
                              secondary={
                                <div className="mt-2">
                                  <Link
                                    underline="hover"
                                    href="#"
                                    color="inherit"
                                    className="mr-2"
                                    onClick={() => {
                                      handleCommentUpdate();
                                    }}
                                  >
                                    <b>Save</b>
                                  </Link>
                                </div>
                              }
                            />
                          </>
                        ) : (
                          <>
                            <ListItemAvatar>
                              <Avatar
                                src="/broken-image.jpg"
                                sx={{ bgcolor: colors.harvestOrange }}
                              >
                                {getInitials(comment.createdByUserFullName)}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <React.Fragment key={_.toString(comment.id)}>
                                  <Typography
                                    sx={{ display: "inline" }}
                                    component="span"
                                    variant="body2"
                                    color="text.primary"
                                  >
                                    <b>{comment.createdByUserFullName}</b>
                                    &nbsp;-&nbsp;
                                    {timeAgo.format(
                                      _.toNumber(
                                        moment
                                          .utc(comment.createdDateTimeUtc)
                                          .valueOf()
                                      )
                                    )}
                                  </Typography>
                                  <Typography
                                    variant="body1"
                                    className="mt-1"
                                    sx={{
                                      whiteSpace: "pre-line",
                                      fontSize: "0.9rem",
                                    }}
                                  >
                                    {parser(comment.comment)}
                                  </Typography>
                                </React.Fragment>
                              }
                              secondary={
                                <>
                                  {comment.createdByUserId == getUserId() ? (
                                    <div className="mt-2">
                                      <Link
                                        underline="hover"
                                        href="#"
                                        color="inherit"
                                        className="mr-2"
                                        onClick={() => {
                                          setCommentToEdit(comment);
                                        }}
                                      >
                                        <b>Edit</b>
                                      </Link>
                                      <Link
                                        href="#"
                                        underline="hover"
                                        color="inherit"
                                        onClick={() =>
                                          handleCommentDelete(comment)
                                        }
                                      >
                                        <b>Delete</b>
                                      </Link>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              }
                            />
                          </>
                        )}
                      </ListItem>
                      <Divider />
                    </React.Fragment>
                  ))}
              </List>
              {commentCount < comments.length && (
                <Button
                  onClick={() => {
                    setCommentCount(commentCount + 5);
                  }}
                >
                  Show More
                </Button>
              )}
            </Form.Group>
          </Grid.Col>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          disableScrollLock
          onClose={handlePopoverClose}
        >
          {!_.isEmpty(selectedContact) && (
            <ContactQuickCard
              id={selectedContact.id}
              type={selectedContact.type}
            />
          )}
          {!_.isEmpty(selectedUser) && <UserQuickCard id={selectedUser.id} />}
        </Popover>
        {showDeleteDialog && (
          <LynxDialog
            open={showDeleteDialog}
            handleClose={() => setShowDeleteDialog(false)}
            handleDelete={handleConfirmDeleteComment}
            title={`Delete Comment?`}
            description={"The action can not be undone."}
          />
        )}
      </Dimmer>
    </>
  );
}
