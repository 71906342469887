import FactoryIcon from "@mui/icons-material/Factory";
import React from "react";
import { Form, Grid } from "tabler-react";
import { MultiSelect } from "../../form-controls/multi-select";

export const customFieldPrefix = "customFieldData.";
export function CustomField(props) {
  const getFieldLabel = () => {
    if (
      props.customField.fieldType == "Number" &&
      props.customField.isEmissionsField
    ) {
      return (
        <Form.Label>
          {props.customField.fieldLabel}{" "}
          <FactoryIcon color="action" sx={{ fontSize: 15, marginLeft: "2" }} />
        </Form.Label>
      );
    } else return props.customField.fieldLabel;
  };
  const fieldName = props.customField.fieldName;
  const field = props.formState[fieldName];
  const fieldError = props.formState[`${fieldName}Error`];
  return (
    <Grid.Col md={6} width={12}>
      <Form.Group isRequired={props.isRequired} label={getFieldLabel()}>
        {props.customField.fieldType == "Dropdown" && (
          <Form.Select
            name={`${fieldName}.textValue`}
            onChange={props.handleInputChange}
            value={field && field.textValue}
            error={fieldError}
            disabled={props.disabled}
          >
            <option value={""}></option>
            {props.customField.dropdownValues.split("|").map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Form.Select>
        )}
        {props.customField.fieldType == "MultiSelect" && (
          <MultiSelect
            disabled={props.disabled}
            error={fieldError}
            onChange={(e) => props.handleInputChange(e)}
            name={`${fieldName}.textValue`}
            dropdownValues={props.customField.dropdownValues.split("|")}
            value={field && field.textValue ? field.textValue.split("|") : []}
          />
        )}
        {props.customField.fieldType == "Text" && (
          <Form.Input
            type="text"
            name={`${fieldName}.textValue`}
            onChange={props.handleInputChange}
            value={field && field.textValue}
            error={fieldError}
            disabled={props.disabled}
          ></Form.Input>
        )}
        {props.customField.fieldType == "TextArea" && (
          <Form.Textarea
            name={`${fieldName}.textValue`}
            onChange={props.handleInputChange}
            value={field && field.textValue}
            error={fieldError}
            disabled={props.disabled}
          ></Form.Textarea>
        )}
        {props.customField.fieldType == "Number" && (
          <>
            <Form.Input
              type="number"
              name={`${fieldName}.numericValue`}
              onChange={props.handleInputChange}
              value={field && field.numericValue}
              error={fieldError}
              disabled={props.disabled}
            ></Form.Input>
          </>
        )}
        {props.customField.fieldType == "DateTime" && (
          <Form.Input
            type="datetime-local"
            name={`${fieldName}.dateTimeValue`}
            onChange={props.handleInputChange}
            value={field && field.dateTimeValue}
            error={fieldError}
            disabled={props.disabled}
          />
        )}
        {props.customField.fieldType == "Date" && (
          <Form.Input
            type="date"
            name={`${fieldName}.dateValue`}
            onChange={props.handleInputChange}
            value={field ? field.dateValue : ""}
            error={fieldError}
            disabled={props.disabled}
          />
        )}{" "}
      </Form.Group>
      {props.customField.fieldType == "Checkbox" && (
        <Form.Checkbox
          label={" "}
          name={`${fieldName}.booleanValue`}
          onChange={props.handleInputChange}
          checked={field && field.booleanValue}
          error={fieldError}
          disabled={props.disabled}
        />
      )}
    </Grid.Col>
  );
}
