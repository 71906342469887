import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Card, Dimmer } from "tabler-react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Link from "@mui/material/Link";
import { getWidgetData } from "../../../../services/dashboard-widget-service";
import { DashboardWidgetHeader } from "./dashboard-widget-header";
import { EntityTypes } from "../../../../types/enums";

export function CountListWidget(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [lastCalculatedDate, setLastCalculatedDate] = useState("");

  useEffect(() => {
    if (!_.isEmpty(props.widget)) {
      loadWidgetData(false);
    }
  }, [props.widget]);

  const loadWidgetData = (refreshData = false) => {
    setIsLoading(true);
    getWidgetData(props.widget.id, refreshData).then((res) => {
      setLastCalculatedDate(res.data.lastCalculatedDateTimeUtc);
      setRows(JSON.parse(res.data.dataJson));
      setIsLoading(false);
    });
  };

  const handleRefresh = () => {
    loadWidgetData(true);
  };

  const handleTableClick = (e, i) => {
    const index = i + 1;
    let filter = props.widget.dashboardWidgetFilters.find(
      (x) => x.sortOrder == index
    );
    if (
      _.toLower(filter.entityFilter.entityType) == _.toLower(EntityTypes.Event)
    ) {
      props.history.push(`/events?filterId=${filter.entityFilterId}`);
    }
    if (
      _.toLower(filter.entityFilter.entityType) ==
      _.toLower(EntityTypes.MonitoringEvent)
    ) {
      props.history.push(
        `/monitoring?view=events&filterId=${filter.entityFilterId}`
      );
    }
    if (
      _.toLower(filter.entityFilter.entityType) ==
      _.toLower(EntityTypes.MonitoringResult)
    ) {
      props.history.push(
        `/monitoring?view=results&filterId=${filter.entityFilterId}`
      );
    }
    if (
      _.toLower(filter.entityFilter.entityType) ==
      _.toLower(EntityTypes.Incident)
    ) {
      props.history.push(`/incidents?filterId=${filter.entityFilterId}`);
    }
    if (
      _.toLower(filter.entityFilter.entityType) == _.toLower(EntityTypes.Action)
    ) {
      props.history.push(`/actions?filterId=${filter.entityFilterId}`);
    }
    if (
      _.toLower(filter.entityFilter.entityType) ==
      _.toLower(EntityTypes.InspectionEvent)
    ) {
      props.history.push(`/inspections?filterId=${filter.entityFilterId}`);
    }
    if (
      _.toLower(filter.entityFilter.entityType) ==
      _.toLower(EntityTypes.EnergyLog)
    ) {
      props.history.push(`/energy-logs?filterId=${filter.entityFilterId}`);
    }
    if (
      _.toLower(filter.entityFilter.entityType) ==
      _.toLower(EntityTypes.ProgramEvent)
    ) {
      props.history.push(`/programs?filterId=${filter.entityFilterId}`);
    }
  };

  return (
    <Card>
      <DashboardWidgetHeader
        handleSortChange={props.handleSortChange}
        handleDelete={props.handleDelete}
        upDisabled={props.upDisabled}
        downDisabled={props.downDisabled}
        widget={props.widget}
        handleRefresh={handleRefresh}
        lastCalculatedDate={lastCalculatedDate}
        title={props.title}
      />
      <Dimmer active={props.loading ?? isLoading} loader>
        <div
          style={{
            height: isLoading && props.widget ? 400 : "100%",
            padding: 0,
            width: "100%",
          }}
        >
          <TableContainer>
            <Table sx={{ minWidth: "100%" }} aria-label="count-list-table">
              <TableHead>
                <TableRow>
                  <TableCell>Filter</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.orderBy(props.rows ?? rows, "sortOrder").map((row, i) => (
                  <TableRow key={row.filterName}>
                    <TableCell>{row.filterName}</TableCell>
                    <TableCell>
                      {!row.inactive ? (
                        <Link
                          underline="none"
                          component="button"
                          onClick={() => handleTableClick(row, i)}
                        >
                          {row.entityCount}
                        </Link>
                      ) : (
                        <div>Module Inactive</div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Dimmer>
    </Card>
  );
}
