import { apiUrl, client } from "../modules/store";

export function deleteUser(userId) {
  return client.delete(apiUrl + "/admin/users/" + userId);
}

export function updateUserAdmin(userId, dto) {
  return client.patch(apiUrl + "/admin/users/" + userId, {
    ...dto,
  });
}

export function addUserAdmin(dto) {
  return client.post(apiUrl + "/admin/users", {
    ...dto,
  });
}

export function addOrganization(org) {
  return client.post(apiUrl + "/admin/organizations", { ...org });
}
export function deleteOrganization(orgId) {
  return client.delete(apiUrl + "/admin/organizations/" + orgId);
}
export function getOrganization(id) {
  return client.get(apiUrl + "/admin/organizations/" + id);
}

export function getOrganizations(params) {
  return client.get(apiUrl + "/admin/organizations/", {
    params: params,
  });
}
export function updateOrganization(id, dto) {
  return client.put(apiUrl + "/admin/organizations/" + id, dto);
}

export function getUserToken(id) {
  return client.get(apiUrl + "/auth/superadminlogin/" + id);
}
