import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory } from "react-router-dom";
import { dateUtil } from "../../../services/date-util";
import { statusColorSwitch } from "./action-columns";
export function ActionMobileCard(props) {
  const history = useHistory();
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const action = props.entity;
  return (
    <Card variant="outlined" className="w-100">
      <CardContent className="pb-0 pt-1">
        <Typography variant="h6">
          <Link
            href="#"
            underline="none"
            onClick={(e) => handleNavigateTo(e, `actions/${action.id}`)}
          >
            {action.actionNumber}
          </Link>
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          Asset: {action.assetName}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          Soruce: {action.sourceNumber}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {action.closedDateTime
            ? "Closed: " +
              dateUtil.convertDateTimeToLocal(action.closedDateTime)
            : "Due: " + dateUtil.convertDateOnlyToLocal(action.dueDate)}
        </Typography>
        <Typography variant="h6">{action.title}</Typography>
        <Typography color="text.secondary" sx={{ mb: 1 }}>
          {action.category}
        </Typography>
        <Typography sx={{ m1: 1 }} variant="body2">
          Priority: {action.priority || "-"}
        </Typography>
        <Typography sx={{ mb: 1 }} variant="body2">
          Description: {action.description || "-"}
        </Typography>

        <Typography variant="body2" sx={{ mb: 1 }}>
          <i
            className={"fe fe-circle "}
            style={{ color: statusColorSwitch(action.status) }}
          />
          &nbsp;Status: {action.status || "None"}
        </Typography>
      </CardContent>
    </Card>
  );
}
