import { apiUrl, client } from "../modules/store";

export function getLastSyncCacheInvalidated() {
  return client.get(`${apiUrl}/organization/syncInvalidated`);
}

export function uploadLogo(form) {
  return client.post(apiUrl + "/organization/logo/", form);
}

export function getLogo() {
  return client
    .get(apiUrl + `/organization/logo`, {
      responseType: "blob",
    })
    .then((res) => res);
}

export function getOrgLogo(orgId) {
  return client
    .get(apiUrl + `/organization/logo/${orgId}`, {
      responseType: "blob",
    })
    .then((res) => res);
}
