import React, { useState, useEffect } from "react";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import moment from "moment";
import { getKpiHistoryById } from "./../../../services/kpi-service";
import Box from "@mui/material/Box";
import _ from "lodash";
import { prepareLastCalculatedValues } from "./kpi-dashboard";
const kpiValueColumns = [
  {
    field: "exceedsThreshold",
    headerName: "Status",
    width: 60,
    type: "boolean",
    renderCell: (params) => (
      <div>
        <>
          <i
            className={`fe fe-circle ${
              params.value == true ? "text-danger" : "text-success"
            }`}
          />
        </>
      </div>
    ),
  },
  {
    field: "runDateTimeUtc",
    headerName: "Run Date",
    width: 150,
    type: "dateTime",
    renderCell: (params) => (
      <div>
        {params.value
          ? moment.utc(params.value).local().format("YYYY-MM-DD HH:mm:ss")
          : ""}
      </div>
    ),
  },
  {
    field: "value",
    headerName: "Value",
    width: 200,
    type: "number",
    valueGetter: (params) => {
      if (!params.value) {
        return params.value;
      }

      return parseFloat(params.value).toFixed(8);
    },
  },
  {
    field: "threshold",
    headerName: "Threshold",
    width: 100,
    type: "number",
  },
];

export function KpiHistoryList(props) {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let newColumns = [...kpiValueColumns];
    if (!_.isEmpty(props.customFields) && !_.isEmpty(props.kpi)) {
      let valuesArray = prepareLastCalculatedValues(
        props.kpi.lastCalculatedValues,
        props.customFields
      );
      for (let i = 0; i < valuesArray.length; i++) {
        newColumns.push({
          field: valuesArray[i][2],
          headerName: valuesArray[i][0],
          width: 200,
          type: "number",
          filterable: false,
          sortable: false,
          renderCell: (params) => {
            if (params.row.calculatedValues) {
              let object = JSON.parse(params.row.calculatedValues);
              return <div>{object[valuesArray[i][2]]}</div>;
            } else return <></>;
          },
        });
      }
      newColumns.push({
        field: "populatedEquation",
        headerName: "Calculation",
        width: 300,
        type: "string",
      });
      setColumns(newColumns);
    }
  }, [props.customFields, props.kpi.lastCalculatedValues, props.kpi]);

  const getDataFunction = (params) => {
    return getKpiHistoryById(props.kpi.id, params);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "calc(100% - 119px)",
        bgcolor: "background.paper",
      }}
    >
      <LynxDataGrid
        columns={columns}
        getDataFunction={getDataFunction}
        onlyShowTable
        entityName="kpihistory"
        localStorageName={`kpivalues_${props.kpi.id}`}
        {...props}
      />
    </Box>
  );
}
