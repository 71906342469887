import * as React from "react";
import { Icon } from "tabler-react";
import { roleMatch } from "../../../../actions/auth";
import { UserRoles } from "../../../../types/enums";
export const tagGroupColumns = [
  {
    field: "code",
    headerName: "Group",
    width: 200,
    type: "string",
  },

  {
    field: "description",
    headerName: "Description",
    width: 250,
    type: "string",
  },

  {
    field: "delete",
    headerName: "",
    width: 45,
    type: "",
    cellClassName: "icon-settings",
    renderCell: (params) => (
      <div>
        {roleMatch([UserRoles.Admin]) && !params.row.isInUse && (
          <Icon className="display-5 mb-3" name="trash" />
        )}
      </div>
    ),
  },

  {
    field: "edit",
    headerName: "",
    width: 45,
    type: "",
    cellClassName: "icon-settings",
    renderCell: (params) => (
      <div>
        <Icon className="display-5 mb-3" name="edit" />
      </div>
    ),
  },
];

export const tagCategoryColumns = [
  {
    field: "code",
    headerName: "Category",
    width: 200,
    type: "string",
  },

  {
    field: "description",
    headerName: "Description",
    width: 250,
    type: "string",
  },

  {
    field: "delete",
    headerName: "",
    width: 45,
    type: "",
    cellClassName: "icon-settings",
    renderCell: (params) => (
      <div>
        {roleMatch([UserRoles.Admin]) && !params.row.isInUse && (
          <Icon className="display-5 mb-3" name="trash" />
        )}
      </div>
    ),
  },

  {
    field: "edit",
    headerName: "",
    width: 45,
    type: "",
    cellClassName: "icon-settings",
    renderCell: (params) => (
      <div>
        <Icon className="display-5 mb-3" name="edit" />
      </div>
    ),
  },
];

export const tagColumns = [
  {
    field: "parentLookupCode",
    headerName: "Group",
    width: 100,
    type: "string",
  },
  {
    field: "code",

    headerName: "Tag",
    width: 200,
    type: "string",
  },
  {
    field: "childLookupCode",
    headerName: "Category",
    width: 200,
    type: "string",
  },
  {
    field: "description",
    headerName: "Description",
    width: 150,
    type: "string",
  },

  {
    field: "delete",
    headerName: "",
    width: 40,
    type: "",

    cellClassName: "icon-settings",
    renderCell: (params) => (
      <div>
        {roleMatch([UserRoles.Admin]) && !params.row.isInUse && (
          <Icon className="display-5 mb-3" name="trash" />
        )}
      </div>
    ),
  },

  {
    field: "edit",
    headerName: "",
    width: 40,
    type: "",
    cellClassName: "icon-settings",
    renderCell: (params) => (
      <div>
        <Icon className="display-5 mb-3" name="edit" />
      </div>
    ),
  },
];

export const tagEditColumns = [
  {
    field: "parentLookupCode",
    headerName: "Framework",
    width: 200,
    type: "string",
  },
  {
    field: "code",

    headerName: "Code",
    width: 200,
    type: "string",
  },
  {
    field: "childLookupCode",
    headerName: "Category",
    width: 200,
    type: "string",
  },
  {
    field: "description",
    headerName: "Description",
    width: 400,
    type: "string",
  },
];
