import React, { useState, useEffect } from "react";
import { Grid, Card } from "tabler-react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { AddLookupHeader } from "./../add-lookup-header";
import { getLookups } from "./../../../../services/lookup";
import { getParameters } from "./../../../../services/parameters";
import { AddParameterHeader } from "./add-parameter-header";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";
import { EditLookupModal } from "./../edit-lookup-modal";
import { ParameterModal } from "./parameter-modal";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";
import { monitoringCustomFieldColumns } from "./monitoring-custom-field-columns";
import {
  updateCustomFieldSort,
  getCustomFields,
} from "../../../../services/custom-fields-service";
import { EntityTypes, LookupTypes } from "../../../../types/enums";
import { CustomFieldModal } from "../EventSettings/custom-field-modal";
import useWindowDimensions from "./../../../../hooks/useWindowDimensions";
import { getParameterGroups } from "../../../../services/parameter-group-service";
import { ParameterGroupModal } from "./parameter-group-modal";
import { useSelector } from "react-redux";
import { useGetUnitConversionsQuery } from "services/rtkApi/endpoints/unitConversion";
import { AddUnitConversion } from "./add-unit-conversion";
import { EditUnitConversion } from "./edit-unit-conversion";
export function MonitoringSettings() {
  var organization = useSelector((state) => state.organization);
  console.log({organization})
  const editFieldProps = {
    field: "Actions",
    headerName: "",
    width: 50,
    type: "actions",
    resizable: false,
    disableColumnMenu: true,
    disableReorder: true,
    disableExport: true,
    hideSortIcons: true,
  };
  const defaultColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditMonEventType(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "code",
      headerName: "Name",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const colorColumn = {
    field: "color",
    headerName: "Color",
    width: 200,
    type: "string",
    renderCell: (params) => {
      return (
        <i className={"dot "} style={{ backgroundColor: params.row.color }} />
      );
    },
  };

  const parameterColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  handleEditParameter(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },

    {
      field: "defaultUnitName",
      headerName: "Default Unit",
      width: 250,
      type: "string",
    },
  ];

  const parameterGroupColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  setSelectedParameterGroupId(params.row.id);
                  setShowParameterGroupModal(true);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      type: "string",
    },

    {
      field: "description",
      headerName: "Description",
      width: 250,
      type: "string",
    },
  ];

  const unitConvColumns = [
    {
      ...editFieldProps,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  setSelectedConversion(params.row);
                }}
                aria-label="Edit"
                size="small"
                className="grid-edit-button"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "fromUnitCode",
      headerName: "From Unit",
      width: 250,
      type: "string",
    },
    {
      field: "toUnitCode",
      headerName: "To Unit",
      width: 250,
      type: "string",
    },
    {
      field: "conversionFactor",
      headerName: "Conversion Factor",
      width: 500,
      type: "string",
    },
  ];
  const [monitoringEventTypes, setMonitoringEventTypes] = useState([]);
  const [monEventTypesLoading, setMonEventTypesLoading] = useState(true);
  const [monitoringResultTypes, setMonitoringResultTypes] = useState([]);
  const [monitoringLimitTypes, setMonitoringLimitTypes] = useState([]);
  const [parameterGroups, setParameterGroups] = useState([]);
  const [parameterGroupsLoading, setParameterGroupsLoading] = useState(true);
  const [monitoringLimitTypesLoading, setMonitoringLimitTypesLoading] =
    useState(true);
  const [monitoringResultTypesLoading, setMonitoringResultTypesLoading] =
    useState(true);
  const [monitoringLocationTypes, setMonitoringLocationTypes] = useState([]);
  const [monitoringLocationTypesLoading, setMonitoringLocationTypesLoading] =
    useState(true);
  const [units, setUnits] = useState([]);
  const [unitsLoading, setUnitsLoading] = useState(true);
  const [parameters, setParameters] = useState([]);
  const [parametersLoading, setParametersLoading] = useState(true);
  const [showLookupModal, setShowLookupModal] = useState(false);
  const [selectedLookup, setSelectedLookup] = useState({});
  const [showParameterModal, setShowParameterModal] = useState(false);
  const [selectedParameter, setSelectedParamter] = useState({});
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false);
  const [monitoringCustomFields, setMonitoringCustomFields] = useState([]);
  const [selectedCustomField, setSelectedCustomField] = useState({});
  const [showParameterGroupModal, setShowParameterGroupModal] = useState(false);
  const [selectedParameterGroupId, setSelectedParameterGroupId] =
    useState(null);
  const dimensions = useWindowDimensions();
  const history = useHistory();

  const [selectedConversion, setSelectedConversion] = useState();
  const { data: unitConvList, isFetching: unitConvIsFetching } =
    useGetUnitConversionsQuery();

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadMonitoringEvents();
    loadUnits();
    loadParameters();
    loadCustomFields();
    loadMonitoringResultTypes();
    loadMonitoringLimitTypes();
    loadMonitoringLocationTypes();
    loadParameterGroups();
  };

  const loadMonitoringEvents = () => {
    getLookups(LookupTypes.MonitoringEventType).then((res) => {
      setMonitoringEventTypes(res.data);
      setMonEventTypesLoading(false);
    });
  };

  const loadParameterGroups = () => {
    getParameterGroups().then((res) => {
      setParameterGroups(res.data);
      setParameterGroupsLoading(false);
    });
  };

  const loadMonitoringLocationTypes = () => {
    getLookups(LookupTypes.MonitoringLocationType).then((res) => {
      setMonitoringLocationTypes(res.data);
      setMonitoringLocationTypesLoading(false);
    });
  };

  const loadMonitoringResultTypes = () => {
    getLookups(LookupTypes.MonitoringResultType).then((res) => {
      setMonitoringResultTypes(res.data);
      setMonitoringResultTypesLoading(false);
    });
  };

  const loadMonitoringLimitTypes = () => {
    getLookups(LookupTypes.MonitoringLimitType).then((res) => {
      setMonitoringLimitTypes(res.data);
      setMonitoringLimitTypesLoading(false);
    });
  };
  const loadUnits = () => {
    getLookups(LookupTypes.Unit).then((res) => {
      setUnits(res.data);
      setUnitsLoading(false);
    });
  };

  const loadParameters = () => {
    getParameters().then((res) => {
      setParameters(res.data);
      setParametersLoading(false);
    });
  };

  const handleEditMonEventType = (lookup) => {
    setShowLookupModal(true);
    setSelectedLookup(lookup);
  };

  const handleEditParameter = (parameter) => {
    setShowParameterModal(true);
    setSelectedParamter(parameter);
  };

  const handleLookupModalClose = () => {
    setShowLookupModal(false);
    setSelectedLookup({});
  };

  const handleParameterModalClose = () => {
    setShowParameterModal(false);
    setSelectedParamter({});
  };

  const handleParameterSave = () => {
    handleParameterModalClose();
    loadParameters();
  };

  const handleLookupSave = () => {
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() == LookupTypes.Unit.toLowerCase()
    ) {
      loadUnits();
      loadParameters();
    }
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() ==
        LookupTypes.MonitoringEventType.toLowerCase()
    ) {
      loadMonitoringEvents();
    }
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() ==
        LookupTypes.MonitoringResultType.toLowerCase()
    ) {
      loadMonitoringResultTypes();
    }
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() ==
        LookupTypes.MonitoringLimitType.toLowerCase()
    ) {
      loadMonitoringLimitTypes();
    }
    if (
      selectedLookup &&
      selectedLookup.lookupType &&
      selectedLookup.lookupType.toLowerCase() ==
        LookupTypes.MonitoringLocationType.toLowerCase()
    ) {
      loadMonitoringLocationTypes();
    }
    handleLookupModalClose();
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const handleRowOrderChange = (e) => {
    updateCustomFieldSort({
      entityId: e.row.id,
      newPosition: e.targetIndex + 1,
      entityType: EntityTypes.MonitoringEvent,
    });
    let newValues = [...monitoringCustomFields];
    let oldValue = newValues.splice(e.oldIndex, 1);
    newValues.splice(e.targetIndex, 0, ...oldValue);
    setMonitoringCustomFields(newValues);
  };

  const handleCustomFieldEdit = (e) => {
    if (e.field == "edit") {
      setSelectedCustomField(
        monitoringCustomFields.find((x) => x.id == e.row.id)
      );
      setShowCustomFieldModal(true);
    }
  };
  const loadCustomFields = () => {
    getCustomFields(EntityTypes.MonitoringEvent).then((res) => {
      setMonitoringCustomFields(res.data);
    });
  };
  const handleCustomFieldModalClosed = (refresh) => {
    setShowCustomFieldModal(false);

    loadCustomFields();
    setSelectedCustomField({});
  };

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant={
                  dimensions && dimensions.width && dimensions.width < 450
                    ? "h6"
                    : "h3"
                }
                component="div"
              >
                Monitoring Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">Monitoring Settings</Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Monitoring Event Types
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Monitoring event types are used to select the type of monitoring
              being carried out for a monitoring event (e.g., blast noise, air
              quality, weekly water sample etc.).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Name"
              descriptionLabel="Description"
              lookupType="MonitoringEventType"
              lookupName="Monitoring Event Type"
              handleAddLookup={() => loadMonitoringEvents()}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={monitoringEventTypes}
                columns={defaultColumns}
                disableMultipleSelection={true}
                loading={monEventTypesLoading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Monitoring Form Additional Fields
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Additional fields are used to customize the fields used to enter
              data for an Event Type on the Add Monitoring Event form. You can
              create and assign additional fields here.
            </Typography>
          </div>
        </Grid.Col>

        <Grid.Col md={10} width={12}>
          <Card>
            <Card.Header className="justify-content-end">
              <Button
                variant="contained"
                onClick={() => setShowCustomFieldModal(true)}
              >
                Add
              </Button>
            </Card.Header>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                columns={monitoringCustomFieldColumns}
                rows={monitoringCustomFields}
                onRowOrderChange={handleRowOrderChange}
                onCellClick={handleCustomFieldEdit}
                rowReordering
                disableColumnReorder
                disableColumnFilter
                disableColumnMenu
                disableSelectionOnClick
                disableChildrenSorting
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Monitoring Location Types
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Monitoring location types are used to classify monitoring
              locations (Ground Water, Surface Water, Air Monitoring etc.)
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Name"
              hasColor
              descriptionLabel="Description"
              lookupType={LookupTypes.MonitoringLocationType}
              lookupName="Monitoring Location Type"
              handleAddLookup={() => loadMonitoringLocationTypes()}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={monitoringLocationTypes}
                columns={[...defaultColumns, colorColumn]}
                disableMultipleSelection={true}
                loading={monitoringLocationTypesLoading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Monitoring Parameter and Limit Types
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Monitoring parameter and limit types are used to classify your
              parameter templates and monitoring limit types.
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Name"
              descriptionLabel="Description"
              lookupType="MonitoringLimitType"
              lookupName="Monitoring Parameter and Limit Type"
              handleAddLookup={() => loadMonitoringLimitTypes()}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={monitoringLimitTypes}
                columns={defaultColumns}
                disableMultipleSelection={true}
                loading={monitoringLimitTypesLoading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Monitoring Result Types
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Monitoring result types are used to select the type of result
              being added to a monitoring event (e.g., Field, Lab, QA/QC etc.).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Name"
              descriptionLabel="Description"
              lookupType="MonitoringResultType"
              lookupName="Monitoring Result Type"
              handleAddLookup={() => loadMonitoringResultTypes()}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={monitoringResultTypes}
                columns={defaultColumns}
                disableMultipleSelection={true}
                loading={monitoringResultTypesLoading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Parameters
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Monitoring Parameters are used on the Add Monitoring Event form
              (e.g., Cyanide, Noise).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddParameterHeader
              units={units}
              handleAddParameter={() => loadParameters()}
            ></AddParameterHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={parameters}
                columns={parameterColumns}
                disableMultipleSelection={true}
                loading={parametersLoading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      {organization.featureFlags.showInDevelopmentFeatures && (
        <Grid.Row>
          <Grid.Col md={2} width={12}>
            <div className="">
              <Typography variant="h5" component="div">
                Parameter Groups
              </Typography>
              <Typography variant="body2" className="mt-2" component="div">
                Parameter Groups are used to group your parameters for easier
                data entry and reporting purposes. Parameter groups can be
                associated to monitoring location types to filter the parameter
                list for data entry, and can be used to easily create reports
                and dashboard widgets. (e.g., Ground Water Parameters, Quarterly
                Reporting Air Parameters).
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col md={10} width={12}>
            <Card>
              <Card.Header className="justify-content-end">
                <Button
                  variant="contained"
                  onClick={() => setShowParameterGroupModal(true)}
                >
                  Add
                </Button>
              </Card.Header>
              <div style={{ height: 630, width: "100%" }}>
                <DataGridPro
                  rows={parameterGroups}
                  columns={parameterGroupColumns}
                  disableMultipleSelection={true}
                  loading={parameterGroupsLoading}
                />
              </div>
            </Card>
          </Grid.Col>
        </Grid.Row>
      )}
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Units
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              A standard of measurement for a quantity on the Add Monitoring
              Event form (e.g., dB, mg/L).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <AddLookupHeader
              codeLabel="Name"
              descriptionLabel="Description"
              lookupType="Unit"
              lookupName="Unit"
              handleAddLookup={() => loadUnits()}
            ></AddLookupHeader>

            <div style={{ height: 630, width: "100%" }}>
              <DataGridPro
                rows={units}
                columns={defaultColumns}
                disableMultipleSelection={true}
                loading={unitsLoading}
              />
            </div>
          </Card>
        </Grid.Col>
      </Grid.Row>
      {organization.featureFlags.showInDevelopmentFeatures && (
        <Grid.Row>
          <Grid.Col md={2} width={12}>
            <div className="">
              <Typography variant="h5" component="div">
                Unit Conversions
              </Typography>
              <Typography variant="body2" className="mt-2" component="div">
                Convert units based on factor
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col md={10} width={12}>
            <Card>
              <AddUnitConversion unitList={units} />

              <div style={{ height: 630, width: "100%" }}>
                <DataGridPro
                  rows={unitConvList || []}
                  columns={unitConvColumns}
                  loading={unitConvIsFetching}
                />
              </div>
            </Card>
          </Grid.Col>
        </Grid.Row>
      )}
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showLookupModal}
        onClose={handleLookupModalClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <EditLookupModal
          lookup={selectedLookup}
          codeLabel="Name"
          descriptionLabel="Description"
          handleModalClose={handleLookupModalClose}
          handleLookupSave={handleLookupSave}
          lookupName={
            selectedLookup.lookupType
              ? selectedLookup.lookupType.toLowerCase() == "unit"
                ? "Unit"
                : selectedLookup.lookupType.toLowerCase() ==
                  "monitoringeventtype"
                ? "Monitoring Event Type"
                : ""
              : ""
          }
        />
      </Dialog>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showParameterModal}
        onClose={handleParameterModalClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <ParameterModal
          parameter={selectedParameter}
          handleModalClose={handleParameterModalClose}
          handleParameterSave={handleParameterSave}
          units={units}
        />
      </Dialog>{" "}
      {showCustomFieldModal && (
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showCustomFieldModal}
          onClose={() => {
            setShowCustomFieldModal(false);
            setSelectedCustomField({});
          }}
          disableBackdropClick
        >
          <CustomFieldModal
            handleModalClose={handleCustomFieldModalClosed}
            customField={selectedCustomField}
            entityType={EntityTypes.MonitoringEvent}
          ></CustomFieldModal>
        </Dialog>
      )}
      {showParameterGroupModal && (
        <Dialog
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={showParameterGroupModal}
          onClose={() => {
            setShowParameterGroupModal(false);
            setSelectedParameterGroupId(null);
          }}
          disableBackdropClick
        >
          <ParameterGroupModal
            parameters={parameters}
            handleModalClose={() => {
              setShowParameterGroupModal(false);
              setSelectedParameterGroupId(null);
              loadParameterGroups();
            }}
            parameterGroupId={selectedParameterGroupId}
          ></ParameterGroupModal>
        </Dialog>
      )}
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={Boolean(selectedConversion)}
        onClose={() => {
          setSelectedConversion(undefined);
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <EditUnitConversion
          unitList={units}
          unitConversion={selectedConversion}
          handleModalClose={() => {
            setSelectedConversion(undefined);
          }}
        />
      </Dialog>
    </Grid>
  );
}
