import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import _ from "lodash";
import { Form, Grid } from "tabler-react";
import { EntityTypes } from "../../../types/enums";
import { getMonitoringLocations } from "../../../services/monitoring-location-service";
export function Step6(props) {
  const [monitoringLocations, setMonitoringLocations] = useState([]);
  useEffect(() => {
    if (props.entityType == EntityTypes.TimeSeries) {
      getMonitoringLocations({ pageSize: 10000, isTimeSeries: true }).then(
        (res) => {
          setMonitoringLocations(res.data);
        }
      );
    }
  }, []);
  return (
    <>
      {props.entityType == EntityTypes.TimeSeries && (
        <Grid>
          <Grid.Row>
            <Grid.Col md={12} width={12}>
              <Form.Group
                isRequired
                label="Select Monitoring Location"
                id="monitoringLocaitonId"
              >
                <Form.Select
                  className="monitoringLocationId"
                  name="monitoringLocationId"
                  onChange={props.handleInputChange}
                  data-id={"1"}
                  value={props.formState.monitoringLocationId}
                  error={props.formState.monitoringLocationIdError}
                >
                  {!_.isEmpty(monitoringLocations) && (
                    <>
                      <option value={""}></option>
                      {monitoringLocations.map((monLoc) => (
                        <option
                          value={monLoc.id}
                          isSelected={
                            _.toString(props.formState.monitoringLocationId) ==
                            _.toString(monLoc.id)
                          }
                          className="mon-loc-option"
                          key={monLoc.id}
                        >
                          {monLoc.name}
                        </option>
                      ))}
                    </>
                  )}
                </Form.Select>
              </Form.Group>
            </Grid.Col>
          </Grid.Row>
        </Grid>
      )}
      {_.isEmpty(props.selectedSpec) && (
        <>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={props.isSaveNew}
                  onChange={(e) => {
                    props.setIsSaveNew(e.target.checked);
                  }}
                />
              }
              label="Save configuration for future import?"
            />
          </FormControl>
          {props.isSaveNew && (
            <Grid>
              <Grid.Row>
                <Grid.Col md={12} width={12}>
                  <Form.Group isRequired label="Name" id="name">
                    <Form.Input
                      name="name"
                      error={props.formState.nameError}
                      onChange={props.handleInputChange}
                      value={props.formState.name}
                    ></Form.Input>
                  </Form.Group>
                </Grid.Col>
                <Grid.Col md={12} width={12}>
                  <Form.Group label="Description">
                    <Form.Textarea
                      name="description"
                      onChange={props.handleInputChange}
                      value={props.formState.description}
                    ></Form.Textarea>
                  </Form.Group>
                </Grid.Col>
              </Grid.Row>
            </Grid>
          )}
        </>
      )}

      <Box sx={{ mb: 2 }}>
        <div>
          <Button
            variant="contained"
            onClick={props.handleNext}
            sx={{ mt: 1, mr: 1 }}
          >
            Import
          </Button>
          <Button onClick={props.handleBack} sx={{ mt: 1, mr: 1 }}>
            Back
          </Button>
        </div>
      </Box>
    </>
  );
}
