import React, { useState, useEffect, useRef } from "react";
import Container from "@mui/material/Container";
import { ReactComponent as ReactLogo } from "./logo.svg";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import "./microsites.css";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Grid } from "tabler-react";
import { BarChartWidget } from "../dashboards/dashboards/widgets/bar-chart-widget";
import { DonutChartWidget } from "../dashboards/dashboards/widgets/donut-chart-widget";
import { BadgeWidget } from "../dashboards/dashboards/widgets/badge-widget";
import lynxColors from "../../modules/lynxColors";
import { MapWidget } from "../dashboards/dashboards/widgets/map-widget";
import demoGeojson from "./demo.geojson";
import { LineChartWidget } from "../dashboards/dashboards/widgets/line-chart-widget";
const companyName = "Lynx Nickel Corp";
export function DemoMicrosite(props) {
  const [profileTabValue, setProfileTabValue] = useState("1");
  const [communityTabValue, setCommunityTabValue] = useState("1");

  const waterRef = useRef();
  const profileRef = useRef();
  const emissionsRef = useRef();
  const healthAndSafetyRef = useRef();
  const communityRef = useRef();
  const handleProfileTabChange = (event, newValue) => {
    setProfileTabValue(newValue);
  };
  const handleCommunityTabChange = (event, newValue) => {
    setCommunityTabValue(newValue);
  };

  const engagmentChartData = [
    { name: "Deer Lake", value: 3 },
    { name: "Keewaywin", value: 3 },
    { name: "Poplar Hill", value: 15 },
  ];

  const projectChartData = [
    { name: "Project 1", value: 12221 },
    { name: "Project 2", value: 4552 },
    { name: "Project 3", value: 6125 },
  ];

  const emissionsData = [
    { name: "Scope 1", value: 50.5 },
    { name: "Scope 2", value: 48.8 },
    { name: "Scope 3", value: 277.8 },
  ];
  const menuOptions = [
    ["Company Profile", profileRef],
    ["Community Commitment", communityRef],
    ["Emissions", emissionsRef],
    ["Health and Safety", healthAndSafetyRef],
    ["Water and Environment", waterRef],
  ];

  const getMicrositesSidebar = () => {
    return (
      <div className="micro-sidebar">
        <div className="logo-container">
          <ReactLogo />
        </div>
        <div className="micro-sidebar-content">
          {menuOptions.map((opt) => (
            <div className="micro-menu-button" key={opt[0]}>
              <Typography
                className="menu-text"
                onClick={() => {
                  opt[1].current.scrollIntoView({
                    block: "start",
                    behavior: "smooth",
                  });
                }}
              >
                {opt[0]}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const getWaterCard = () => {
    let card = (
      <Card
        ref={waterRef}
        variant="outlined"
        sx={{ width: "100%" }}
        className="lynx-card mb-5"
      >
        <CardHeader
          className="lynx-card-header"
          title="Environmental Monitoring for Water and Air"
        />

        <Divider />
        <CardContent>
          <Grid>
            <Grid.Row>
              <Grid.Col md={6} width={12}>
                <div className="text-container">
                  <Typography>
                    Lynx Nickel Corp. employs comprehensive environmental
                    monitoring programs to protect water and air quality in and
                    around our mining site. We conduct regular testing and
                    monitoring of water sources and air quality to ensure
                    compliance with environmental regulations and to mitigate
                    any potential impacts on the ecosystem. During construction
                    and blasting we monitor Respirable Crystalline Silica.
                  </Typography>
                </div>
                <BadgeWidget
                  title={"Total Water Monitoring Events (2023)"}
                  value={"423"}
                  unit={"events"}
                  subValue={"From 7 locations"}
                  className="mt-2 mr-2"
                />
                <BadgeWidget
                  title={"Total Air Monitoring Events (2023)"}
                  value={"1503"}
                  unit={"events"}
                  subValue={"Daily readings across 5 sensors"}
                  className="mt-2 mr-2"
                />
              </Grid.Col>

              <Grid.Col md={6} width={12}>
                <MapWidget geojson={demoGeojson} />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </CardContent>
      </Card>
    );
    return card;
  };
  const getHealthAndSafetyCard = () => {
    let card = (
      <Card
        ref={healthAndSafetyRef}
        variant="outlined"
        sx={{ width: "100%" }}
        className="lynx-card mb-5"
      >
        <CardHeader className="lynx-card-header" title="Health and Safety" />

        <Divider />
        <CardContent>
          <Grid>
            <Grid.Row>
              <Grid.Col md={6} width={12}>
                <div className="text-container">
                  <Typography>
                    The health and safety of our employees, contractors, and
                    community members are paramount at Lynx Nickel Corp. We
                    adhere to the highest standards of occupational health and
                    safety, implementing rigorous training programs, safety
                    protocols, and emergency response plans to ensure a safe
                    working environment for all. Our training programs go
                    above-and-beyond what is required in Ontario, and our safety
                    record speaks for itself, with few injuries and no
                    fatalities over the past years.
                  </Typography>
                </div>
                <BadgeWidget
                  title={"Lost Time Incidents (2023)"}
                  value={"0"}
                  subValue={"0 LTIFR Rate in 2023"}
                  subValueColor={lynxColors.success}
                  className="mt-2 mr-2"
                />
                <BadgeWidget
                  title={"Total Training Provided (2023)"}
                  value={"423"}
                  subValue={"Contractor + Employee Hours"}
                  unit={"hours"}
                  className="mt-2"
                />
              </Grid.Col>
              <Grid.Col md={6} width={12}>
                <LineChartWidget
                  title={"TRIFR by Quarter"}
                  data={[
                    { name: "Jan 2022", TRIFR: 0.3 },
                    { name: "Apr 2022", TRIFR: 1.2 },
                    { name: "Jul 2022", TRIFR: 0.2 },
                    { name: "Oct 2022", TRIFR: 0.4 },
                    { name: "Jan 2023", TRIFR: 0.2 },
                    { name: "Apr 2023", TRIFR: 0.1 },
                    { name: "Jul 2023", TRIFR: 0.1 },
                    { name: "Oct 2023", TRIFR: 0.2 },
                  ]}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </CardContent>
      </Card>
    );
    return card;
  };

  const getEmissionsCard = () => {
    let card = (
      <Card
        ref={emissionsRef}
        variant="outlined"
        sx={{ width: "100%" }}
        className="lynx-card mb-5"
      >
        <CardHeader className="lynx-card-header" title="Emissions Reduction" />

        <Divider />
        <CardContent>
          <Grid>
            <Grid.Row>
              <Grid.Col md={6} width={12}>
                <BadgeWidget
                  title={"Total Emissions (2023)"}
                  value={"421"}
                  unit={"tC02eq"}
                  subValue={"-3.2% vs previous year"}
                  subValueColor={lynxColors.success}
                  arrow={2}
                  className="mr-2"
                />
                <BadgeWidget
                  title={"Share of renewable sources (2023)"}
                  value={"62.0%"}
                  subValue={"+5.4% vs previous year"}
                  subValueColor={lynxColors.success}
                  arrow={1}
                />
                <LineChartWidget
                  className="mt-2"
                  title="Emissions By Year"
                  data={[
                    {
                      name: "2020",
                      "Scope 1": 55,
                      "Scope 2": 67,
                      "Scope 3": 422,
                    },
                    {
                      name: "2021",
                      "Scope 1": 102,
                      "Scope 2": 123,
                      "Scope 3": 665,
                    },
                    {
                      name: "2022",
                      "Scope 1": 50,
                      "Scope 2": 67,
                      "Scope 3": 422,
                    },
                    {
                      name: "2023",
                      "Scope 1": 50,
                      "Scope 2": 48,
                      "Scope 3": 277,
                    },
                  ]}
                />
                <div className="text-container">
                  <Typography>
                    Our commitment to sustainability is reflected in our
                    stringent emissions reduction strategies. Lynx Nickel Corp.
                    employs state-of-the-art technologies to monitor and
                    minimize greenhouse gas emissions from our operations. We
                    are dedicated to continuous improvement in our processes to
                    contribute to global efforts in combating climate change. We
                    track both Scope 1 and 2 emissions, and regularly report
                    this data.
                  </Typography>
                </div>
              </Grid.Col>

              <Grid.Col md={6} width={12}>
                <DonutChartWidget
                  title={"Emissions by Scope (2023)"}
                  data={emissionsData}
                />
              </Grid.Col>
            </Grid.Row>
          </Grid>
        </CardContent>
      </Card>
    );
    return card;
  };

  const getProfileCard = () => {
    return (
      <Card
        variant="outlined"
        sx={{ width: "100%" }}
        className="lynx-card mb-5"
        ref={profileRef}
      >
        <CardHeader className="lynx-card-header" title="Company Profile" />

        <Tabs
          value={profileTabValue}
          onChange={handleProfileTabChange}
          aria-label="basic tabs example"
          className="tab-darker-border"
        >
          <Tab value="1" label="Business Overview" />
          <Tab value="2" label="Our Approach" />
        </Tabs>
        <Divider />
        <CardContent>
          <Grid>
            {profileTabValue == "1" ? (
              <Grid.Row>
                <Grid.Col md={12} width={12}>
                  <div className="text-container">
                    <Typography>
                      Lynx Nickel Corp. is an emerging junior nickel mining
                      company focused on the exploration and development of a
                      promising nickel deposit located in Sudbury, Ontario,
                      Canada. With a commitment to sustainable and responsible
                      mining practices, Lynx Nickel Corp. aims to become a
                      leading supplier of high-quality nickel essential for the
                      burgeoning electric vehicle and renewable energy sectors.
                      Strong initial assay results have emerged from our Spring
                      Drill Campaign of over 500m, extending from the existing
                      Iron Mountain deposit to the north, where the anomalies
                      and boundary of the deposit are being explored further in
                      the Black Spring area.
                    </Typography>
                  </div>
                </Grid.Col>
                <Grid.Col md={3} width={12}>
                  <div className="mt-2">
                    <BadgeWidget
                      value={"21.12"}
                      unit={"USD"}
                      subValue={"+0.080 (0.25%)"}
                      subValueColor={lynxColors.success}
                      arrow={1}
                      title={"Stock Price"}
                    />
                  </div>
                </Grid.Col>
              </Grid.Row>
            ) : (
              <Grid.Row>
                <Grid.Col md={12} width={12}>
                  <div className="text-container">
                    <Typography>
                      At Lynx Nickel Corp., we leverage advanced exploration
                      techniques and innovative mining technologies to ensure
                      efficient and environmentally responsible extraction of
                      nickel. Our approach prioritizes the preservation of the
                      natural landscape, water conservation, and minimizing our
                      carbon footprint, while maximizing the value and potential
                      of our nickel deposit. Featuring a world-class team at a
                      world-class deposit, Lynx Nickel is poised to become a
                      banner asset for Sudbury, showcasing the potential of
                      Canadian battery mineral companies.
                    </Typography>
                  </div>
                </Grid.Col>
              </Grid.Row>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };

  const getCommunityCard = () => {
    return (
      <Card
        variant="outlined"
        sx={{ width: "100%", minHeight: "858px" }}
        className="lynx-card mb-5"
        ref={communityRef}
      >
        <CardHeader className="lynx-card-header" title="Community Commitment" />
        <Tabs
          value={communityTabValue}
          onChange={handleCommunityTabChange}
          aria-label="basic tabs example"
          className="tab-darker-border"
        >
          <Tab value="1" label="Engagment" />
          <Tab value="2" label="Investment" />
        </Tabs>
        <Divider />
        <CardContent>
          <Grid>
            {communityTabValue == "1" ? (
              <Grid.Row>
                <Grid.Col md={6} width={12}>
                  <div className="text-container">
                    <Typography>
                      We believe in building strong, transparent, and mutually
                      beneficial relationships with the local communities in
                      Sudbury. Lynx Nickel Corp. actively engages with community
                      members through regular meetings, updates, and open
                      forums, ensuring that our operations align with community
                      interests and contribute positively to the local
                      socio-economic development. When the community has a
                      complaint or suggestion, we listen. All community
                      grievances are tracked through the Lynx Community Portal
                      to ensure corrective and preventative actions are taken.
                      Doing so preserves our social license to operate.
                    </Typography>
                  </div>
                  <div className="d-flex justify-content-between">
                    <BadgeWidget
                      title={"Avg Community Portal Response Time"}
                      value={"1 day 4 hours"}
                      subValue={"-35.4% vs previous year"}
                      subValueColor={lynxColors.success}
                      arrow={2}
                      className="mt-2"
                    />
                    <div className="photo-container mt-2">
                      <img
                        alt="image1"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQuimml9uQhX2NWd90qV_EdeW5UujUmohJA8A&usqp=CAU"
                        width={300}
                      ></img>
                    </div>
                  </div>
                </Grid.Col>

                <Grid.Col md={6} width={12}>
                  <DonutChartWidget
                    title={"Community Engagment Events by Group (2023)"}
                    data={engagmentChartData}
                  />
                </Grid.Col>
              </Grid.Row>
            ) : (
              <Grid.Row>
                <Grid.Col md={6} width={12}>
                  <BadgeWidget
                    value={"$21K"}
                    unit={"USD"}
                    title={"Total Community Investment (2023)"}
                    className="mr-2"
                  />
                  <BadgeWidget
                    value={"$5K"}
                    unit={"USD"}
                    title={"Total Community Donations (2023)"}
                  />
                  <div className="text-container">
                    <Typography>
                      Lynx Nickel Corp. is committed to the well-being and
                      prosperity of the Sudbury community. We invest in local
                      infrastructure, education, and healthcare projects, and
                      support small businesses and community initiatives. Our
                      goal is to create lasting positive impacts and provide
                      opportunities for community growth alongside our mining
                      operations. Lynx Nickel is one of the largest employers in
                      the region, and we realize our commitment to community is
                      best leveraged through safe working conditions and fair
                      compensation, above the market rate.
                    </Typography>
                  </div>
                  <div className="photo-container mt-2">
                    <img
                      alt="image1"
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpLyspe3_mSJHQMQTBUyOdmQBN8Kkb9abCYA&usqp=CAU"
                      width={300}
                      className="mt-2"
                    ></img>
                  </div>
                </Grid.Col>

                <Grid.Col md={6} width={12}>
                  <BarChartWidget
                    title={"Community Investment in USD by Project (2023)"}
                    data={projectChartData}
                  />
                </Grid.Col>
              </Grid.Row>
            )}
          </Grid>
        </CardContent>
      </Card>
    );
  };
  return (
    <>
      {getMicrositesSidebar()}
      <div className="content-wrapper-microsite padding-left-sidebar">
        <Container maxWidth="xl">
          <div className="header-container">
            <Typography variant="h2">{`${companyName} Due Diligence Portal`}</Typography>
          </div>
          {getProfileCard()}
          {getCommunityCard()}
          {getEmissionsCard()}
          {getHealthAndSafetyCard()}
          {getWaterCard()}
        </Container>
      </div>
    </>
  );
}
