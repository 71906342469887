import { createTheme } from "@mui/material/styles";
import lynxColors from "../modules/lynxColors";

export const theme = createTheme({
  typography: {
    fontFamily: [
      "DIN Pro",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },

  palette: {
    primary: {
      main: lynxColors.primary,
    },
    error: {
      main: lynxColors.error,
      contrastText: "#fff",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    secondary: {
      light: "#0066ff",
      main: "#f3a738",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    success: {
      main: lynxColors.success,
      contrastText: "#fff",
    },
  },
});
